import React from 'react';
import PropTypes from 'prop-types';
import Plot from 'react-plotly.js';

class SellersCharts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <Plot
          data={[
            {
              type: 'pie',
              labels: this.props.sellersGenderData.gender,
              values: this.props.sellersGenderData.count,
            },
          ]}
          layout={{
            width: '100%',
            height: '100%',
            title: 'Género',
          }}
          config={{
            displaylogo: false,
            displayModeBar: false,
          }}
        />
        <Plot
          data={[
            {
              x: this.props.sellersAgeData.age,
              type: 'histogram',
              xbins: { start: 15, end: 100, size: 5 },
            },
          ]}
          layout={{
            width: '100%',
            height: '100%',
            title: 'Edad',
          }}
          config={{
            displaylogo: false,
            displayModeBar: false,
          }}
        />
      </div>
    );
  }
}

SellersCharts.propTypes = {
  sellersAgeData: PropTypes.object.isRequired,
  sellersGenderData: PropTypes.object.isRequired,
};

export default SellersCharts;
