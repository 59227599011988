/* eslint-disable no-await-in-loop */
import React from 'react';
import PropTypes from 'prop-types';
import PrintComponents from 'react-print-components';
import moment from 'moment';
import { Row, Col, Select, notification, DatePicker } from 'antd';
import {
  DropboxOutlined,
  LoadingOutlined,
  PrinterOutlined,
  FileDoneOutlined,
  RocketOutlined,
} from '@ant-design/icons';
import PackingsTable from './PackingsTable';
import {
  PACKING_READY_FOR_PACKING,
  PACKING_COMPLETED,
  PACKING_IN_PROCESS,
  PACKING_RETURNED,
  PACKING_DELIVERED,
  PACKING_DISPATCHED,
} from '../Utils/constants';
import { fetchWarehouses } from '../../gateway/WarehouseServices';
import {
  fetchPackings,
  changePackingStatus,
} from '../../gateway/PackingServices';
import { fetchProducts } from '../../gateway/ProductServices';
import { fetchWarehouseBoxes } from '../../gateway/BoxServices';
import { getShipment } from '../../gateway/ShippingServices';
import { fetchOrders } from '../../gateway/OrderServices';
import { fetchCompany } from '../../gateway/CompanyServices';
import { fetchRole } from '../../gateway/IdentityService';
import { fetchAllCities } from '../../gateway/CountryServices';
import BoxesModal from './BoxesModal';
import './packings.scss';

const { Option } = Select;

class Packings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      boxesModalVisible: false,
      loading: false,
      totalPackings: 0,
      pagination: { current: 1, pageSize: 10 },
      since: moment(new Date().setDate(new Date().getDate() - 100)),
      to: moment().endOf('day'),

      // arrays
      packings: [],
      warehouses: [],
      printingInformation: [],
      warehouseBoxes: [],
      packingsToBePacked: [],

      // hashes
      allPackingInfoHash: {},
      shipmentsHash: {},
      ordersInfoHash: {},
      citiesHash: {},
    };
    this.reload = this.reload.bind(this);

    // methods to retrieve information
    this.fetchPackings = this.fetchPackings.bind(this);
    this.fetchWarehouses = this.fetchWarehouses.bind(this);
    this.fetchOrdersInfo = this.fetchOrdersInfo.bind(this);
    this.fetchWarehouseBoxes = this.fetchWarehouseBoxes.bind(this);
    this.fetchInformation = this.fetchInformation.bind(this);
    this.fetchCompany = this.fetchCompany.bind(this);
    this.fetchShippingCityInfo = this.fetchShippingCityInfo.bind(this);
    this.fetchShipment = this.fetchShipment.bind(this);

    // methods to make changes on data
    this.processAllPackings = this.processAllPackings.bind(this);
    this.changePackingStatus = this.changePackingStatus.bind(this);
    this.sendNewStatus = this.sendNewStatus.bind(this);
    this.printAllShipmentGuides = this.printAllShipmentGuides.bind(this);

    // method to open and close boxes modal
    this.changeBoxesModalVisibility =
      this.changeBoxesModalVisibility.bind(this);
  }

  reload() {
    this.setState(
      {
        pagination: { current: 1, pageSize: 10 },
        boxesModalVisible: false,
        packings: [],
        packingsToBePacked: [],
      },
      () => this.fetchPackings(this.state.pagination),
    );
  }

  async fetchCompany() {
    const role = await fetchRole();
    const isDarkstoreUser = role?.find(
      (currentRole) => currentRole === 'DARKSTORE_USER',
    );
    if (!isDarkstoreUser) {
      const company = await fetchCompany();
      this.setState({ companyId: company?.id });
    }
  }

  async fetchWarehouses() {
    const warehouses = await fetchWarehouses();
    this.setState({ warehouses });
    if (warehouses.length && warehouses[0].id) {
      this.setState({ warehouseId: warehouses[0].id });
    }
  }

  async changePackingStatus(newStatus, packing) {
    if (newStatus === PACKING_COMPLETED) {
      this.setState((prevState) => {
        let packings = prevState.packingsToBePacked;
        if (packings.includes(packing)) {
          packings = packings.filter((c) => c !== packing);
        } else {
          packings.push(packing);
        }
        return { packingsToBePacked: packings };
      });
    } else {
      await this.sendNewStatus(newStatus, packing);
    }
  }

  async sendNewStatus(newStatus, packing, boxes) {
    this.setState({ loading: true });
    const result = await changePackingStatus(
      packing.warehouse.id,
      packing.id,
      newStatus,
      boxes,
    );
    if (result) {
      if (newStatus === PACKING_COMPLETED) {
        this.fetchShipment(result);
        notification.info({
          message: `La guía se está generando.`,
          duration: 10,
          description: `Porfavor espere para poder abrir la guía una vez esta se haya generado.`,
        });
      }
      this.reload();
      notification.success({
        message: `Se ha actualizado correctamente el estado del paquete: ${packing.id}`,
        duration: 7,
        description: `El estado actual ahora es: ${newStatus}`,
      });
    } else {
      notification.error({
        message: `Error actualizando el estado del paquete: ${packing.id}`,
        duration: 7,
        description: `El estado actual sigue siendo: ${packing.currentStatus.value}`,
      });
    }
    this.setState({ loading: false });
    return result;
  }

  async fetchShipment(shipmentId) {
    let fetching = true;
    let secondsWaiting = 0;
    while (fetching) {
      await new Promise((r) => setTimeout(r, 2000));
      secondsWaiting += 2;
      const shipment = await getShipment(shipmentId);
      if (shipment.currentStatus.value === 'GUIDE CREATED') {
        fetching = false;
        notification.success({
          message: `Guía ha sido generada.`,
          duration: 10,
          description: `Presione el botón en la parte superior de la página para abrir la guía.`,
        });
      } else if (secondsWaiting >= 30) {
        fetching = false;
        notification.error({
          message: 'Error creando guía',
          description:
            'El paquete ha sido empacado pero ocurrió un error creando la guía. Porfavor comuníquese con nosotros.',
          duration: 0,
        });
      }
    }
  }

  async fetchPackings(params) {
    this.setState({ loading: true });
    const { pageSize, current } = params;
    const { warehouseId, since, to } = this.state;
    const { packings, total } = await fetchPackings(
      current - 1,
      pageSize,
      [this.props.currentStatus],
      undefined,
      warehouseId,
      since,
      to,
    );
    this.setState({
      pagination: { ...params, total },
      packings,
      totalPackings: total,
    });
    this.fetchInformation(packings, total);
    this.setState({ loading: false });
  }

  async fetchOrdersInfo(subordersIds, packings) {
    const ordersInfoHashByPackingId = {};
    const orders = await fetchOrders(subordersIds, this.state.companyId);
    packings.forEach((packing) => {
      const subId = packing.productsPerPacking[0].suborderId;
      const order = orders.find((o) => o.suborders.some((s) => s.id === subId));
      if (order) ordersInfoHashByPackingId[packing.id] = order;
    });
    return ordersInfoHashByPackingId;
  }

  async fetchShippingCityInfo(governmentCodes, orders) {
    const { cities } = await fetchAllCities(
      0,
      governmentCodes.length,
      undefined,
      undefined,
      governmentCodes,
    );
    const citiesHash = {};
    orders.forEach((order) => {
      const { shippingCityCode } = order;
      const city = cities.find((c) => c.governmentCode === shippingCityCode);
      citiesHash[order.id] = city;
    });
    return citiesHash;
  }

  async fetchShipments(packings) {
    const shipmentsHash = {};
    for (let i = 0; i < packings.length; i += 1) {
      const packing = packings[i];
      const shipment = packing.shipmentId
        ? await getShipment(packing.shipmentId)
        : undefined;
      if (shipment) shipmentsHash[packing.id] = shipment;
    }
    return shipmentsHash;
  }

  async printAllShipmentGuides() {
    this.state.packings.forEach((packing) => {
      const shipment = this.state.shipmentsHash[packing.id];
      if (shipment?.trackingPdf) {
        window.open(shipment.trackingPdf);
      }
    });
  }

  async getProductPerPackingInfo(
    packings,
    ordersInfoHashByPackingId,
    productConfigIds,
  ) {
    const { products } = await fetchProducts(
      0,
      productConfigIds.length,
      productConfigIds,
    );

    const allPackingInfoHash = {};
    const printingInformation = [];

    for (let i = 0; i < packings.length; i += 1) {
      const currentPacking = packings[i];
      const orderForCurrentPacking =
        ordersInfoHashByPackingId[currentPacking.id];
      const { productsPerPacking } = currentPacking;
      const informationHash = {};

      for (let j = 0; j < productsPerPacking.length; j += 1) {
        const productPerPacking = productsPerPacking[j];

        const { productConfigurationId, quantity, suborderId } =
          productPerPacking;

        const suborder = orderForCurrentPacking
          ? orderForCurrentPacking.suborders.find(
              (subOrder) => subOrder.id === suborderId,
            )
          : {};

        const suborderItem = suborder.suborderItems
          ? suborder.suborderItems.find(
              (currentSuborderItem) =>
                currentSuborderItem.externalId === productConfigurationId,
            )
          : {};

        let foundProduct;

        products.forEach((product) => {
          const productConfigFound = product.productConfigurations.find(
            (pc) => pc.id === suborderItem.externalId,
          );
          if (productConfigFound) {
            foundProduct = product;
          }
        });

        const information = {};
        information.generalReference = foundProduct?.reference;
        information.catalogName = foundProduct?.catalog?.name;
        information.productName = suborderItem?.name;
        information.uniqueReference = suborderItem?.uniqueReference;
        information.config = suborderItem.configuration;
        information.packingId = currentPacking.id;
        information.quantity = quantity;
        information.basePrice = suborderItem.basePrice;
        information.sellerPrice = suborderItem.sellerPrice;

        informationHash[productPerPacking.productConfigurationId] = information;
        printingInformation.push(information);
      }

      allPackingInfoHash[currentPacking.id] = informationHash;
    }

    return { allPackingInfoHash, printingInformation };
  }

  async fetchWarehouseBoxes() {
    const boxes = await fetchWarehouseBoxes(this.state.warehouseId);
    boxes.sort((b1, b2) => {
      const nameA = b1.name.toUpperCase();
      const nameB = b2.name.toUpperCase();
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    });
    return boxes;
  }

  async fetchInformation(packings) {
    const subordersIds = [];
    const productConfigIds = [];

    for (let i = 0; i < packings.length; i += 1) {
      const packing = packings[i];
      for (let j = 0; j < packing.productsPerPacking.length; j += 1) {
        const productPerPacking = packing.productsPerPacking[j];
        if (j === 0) {
          subordersIds.push(productPerPacking.suborderId);
        }
        productConfigIds.push(productPerPacking.productConfigurationId);
      }
    }

    const ordersInfoHashByPackingId = await this.fetchOrdersInfo(
      subordersIds,
      packings,
    );
    const orders = Object.values(ordersInfoHashByPackingId);

    const { allPackingInfoHash, printingInformation } =
      await this.getProductPerPackingInfo(
        packings,
        ordersInfoHashByPackingId,
        productConfigIds,
      );

    const codes = orders.map((o) => o.shippingCityCode);
    const citiesHash = await this.fetchShippingCityInfo(codes, orders);
    let warehouseBoxes = [];
    let shipmentsHash = {};
    if (this.props.currentStatus === PACKING_IN_PROCESS) {
      warehouseBoxes = await this.fetchWarehouseBoxes();
    } else if (
      this.props.currentStatus === PACKING_COMPLETED ||
      this.props.currentStatus === PACKING_DELIVERED ||
      this.props.currentStatus === PACKING_DISPATCHED
    ) {
      shipmentsHash = await this.fetchShipments(packings);
    }
    this.setState({
      allPackingInfoHash,
      printingInformation,
      ordersInfoHash: ordersInfoHashByPackingId,
      warehouseBoxes,
      shipmentsHash,
      citiesHash,
    });
  }

  async componentDidMount() {
    await this.fetchWarehouses();
    await this.fetchCompany();
    if (this.state.warehouseId) await this.fetchPackings(this.state.pagination);
  }

  getOrderStatusTitle() {
    const { currentStatus } = this.props;
    // default case: packing canceled
    let title = 'Paquetes cancelados';
    if (currentStatus === PACKING_READY_FOR_PACKING) {
      title = 'Nuevos paquetes';
    } else if (currentStatus === PACKING_IN_PROCESS) {
      title = 'Paquetes en proceso';
    } else if (currentStatus === PACKING_COMPLETED) {
      title = 'Paquetes empacados';
    } else if (currentStatus === PACKING_RETURNED) {
      title = 'Paquetes devueltos';
    } else if (currentStatus === PACKING_DELIVERED) {
      title = 'Paquetes entregados';
    } else if (currentStatus === PACKING_DISPATCHED) {
      title = 'Paquetes enviados';
    }
    return title;
  }

  getOrderStatusIcon() {
    const { currentStatus } = this.props;
    // default case: packing canceled
    let icon = (
      <ion-icon
        name="trash-outline"
        style={{ height: '25px', width: '25px', marginRight: '10px' }}
      ></ion-icon>
    );
    if (currentStatus === PACKING_READY_FOR_PACKING) {
      icon = (
        <ion-icon
          name="add-outline"
          style={{ height: '25px', width: '25px', marginRight: '10px' }}
        ></ion-icon>
      );
    } else if (currentStatus === PACKING_IN_PROCESS) {
      icon = (
        <DropboxOutlined style={{ fontSize: '25px', marginRight: '10px' }} />
      );
    } else if (currentStatus === PACKING_COMPLETED) {
      icon = (
        <ion-icon
          name="cube-outline"
          style={{ height: '25px', width: '25px', marginRight: '10px' }}
        ></ion-icon>
      );
    } else if (currentStatus === PACKING_RETURNED) {
      icon = (
        <ion-icon
          name="arrow-undo-outline"
          style={{ height: '25px', width: '25px', marginRight: '10px' }}
        ></ion-icon>
      );
    } else if (currentStatus === PACKING_DELIVERED) {
      icon = <FileDoneOutlined />;
    } else if (currentStatus === PACKING_DISPATCHED) {
      icon = <RocketOutlined />;
    }
    return icon;
  }

  async processAllPackings() {
    this.setState({ loading: true });
    const { packings, warehouseId } = this.state;
    const errors = [];
    for (let i = 0; i < packings.length; i += 1) {
      const packing = packings[i];
      const result = await changePackingStatus(
        warehouseId,
        packing.id,
        PACKING_IN_PROCESS,
      );
      if (!result) {
        errors.push(packing.id);
      }
    }
    if (errors.length > 0) {
      const msg = errors.map((e) => ` ${e}\n`);
      notification.error({
        message: 'Error pasando paquetes a estado en proceso',
        duration: 0,
        description: `Paquetes con errores: \n ${msg}`,
      });
    } else {
      this.reload();
      notification.success({
        message: `Se ha actualizado correctamente el estado de los paquetes`,
        duration: 7,
        description: `El estado actual ahora es: PACKING_IN_PROCESS`,
      });
    }
    this.setState({ loading: false });
  }

  changeBoxesModalVisibility(value) {
    this.setState({ boxesModalVisible: value });
  }

  render() {
    const { currentStatus } = this.props;
    return (
      <div>
        <BoxesModal
          boxes={this.state.warehouseBoxes}
          visible={this.state.boxesModalVisible}
          packings={this.state.packingsToBePacked}
          changeBoxesModalVisibility={this.changeBoxesModalVisibility}
          sendBoxes={this.sendNewStatus}
          loading={this.state.loading}
        />
        <div className="row center">
          <span className="viewTitle">
            {this.getOrderStatusIcon()}
            <h2>{this.getOrderStatusTitle()}</h2>
          </span>
        </div>
        <div className="row center" style={{ width: '100%' }}>
          <div
            className="col col-50 center"
            style={{ maxWidth: '800px', minWidth: '400px', width: '60%' }}
          >
            <div className="card">
              <div className="row no-padding center">
                <div className="col">
                  <div style={{ fontSize: '1em' }}>Reporte desde:</div>
                  <DatePicker
                    onChange={(date) =>
                      this.setState({ since: moment(date).startOf('day') })
                    }
                    allowClear={false}
                    format="YYYY-MM-DD"
                    value={this.state.since}
                  />
                </div>
                <div className="col">
                  <div style={{ fontSize: '1em' }}>Reporte hasta:</div>
                  <DatePicker
                    onChange={(date) =>
                      this.setState({ to: moment(date).endOf('day') })
                    }
                    allowClear={false}
                    format="YYYY-MM-DD"
                    value={this.state.to}
                  />
                </div>
                <div className="col center">
                  Bodega
                  <Select
                    placeholder="Seleccione una bodega"
                    style={{ textAlign: 'center', width: '180px' }}
                    loading={this.state.loading}
                    filterOption={false}
                    onSelect={(warehouseId) => this.setState({ warehouseId })}
                    value={this.state.warehouseId}
                  >
                    {this.state.warehouses.map((warehouse) => (
                      <Option key={warehouse.id}>{warehouse.name}</Option>
                    ))}
                  </Select>
                </div>
                <div className="col center">
                  <button
                    type="button"
                    className={`button small primary ${
                      !this.state.warehouseId ? 'disabled' : ''
                    }`}
                    disabled={!this.state.warehouseId}
                    onClick={() => this.fetchPackings(this.state.pagination)}
                  >
                    {this.state.loading && (
                      <LoadingOutlined
                        className="button-icon"
                        style={{ marginRight: '5px' }}
                      />
                    )}
                    Buscar
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col center"
            style={{ maxWidth: '480px', minWidth: '300px', width: '40%' }}
          >
            <div
              className="card"
              style={{ marginLeft: 'auto', marginRight: '0' }}
            >
              <div className="row center no-padding">
                {currentStatus === PACKING_READY_FOR_PACKING && (
                  <div className="col center">
                    <button
                      disabled={this.state.packings.length === 0}
                      type="button"
                      className={`button primary large ${
                        this.state.packings.length === 0 ? 'disabled' : ''
                      }`}
                      onClick={() => this.processAllPackings()}
                    >
                      {this.state.loading && (
                        <LoadingOutlined
                          className="button-icon"
                          style={{ marginRight: '5px' }}
                        />
                      )}
                      Procesar paquetes ({this.state.packings.length})
                    </button>
                  </div>
                )}
                {currentStatus === PACKING_IN_PROCESS && (
                  <div className="col center">
                    <button
                      disabled={this.state.packingsToBePacked.length === 0}
                      type="button"
                      className={`button primary large ${
                        this.state.packingsToBePacked.length === 0
                          ? 'disabled'
                          : ''
                      }`}
                      onClick={() => this.changeBoxesModalVisibility(true)}
                    >
                      {this.state.loading && (
                        <LoadingOutlined
                          className="button-icon"
                          style={{ marginRight: '5px' }}
                        />
                      )}
                      Empacar ({this.state.packingsToBePacked.length})
                    </button>
                  </div>
                )}
                <div className="col center">
                  <PrintComponents
                    trigger={
                      <button
                        type="button"
                        className={`button secondary center small ${
                          this.state.packings.length === 0 ? 'disabled' : ''
                        }`}
                        disabled={this.state.packings.length === 0}
                      >
                        {this.state.loading ? (
                          <LoadingOutlined
                            className="button-icon"
                            style={{
                              marginRight: '5px',
                            }}
                          />
                        ) : (
                          <PrinterOutlined
                            className="button-icon"
                            style={{
                              marginRight: '5px',
                            }}
                          />
                        )}
                        Imprimir
                      </button>
                    }
                  >
                    <Row style={{ borderBottom: 'solid 1px' }}>
                      <Col span={6} style={{ fontWeight: 'bold' }}>
                        Nombre
                      </Col>
                      <Col span={5} style={{ fontWeight: 'bold' }}>
                        Ref. General
                      </Col>
                      <Col span={5} style={{ fontWeight: 'bold' }}>
                        Ref. Única
                      </Col>
                      <Col span={2} style={{ fontWeight: 'bold' }}>
                        #
                      </Col>
                      <Col span={6} style={{ fontWeight: 'bold' }}>
                        Elección
                      </Col>
                    </Row>
                    {this.state.printingInformation.map((information) => (
                      <Row
                        style={{ borderBottom: 'solid 1px' }}
                        key={`${information.uniqueReference}-${information.generalReference}-${information.packingId}`}
                      >
                        <Col span={6}>{information.productName}</Col>
                        <Col span={5}>{information.generalReference}</Col>
                        <Col span={5}>{information.uniqueReference}</Col>
                        <Col span={2}>{information.quantity}</Col>
                        <Col span={6}>{information.config}</Col>
                      </Row>
                    ))}
                  </PrintComponents>
                </div>
                {(currentStatus === PACKING_COMPLETED ||
                  currentStatus === PACKING_DELIVERED ||
                  currentStatus === PACKING_DISPATCHED) && (
                  <button
                    type="button"
                    className={`button secondary center small ${
                      this.state.packings.length === 0 ? 'disabled' : ''
                    }`}
                    disabled={this.state.packings.length === 0}
                    onClick={() => this.printAllShipmentGuides()}
                  >
                    {this.state.loading ? (
                      <LoadingOutlined
                        className="button-icon"
                        style={{
                          marginRight: '5px',
                        }}
                      />
                    ) : (
                      <PrinterOutlined
                        className="button-icon"
                        style={{
                          marginRight: '5px',
                        }}
                      />
                    )}
                    Imprimir todo
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <h4 style={{ textAlign: 'right', padding: '0 15px' }}>
          Total paquetes: {this.state.totalPackings}
        </h4>
        <PackingsTable
          currentStatus={currentStatus}
          packings={this.state.packings}
          allPackingInfoHash={this.state.allPackingInfoHash}
          pagination={this.state.pagination}
          fetchPackings={this.fetchPackings}
          loading={this.state.loading}
          changePackingStatus={this.changePackingStatus}
          shipmentsHash={this.state.shipmentsHash}
          ordersInfoHash={this.state.ordersInfoHash}
          citiesHash={this.state.citiesHash}
          packingsToBePacked={this.state.packingsToBePacked}
        />
      </div>
    );
  }
}

Packings.propTypes = {
  currentStatus: PropTypes.string,
};

export default Packings;
