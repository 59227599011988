import React from 'react';

import PropTypes from 'prop-types';
import SideNav from './SideNav';
import './layout.scss';

class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="layout">
        <SideNav></SideNav>
        <div className="viewsContainer">{this.props.component}</div>
      </div>
    );
  }
}

Layout.propTypes = {
  component: PropTypes.any,
};

export default Layout;
