import XLSX from 'xlsx';

function formatProducts(products, headers, variantHeaders) {
  const refGVSNamesHash = {};
  const hashCountRefG = {};
  const errors = [];
  for (let i = 0; i < products.length; i += 1) {
    let refG = products[i][headers[0]];
    refG = refG ? refG.toString().trim() : null;
    if (!refG || refG === '') errors.push(`Error in line: ${i + 2}. No refG.`);

    for (let j = 0; j < variantHeaders.length; j += 1) {
      const imageName = products[i][variantHeaders[j]];

      if (imageName && !refGVSNamesHash[imageName]) {
        if (!refGVSNamesHash[imageName]) refGVSNamesHash[imageName] = [];
        if (!hashCountRefG[refG]) hashCountRefG[refG] = 0;

        hashCountRefG[refG] += 1;
        refGVSNamesHash[imageName] = { refG, id: `${hashCountRefG[refG]}.` };
      }
    }
  }
  return { refGVSNamesHash, errors };
}

function formatProductsUniqueRefToGeneralRef(products, headers) {
  const refGVSRefU = {};
  const errors = [];
  for (let i = 0; i < products.length; i += 1) {
    let refG = products[i][headers[0]];
    refG = refG ? refG.toString().trim() : null;
    if (!refG || refG === '') errors.push(`Error in line: ${i + 2}. No refG.`);
    let refU = products[i][headers[1]];
    refU = refU ? refU.toString().trim() : null;
    if (!refU || refU === '') errors.push(`Error in line: ${i + 2}. No refG.`);

    refGVSRefU[refU] = refG;
  }
  return { refGVSRefU, errors };
}

export const XLSToHashImageNamesVsreferences = (
  file,
  headers,
  variantHeaders,
) =>
  new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);
    fileReader.onload = (e) => {
      const workbook = XLSX.read(btoa(fixdata(e.target.result)), {
        type: 'base64',
      });
      const workbookJson = toJson(workbook);
      const products = workbookJson[Object.keys(workbookJson)[0]];

      if (!products) {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject({
          type: 'danger',
          msg: 'Este documento no funciona.',
        });
        return;
      }
      products.slice(1);
      const { refGVSNamesHash, errors } = formatProducts(
        products,
        headers,
        variantHeaders,
      );
      resolve({ refGVSNamesHash, errors });
    };
  });

export const XLSToHashGeneralReferenceVsUniqueReferences = (file, headers) =>
  new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);
    fileReader.onload = (e) => {
      const workbook = XLSX.read(btoa(fixdata(e.target.result)), {
        type: 'base64',
      });
      const workbookJson = toJson(workbook);
      const products = workbookJson[Object.keys(workbookJson)[0]];

      if (!products) {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject({
          type: 'danger',
          msg: 'Este documento no funciona.',
        });
        return;
      }
      products.slice(1);
      const { refGVSRefU, errors } = formatProductsUniqueRefToGeneralRef(
        products,
        headers,
      );
      resolve({ refGVSRefU, errors });
    };
  });

export const getHeaders = (file) =>
  new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);
    fileReader.onload = (e) => {
      const workbook = XLSX.read(btoa(fixdata(e.target.result)), {
        type: 'base64',
      });
      const workbookJson = toJson(workbook);
      const products = workbookJson[Object.keys(workbookJson)[0]];
      if (!products) {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject({
          type: 'danger',
          msg: 'Este documento no funciona.',
        });
        return;
      }
      const keys = Object.keys(products[0]);
      resolve(keys);
    };
  });

function fixdata(data) {
  let o = '';
  let l = 0;
  const w = 10240;
  // eslint-disable-next-line no-plusplus
  for (; l < data.byteLength / w; ++l)
    o += String.fromCharCode.apply(
      null,
      new Uint8Array(data.slice(l * w, l * w + w)),
    );
  o += String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w)));
  return o;
}

function toJson(workbook) {
  const result = {};
  workbook.SheetNames.forEach((sheetName) => {
    const roa = XLSX.utils.sheet_to_row_object_array(
      workbook.Sheets[sheetName],
    );
    if (roa.length > 0) {
      result[sheetName] = roa;
    }
  });
  return result;
}
