import React, { useCallback, useEffect, useState } from 'react';
import { ShopOutlined } from '@ant-design/icons';
import { useHistory, useLocation } from 'react-router-dom';
import { Tabs } from 'antd';
import ProductSection from './ProductSection';
import './styles.scss';
import { fetchProduct, handleProduct } from './actions';
import VariantsSection from './VariantsSection';
import { getProductConfigMediaByProductConfigId } from '../../gateway/ProductServices';

function Product() {
  const [product, setProduct] = useState({});
  const [variants, setVariants] = useState({});
  const location = useLocation();

  const id = location.pathname.split('/productos/')[1];

  const fetchData = useCallback(async () => {
    const productData = await fetchProduct(id);
    const { productInfo, variantsInfo } = handleProduct(productData);
    setProduct(productInfo);
    setVariants(variantsInfo);
  }, [id]);

  const handleProductConfigurationChange = (productConfigurationId, e) => {
    const newProductConfigurations = product.productConfigurations.map(
      (productConfigurationSearched) => {
        if (productConfigurationSearched.id === productConfigurationId)
          return {
            ...productConfigurationSearched,
            [e.target.name]: e.target.value,
          };
        return productConfigurationSearched;
      },
    );
    setProduct((prevState) => ({
      ...prevState,
      productConfigurations: newProductConfigurations,
    }));
  };

  async function reloadProductConfigurationMedia(productConfigurationId) {
    const newProdConfigMedia = await getProductConfigMediaByProductConfigId(
      productConfigurationId,
    );
    if (newProdConfigMedia) {
      const newState = { ...product };
      const prodConfigToUpdate = newState.productConfigurations.find(
        (searchProductConfiguration) =>
          searchProductConfiguration.id === productConfigurationId,
      );
      prodConfigToUpdate.productConfigurationMedia = newProdConfigMedia;
      setProduct(newState);
    }
    await fetchData();
  }

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const { TabPane } = Tabs;

  return (
    <div className="col no-padding">
      <h2 className="row center gap">
        Producto
        <ShopOutlined style={{ fontSize: '30px', color: '#001628' }} />
      </h2>
      <div className="col center gap no-padding">
        <div className="col gap no-padding" style={{ width: '80%' }}>
          <TopBanner />
          <Tabs defaultActiveKey="1">
            <TabPane tab={<span>Informacion del Producto</span>} key="1">
              <div className="row center">
                <ProductSection
                  product={product}
                  setProduct={setProduct}
                  fetchData={fetchData}
                />
              </div>
            </TabPane>
            <TabPane tab={<span>Variantes del Producto</span>} key="2">
              <div className="row center no-padding">
                <VariantsSection
                  variants={variants}
                  product={product}
                  fetchData={fetchData}
                  handleProductConfigurationChange={
                    handleProductConfigurationChange
                  }
                  reloadProductConfigurationMedia={
                    reloadProductConfigurationMedia
                  }
                />
              </div>
            </TabPane>
          </Tabs>
        </div>
      </div>
    </div>
  );
}

Product.propTypes = {};

function TopBanner() {
  const history = useHistory();
  return (
    <div className="row left no-padding" style={{ width: '80%' }}>
      <button
        type="button"
        className="link-button row"
        onClick={() => {
          history.push('/productos');
        }}
        style={{ columnGap: '0.5em', padding: 0 }}
      >
        <ion-icon name="chevron-back-outline"></ion-icon>
        Volver a Productos
      </button>
    </div>
  );
}

export default Product;
