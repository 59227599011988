import React from 'react';
import './styles/App.scss';
import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { updateAuth } from './store/reducers/auth';

import Login from './components/LoginLogout/Login';
import Logout from './components/LoginLogout/Logout';
import Layout from './components/Layout/Layout';

import Boxes from './components/Boxes/Boxes';
import Catalogs from './components/Catalogs/Catalogs';
import Warehouses from './components/Warehouses/Warehouses';
import Products from './components/Products/Products';
import Product from './views/Product/Product';
import Catalog from './components/Catalog/Catalog';
import AnalyticsCharts from './components/Analytics/AnalyticsCharts';
import Settings from './components/Settings/Settings';
import Inventories from './components/Inventories/Inventories';
import Packings from './components/Packings/Packings';
import TakeOfPlan from './views/TakeOffPlan/TakeOffPlan';

import {
  PACKING_READY_FOR_PACKING,
  PACKING_COMPLETED,
  PACKING_IN_PROCESS,
  PACKING_RETURNED,
  PACKING_CANCELED,
  PACKING_DELIVERED,
  PACKING_DISPATCHED,
} from './components/Utils/constants';
import Suborders from './components/Reports/SubordersReports/Suborders';

const axios = require('axios');
const ls = require('local-storage');

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedIn: false,
    };
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.interceptorAxio = this.interceptorAxio.bind(this);
  }

  login(authToken, refreshToken) {
    ls.set('authTokenCompany', authToken);
    ls.set('refreshTokenCompany', refreshToken);
    this.setState({ loggedIn: true });
  }

  logout() {
    ls.clear('authTokenCompany');
    ls.clear('refreshTokenCompany');
    this.setState({ loggedIn: false });
  }

  interceptorAxio() {
    axios.interceptors.request.use(
      async (config) => {
        if (ls.get('authTokenCompany')) {
          const { exp } = JSON.parse(
            atob(ls.get('authTokenCompany').split('.')[1]),
          );
          const currentDate = new Date().getTime();
          const refreshDate = exp * 1000;
          const tiempoFaltante = parseInt((refreshDate - currentDate) / 60000); // Tiempo faltante en minutos
          if (tiempoFaltante >= 0 && tiempoFaltante <= 5) {
            this.props.updateAuth(true);
          }
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );
    axios.interceptors.response.use(
      async (config) => {
        return config;
      },
      (error) => {
        if (error.response.status === 403) {
          window.location.href = '/cerrar';
        }

        return Promise.reject(error);
      },
    );
  }

  setApiGatewayURL() {
    const gatewayAPI = process.env.REACT_APP_GATEWAY_SERVICE_API;
    ls.set('gatewayAPI', gatewayAPI);
  }

  componentDidMount() {
    this.interceptorAxio();
  }

  render() {
    this.setApiGatewayURL();

    if (!this.state.loggedIn) {
      return <Login login={this.login} />;
    }

    const componentToRender = (
      <Switch>
        <Route path="/productos/:id" component={Product} />
        <Route path="/productos" component={Products} />
        <Route path="/catalogos/:id" component={Catalog} />
        <Route path="/catalogos" component={Catalogs} />
        <Route path="/bodegas" component={Warehouses} />
        <Route path="/cajas" component={Boxes} />
        <Route path="/estadisticas" component={AnalyticsCharts} />
        <Route path="/configuracion" component={Settings} />
        <Route path="/inventarios" component={Inventories} />
        <Route path="/reporte-subordenes" component={Suborders} />
        <Route
          path="/paquetes-para-enviar"
          component={() => (
            <Packings currentStatus={PACKING_READY_FOR_PACKING} />
          )}
        />
        <Route
          path="/paquetes-en-proceso"
          component={() => <Packings currentStatus={PACKING_IN_PROCESS} />}
        />
        <Route
          path="/paquetes-completados"
          component={() => <Packings currentStatus={PACKING_COMPLETED} />}
        />
        <Route
          path="/paquetes-devueltos"
          component={() => <Packings currentStatus={PACKING_RETURNED} />}
        />
        <Route
          path="/paquetes-cancelados"
          component={() => <Packings currentStatus={PACKING_CANCELED} />}
        />
        <Route
          path="/paquetes-entregados"
          component={() => <Packings currentStatus={PACKING_DELIVERED} />}
        />
        <Route
          path="/paquetes-enviados"
          component={() => <Packings currentStatus={PACKING_DISPATCHED} />}
        />
        <Route path="/plan-despega" component={() => <TakeOfPlan />} />
        <Route
          path="/cerrar"
          component={() => <Logout logout={this.logout} />}
        />
        <Redirect push to="/catalogos" />
      </Switch>
    );
    return (
      <BrowserRouter>
        <Layout component={componentToRender}></Layout>
      </BrowserRouter>
    );
  }
}

export default connect(null, { updateAuth })(App);
