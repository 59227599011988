import React from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Tree } from 'antd';
import { AppstoreOutlined } from '@ant-design/icons';
import UpdateWarehouse from './UpdateWarehouse';

class DisplayWarehouse extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      name,
      street1,
      street2,
      city,
      shippingCities,
    } = this.props.warehouse;

    const treeData = [];
    const countries = {};
    const states = {};
    for (let i = 0; i < shippingCities.length; i += 1) {
      const cityInfo = shippingCities[i];
      const country = cityInfo.state.country.name;
      const state = cityInfo.state.name;
      if (!countries[country]) {
        countries[country] = {
          title: `${country}:0`,
          key: country,
          children: [],
        };
        treeData.push(countries[country]);
      }
      if (!states[state]) {
        states[state] = {
          title: `${state}:0`,
          key: state,
          children: [],
        };
        countries[country].children.push(states[state]);
      }
      states[state].children.push({
        title: cityInfo.name,
        key: `${cityInfo.name + i}`,
      });

      const numCities = countries[country].title.split(':')[1];
      countries[country].title = `${country}:${parseInt(numCities, 0) + 1}`;
      const numCitiesS = states[state].title.split(':')[1];
      states[state].title = `${state}:${parseInt(numCitiesS, 0) + 1}`;
    }

    return (
      <Col span={8} style={{ marginTop: '1em' }}>
        <Card
          hoverable
          style={{ paddingTop: '2em' }}
          cover={
            <div
              style={{
                display: 'inline-flex',
                fontSize: '2em',
                paddingLeft: '1em',
              }}
            >
              <AppstoreOutlined />
              <div style={{ paddingLeft: '0.5em' }}>{name}</div>
            </div>
          }
        >
          <div style={{ textAlign: 'left' }}>
            <br />
            <div>
              Ubicación: <b>{city.name}</b>
            </div>
            <div>Dirección: {street1}</div>
            <div>Detalles de la Dirección: {street2}</div>
            <br />
            Envia a:
            <br />
            <Tree treeData={treeData} />
          </div>
          <UpdateWarehouse
            warehouse={this.props.warehouse}
            reload={this.props.reload}
          />
        </Card>
      </Col>
    );
  }
}

DisplayWarehouse.propTypes = {
  warehouse: PropTypes.object.isRequired,
  reload: PropTypes.func.isRequired,
};

export default DisplayWarehouse;
