import displayError from '../components/Errors/DisplayErrorMessage';

const ls = require('local-storage');
const axios = require('axios');

export const updateProductConfigurationsPrices = async (
  productConfigurations,
) => {
  const url = `${ls.get(
    'gatewayAPI',
  )}/company-service-api/productConfiguration/updatePrices`;
  const headers = { Authorization: `Bearer ${ls.get('authTokenCompany')}` };
  const data = {
    productConfigurations,
  };
  try {
    await axios({ method: 'put', url, data, headers });
    return true;
  } catch (e) {
    displayError('Error actualizando configuraciones de productos', e);
  }
  return false;
};

export const fetchProducts = async (page, pageSize, productConfigIds) => {
  let url = `${ls.get('gatewayAPI')}/company-service-api/product`;
  url += `?page=${page}&pageSize=${pageSize || 1}`;

  if (productConfigIds && productConfigIds.length > 0) {
    url += `&productConfigIds=${JSON.stringify(productConfigIds)}`;
  }

  try {
    const headers = { Authorization: `Bearer ${ls.get('authTokenCompany')}` };
    const result = await axios({ method: 'get', url, headers });
    const total = parseInt(result.headers['pagination-total'], 0);
    return { products: result.data, total };
  } catch (e) {
    displayError('Error recuperando los productos', e);
  }
  return { products: [], total: 0 };
};

export const fetchProductByReference = async (
  productReference,
  productConfigReference,
) => {
  let url = `${ls.get('gatewayAPI')}/company-service-api/product?`;

  if (!productReference && !productConfigReference) {
    return undefined;
  }

  if (productReference && productReference !== '')
    url += `productReference=${productReference}`;
  if (productConfigReference && productConfigReference !== '')
    url += `productConfigurationReference=${productConfigReference}`;

  try {
    const headers = { Authorization: `Bearer ${ls.get('authTokenCompany')}` };
    const result = await axios({ method: 'get', url, headers });
    return result.data[0];
  } catch (e) {
    displayError('Error recuperando el producto', e);
  }
  return undefined;
};

export const fetchProductConfigurations = async (
  pcReferences,
  basePriceVsSellerPriceDifferencePercentage,
  sellerPriceVsRetailPriceDifferencePercentage,
  page,
  pageSize,
) => {
  let url = `${ls.get('gatewayAPI')}/company-service-api/productConfiguration`;
  if (typeof page === 'number' && typeof pageSize === 'number') {
    url += `?page=${page}&pageSize=${pageSize}`;
  } else if (pcReferences) {
    url += `?productConfigurationReferences=${JSON.stringify(pcReferences)}`;
  }
  if (basePriceVsSellerPriceDifferencePercentage)
    url += `&basePriceVsSellerPriceDifferencePercentage=${basePriceVsSellerPriceDifferencePercentage}`;
  if (sellerPriceVsRetailPriceDifferencePercentage)
    url += `&sellerPriceVsRetailPriceDifferencePercentage=${sellerPriceVsRetailPriceDifferencePercentage}`;

  try {
    const headers = { Authorization: `Bearer ${ls.get('authTokenCompany')}` };
    const result = await axios({ method: 'get', url, headers });
    return {
      productConfigurations: result.data,
      total: result.headers['pagination-total'],
    };
  } catch (e) {
    displayError('Error recuperando las Configuraciones de producto', e);
  }
  return {
    productConfigurations: [],
    total: 0,
  };
};

export async function addProductConfigurationMedia(
  productConfigurationId,
  file,
  relevance,
) {
  let url = `${ls.get('gatewayAPI')}/company-service-api/`;
  url += `productConfiguration/${productConfigurationId}/productConfigurationMedia`;

  const headers = {
    Authorization: `Bearer ${ls.get('authTokenCompany')}`,
    'Content-Type': 'multipart/form-data',
  };
  const data = new FormData();
  if (typeof relevance === 'number') data.append('relevance', relevance);
  data.append('file', file);

  try {
    await axios({ method: 'post', url, headers, data });
    return true;
  } catch (e) {
    displayError('Error guardando imagen para configuración de producto.', e);
  }
  return false;
}

export async function getProductConfigMediaByProductConfigId(productConfigId) {
  let url = `${ls.get('gatewayAPI')}/company-service-api/`;
  url += `public/productConfiguration/${productConfigId}/productConfigurationMedia`;

  try {
    const headers = { Authorization: `Bearer ${ls.get('authTokenCompany')}` };
    const result = await axios({ method: 'get', url, headers });
    return result.data;
  } catch (e) {
    displayError(
      'Error recuperando imagenes para configuración de producto.',
      e,
    );
  }
  return undefined;
}

export async function deleteProductConfigMedia(
  productConfigId,
  productConfigMediaId,
) {
  let url = `${ls.get('gatewayAPI')}/company-service-api/`;
  url += `productConfiguration/${productConfigId}/productConfigurationMedia`;
  url += `/${productConfigMediaId}`;

  try {
    const headers = { Authorization: `Bearer ${ls.get('authTokenCompany')}` };
    await axios({ method: 'delete', url, headers });
    return true;
  } catch (e) {
    displayError('Error borrando imagen de configuración de producto.', e);
  }
  return false;
}

export async function deleteAllProductConfigMedia(productConfigId) {
  let url = `${ls.get('gatewayAPI')}/company-service-api/`;
  url += `productConfiguration/${productConfigId}/productConfigurationMedia`;

  try {
    const headers = { Authorization: `Bearer ${ls.get('authTokenCompany')}` };
    await axios({ method: 'delete', url, headers });
    return true;
  } catch (e) {
    displayError('Error borrando imágenes de configuración de producto.', e);
  }
  return false;
}

export async function getCompanyCategories() {
  let url = `${ls.get('gatewayAPI')}/company-service-api/company-category`;
  url += `?pageSize=50&page=0`;
  const headers = { Authorization: `Bearer ${ls.get('authTokenCompany')}` };
  try {
    const result = await axios({ method: 'get', url, headers });
    return result;
  } catch (e) {
    displayError(e, 'Error obteniendo las agrupaciones');
    return false;
  }
}

export async function patchCategories(categoriesSelected, productId) {
  const categoriesIds = Object.keys(categoriesSelected);
  let url = `${ls.get('gatewayAPI')}/company-service-api/product`;
  url += `/${productId}/company-category`;
  const data = { categoriesIds };
  const headers = { Authorization: `Bearer ${ls.get('authTokenCompany')}` };
  try {
    await axios({ method: 'patch', url, headers, data });
    return true;
  } catch (e) {
    displayError('Error actualizando las categorias', e);
    return false;
  }
}
