import displayError from '../components/Errors/DisplayErrorMessage';

const ls = require('local-storage');
const axios = require('axios');

export const changePackingStatus = async (
  warehouseId,
  packingId,
  status,
  boxes,
) => {
  let url = `${ls.get('gatewayAPI')}/warehouse-service-api/warehouse/`;
  url += `${warehouseId}/packing/${packingId}/`;
  if (status === 'PACKING_IN_PROCESS') url += 'mark-as-packing-in-process';
  if (status === 'PACKING_COMPLETED') url += 'mark-as-packing-completed';
  if (status === 'PACKING_RETURNED') url += 'mark-as-packing-returned';
  if (status === 'PACKING_DELIVERED') url += 'mark-as-packing-delivered';
  try {
    const headers = { Authorization: `Bearer ${ls.get('authTokenCompany')}` };
    const data = status === 'PACKING_COMPLETED' ? boxes : undefined;
    const result = await axios({
      method: 'put',
      url,
      data,
      headers,
    });
    return result.data || true;
  } catch (e) {
    displayError('Error actualizando estado del paquete', e);
  }
  return false;
};

export const fetchPackings = async (
  page,
  pageSize,
  currentStatusNames,
  suborderId,
  warehouseId,
  since,
  to,
) => {
  let url = `${ls.get('gatewayAPI')}/warehouse-service-api/warehouse`;
  url += `/${warehouseId}/packing`;
  url += `?page=${page}&pageSize=${pageSize}`;
  if (currentStatusNames)
    url += `&currentStatusNames=${JSON.stringify(currentStatusNames)}`;
  if (suborderId) url += `&suborderId=${suborderId}`;
  if (since) url += `&createdAfter=${since.toISOString()}`;
  if (to) url += `&createdBefore=${to.toISOString()}`;
  try {
    const headers = { Authorization: `Bearer ${ls.get('authTokenCompany')}` };
    const result = await axios({ method: 'get', url, headers });
    return { packings: result.data, total: result.headers['pagination-total'] };
  } catch (e) {
    displayError('Error recuperando paquetes', e);
  }
  return { packings: [], total: 0 };
};
