import XLSX from 'xlsx';

function getRow(data, headers, numRow, errors) {
  let reference = data[headers[0]];
  let id = data[headers[1]];
  let name = data[headers[2]];
  const basePrice = data[headers[3]];
  const fullP = data[headers[4]];
  let description = data[headers[5]];
  const quantity = data[headers[6]];
  let taxName = data[headers[7]];
  let vatPercentage = data[headers[8]];

  reference = reference ? reference.toString().trim() : '';
  if (reference === '') {
    errors.push(
      `Error en la linea: ${numRow + 2}. No hay Ref General. ${
        id ? `Ref Única: ${id}.` : ''
      }`,
    );
  }
  id = id ? id.toString().trim() : null;
  if (id === '') {
    id = null;
  }
  if (!id) {
    errors.push(`Error en la linea: ${numRow + 2}. No hay Ref Única.`);
  }
  name = name ? name.toString().trim() : '';
  if (name === '') {
    errors.push(
      `Error en la linea: ${numRow + 2}. No hay nombre. ${
        id ? `Ref Única: ${id}.` : ''
      }`,
    );
  }
  if (name.length > 250) {
    errors.push(
      `Error en la linea: ${
        numRow + 2
      }. El campo Nombre debe ser menor o igual  a 250 caracteres. ${
        id ? `Ref Única: ${id}.` : ''
      }`,
    );
  }
  if (!basePrice || basePrice.toString().trim() === '') {
    errors.push(
      `Error en la linea: ${numRow + 2}. No hay precio Base. ${
        id ? `Ref Única: ${id}.` : ''
      }`,
    );
  }
  if (!fullP || fullP.toString().trim() === '') {
    errors.push(
      `Error en la linea: ${numRow + 2}. No hay precio público. ${
        id ? `Ref Única: ${id}.` : ''
      }`,
    );
  }
  if (quantity === undefined || quantity.toString().trim() === '') {
    errors.push(
      `Error en la linea: ${numRow + 2}. No hay stock. ${
        id ? `Ref Única: ${id}.` : ''
      }`,
    );
  }
  taxName = taxName || '';
  taxName = taxName.toString().trim().toUpperCase();
  if (taxName === 'EXENTO') {
    taxName = 'EXEMPT';
  } else if (taxName === 'EXCLUIDO') {
    taxName = 'EXCLUDED';
  } else if (taxName === 'GRAVABLE') {
    taxName = 'TAXABLE';
  }

  if (taxName !== 'TAXABLE' && taxName !== 'EXEMPT' && taxName !== 'EXCLUDED') {
    errors.push(
      `Error en la linea: ${
        numRow + 2
      }. Tipo Iva debe ser EXENTO, EXCLUIDO o GRAVABLE. Tipo Iva: ${taxName}. ${
        id ? `Ref Única: ${id}.` : ''
      }`,
    );
  }
  vatPercentage = parseFloat(vatPercentage || 0, 0);
  if (vatPercentage < 1 && vatPercentage > 0) {
    vatPercentage = Math.round(vatPercentage * 100, 0);
  }
  if (vatPercentage !== 19 && vatPercentage !== 5 && vatPercentage !== 0) {
    errors.push(
      `Error en la linea: ${
        numRow + 2
      }. Valor Iva debe ser 19%, 5% o 0. Valor Iva: ${vatPercentage}. ${
        id ? `Ref Única: ${id}.` : ''
      }`,
    );
  }

  description = description ? description.toString().trim() : '';
  if (description === '') {
    errors.push(
      `Error en la linea: ${numRow + 2}. No hay description. ${
        id ? `Ref Única: ${id}.` : ''
      }`,
    );
  }

  return {
    reference,
    id,
    name,
    basePrice,
    fullP,
    description,
    taxName,
    vatPercentage,
  };
}

function getProperties(data, headers) {
  const properties = [];
  for (let i = 0; i < headers.length; i += 1) {
    const value = formatValue(data[headers[i]]);
    if (value !== '' && value !== 'UNDEFINED') {
      const headerName = formatValue(headers[i]);
      properties.push({ name: headerName, value });
    }
  }
  if (properties.length === 0) {
    properties.push({ name: 'UNICO', value: 'UNICO' });
  }
  return properties;
}

function formatProducts(products, headers, variantHeaders) {
  const finalProducts = [];
  const errors = [];
  const productsHash = {};
  const hashOfUniqueReferences = {};
  for (let k = 0; k < products.length; k += 1) {
    const {
      reference,
      id,
      name,
      basePrice,
      fullP,
      description,
      taxName,
      vatPercentage,
    } = getRow(products[k], headers, k, errors);

    if (!hashOfUniqueReferences[id]) hashOfUniqueReferences[id] = 0;
    hashOfUniqueReferences[id] += 1;
    if (hashOfUniqueReferences[id] === 2) {
      errors.push(`Referencia única repetida: ${id}.`);
    }

    if (!productsHash[reference]) {
      productsHash[reference] = {
        name,
        description,
        reference,
        variants: {},
      };
    }
    const properties = getProperties(products[k], variantHeaders);
    const identifier = id;
    productsHash[reference].variants[identifier] = formatVariant(
      id,
      basePrice,
      fullP,
      properties,
      taxName,
      vatPercentage,
      errors,
      k,
    );
  }
  if (errors.length > 0) {
    return { finalProducts: [], errors };
  }
  const keys = Object.keys(productsHash);
  for (let i = 0; i < keys.length; i += 1) {
    const product = productsHash[keys[i]];
    const { name, description, reference } = product;
    const variants = [];
    const variantKeys = Object.keys(product.variants);
    for (let j = 0; j < variantKeys.length; j += 1) {
      variants.push(product.variants[variantKeys[j]]);
    }
    finalProducts.push({
      name,
      description,
      reference,
      variants,
    });
  }
  return { finalProducts, errors };
}

function formatVariant(
  id,
  basePrice,
  fullP,
  properties,
  taxName,
  vatPercentage,
  errors,
  k,
) {
  const retailPr = parseFloat(`${fullP}`.replace('$', '').replace(',', ''));
  const basePr = parseFloat(`${basePrice}`.replace('$', '').replace(',', ''));

  if (Number.isNaN(retailPr) || retailPr < 1000) {
    errors.push(
      `Precio público Invalido. Precio Público: ${retailPr}. Linea: ${
        k + 2
      }. Ref única: ${id}`,
    );
  }
  if (Number.isNaN(basePr) || basePr > retailPr) {
    errors.push(
      `Precio Base Invalido. Precio Base: ${basePr}. Precio público: ${retailPr}. Linea: ${
        k + 2
      }. Ref única: ${id}. Precio base NO puede ser menor al 80% del precio público Ni mayor al precio público`,
    );
  }

  return {
    retailPrice: retailPr,
    basePrice: basePr,
    currencyCode: 'COP',
    images: [],
    properties,
    reference: id,
    vatPercentage,
    taxClassificationName: taxName,
  };
}

// Return "", "UNDEFINED" or String value => trimed and uppercased.
function formatValue(data) {
  return `${data}`.trim().toUpperCase();
}

export const XLSToJsonAddProducts = (file, headers, variantHeaders) =>
  new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);
    // eslint-disable-next-line func-names
    fileReader.onload = function (e) {
      const workbook = XLSX.read(btoa(fixdata(e.target.result)), {
        type: 'base64',
      });
      const workbookJson = toJson(workbook);
      const products = workbookJson[Object.keys(workbookJson)[0]];

      if (!products) {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject({
          type: 'danger',
          msg: 'Este documento no funciona.',
        });
        return;
      }
      products.slice(1);
      const { finalProducts, errors } = formatProducts(
        products,
        headers,
        variantHeaders,
      );
      resolve({ finalProducts, errors });
    };
  });

export const getHeaders = (file) =>
  new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);
    // eslint-disable-next-line func-names
    fileReader.onload = function (e) {
      const workbook = XLSX.read(btoa(fixdata(e.target.result)), {
        type: 'base64',
      });
      const workbookJson = toJson(workbook);
      const products = workbookJson[Object.keys(workbookJson)[0]];
      if (!products || workbook.SheetNames.length !== 1) {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject({
          type: 'danger',
          msg: `Este documento no funciona. ${
            workbook.SheetNames.length !== 1
              ? `Tiene ${workbook.SheetNames.length} hojas y solo puede tener 1.`
              : 'No contiene productos.'
          }`,
        });
        return;
      }
      const keys = Object.keys(products[0]);
      resolve(keys);
    };
  });

function fixdata(data) {
  let o = '';
  let l = 0;
  const w = 10240;
  // eslint-disable-next-line no-plusplus
  for (; l < data.byteLength / w; ++l)
    o += String.fromCharCode.apply(
      null,
      new Uint8Array(data.slice(l * w, l * w + w)),
    );
  o += String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w)));
  return o;
}

function toJson(workbook) {
  const result = {};
  // eslint-disable-next-line func-names
  workbook.SheetNames.forEach(function (sheetName) {
    const roa = XLSX.utils.sheet_to_row_object_array(
      workbook.Sheets[sheetName],
    );
    if (roa.length > 0) {
      result[sheetName] = roa;
    }
  });
  return result;
}
