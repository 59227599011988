import { notification } from 'antd';

export default async function displayError(title, e, aditionalInfo) {
  if (e.response) {
    const error = e.response;
    if (error.status === 404) {
      notification.error({
        message: title,
        duration: 30,
        description: `404: URL Invalida`,
      });
    } else {
      notification.error({
        message: title,
        duration: 10,
        description: `${translateError(
          error.data.errorCode,
          error.data.message,
          aditionalInfo,
        )}`,
        style: { width: 300 },
      });
    }
  } else {
    notification.error({
      message: 'Error desconocido',
      duration: 30,
      description: `${e.message}`,
    });
  }
}

function translateError(code, message, extraInfo) {
  const toSpanish = `${code} - ${message} - ${extraInfo}`;

  return toSpanish;
}
