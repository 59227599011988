import React from 'react';
import PropTypes from 'prop-types';

const ProgressBar = ({ progress, height }) => {
  const parent = {
    height,
    width: '100%',
    backgroundColor: 'whitesmoke',
    borderRadius: 40,
    margin: 30,
  };

  const child = {
    height: '100%',
    width: `${progress}%`,
    backgroundColor: '#06C2AC',
    borderRadius: 40,
    textAlign: 'right',
  };

  return (
    <div style={parent}>
      <div style={child}></div>
    </div>
  );
};

ProgressBar.propTypes = {
  progress: PropTypes.number,
  height: PropTypes.number,
};

export default ProgressBar;
