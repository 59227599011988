import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table, Checkbox, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import DefaultImage from './DefaultImage.png';
const text = { fontSize: '1.2em', color: '#1A002D' };

class CategoriesTable extends Component {
  getCols() {
    const actionColumnName =
      this.props.action === 'add' ? 'Agregar' : 'Asignar';
    return [
      {
        title: 'Imagen',
        dataIndex: '',
        render: (c) => (
          <img
            style={{ height: '100px', width: '80px', borderRadius: '1em' }}
            alt="imagen"
            src={c.imageURL ? c.imageURL : DefaultImage}
          />
        ),
      },
      { title: 'Nombre', dataIndex: 'name' },
      {
        title: (
          <div style={{ display: 'inline-flex' }}>
            <Tooltip title="Determina si la categoria está disponible para que la puedas usar en tus catálogos">
              <div style={{ marginRight: '0.2em' }}>Estado</div>
              <div
                style={{
                  marginTop: '-1.7em',
                  textAlign: 'right',
                  marginRight: '-1em',
                }}
              >
                <QuestionCircleOutlined />
              </div>
            </Tooltip>
          </div>
        ),
        dataIndex: '',
        render: (c) => (
          <div>{c.isAvailable ? 'Habilitada' : 'Deshabilitada'}</div>
        ),
      },
      {
        title: actionColumnName,
        dataIndex: '',
        render: (c) => (
          <div style={{ display: 'inline-flex' }}>
            <Checkbox
              style={text}
              onChange={() => this.props.check(c)}
              checked={!!this.props.categoriesSelected[c.id]}
            />
          </div>
        ),
      },
    ];
  }

  render() {
    return (
      <div>
        <div style={{ display: 'inline-flex' }}>
          <Tooltip
            title={
              this.props.action === 'add'
                ? 'Selecciona las categorías que deseas para este catálogo. Debes seleccionar al menos una.'
                : 'Selecciona o deselecciona las categorías que deseas para este catálogo. Las categorías que ya estaban seleccionadas son las categorías actuales del cátalogo'
            }
          >
            <div style={{ marginRight: '0.2em' }}>
              Selecciona las categorías del catálogo:
            </div>
            <div
              style={{
                marginTop: '-3em',
                textAlign: 'right',
                marginRight: '-1em',
              }}
            >
              <QuestionCircleOutlined />
            </div>
          </Tooltip>
        </div>
        <Table
          rowKey={(c) => c.id}
          dataSource={this.props.categories}
          columns={this.getCols()}
          loading={this.props.loadingCategories}
        />
      </div>
    );
  }
}
CategoriesTable.propTypes = {
  categories: PropTypes.array,
  categoriesSelected: PropTypes.object,
  loadingCategories: PropTypes.bool,
  check: PropTypes.func,
  action: PropTypes.string,
};
export default CategoriesTable;
