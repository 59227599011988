import React from 'react';
import { SettingOutlined } from '@ant-design/icons';
import { Col, Row, notification } from 'antd';
import Content from './Content';
import UpsertContentModal from './UpsertContentModal';
import { fetchContents, deleteContent } from '../../gateway/ContentServices';

class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contents: [],
    };
    this.fetchContents = this.fetchContents.bind(this);
    this.handleReload = this.handleReload.bind(this);
    this.removeContent = this.removeContent.bind(this);
  }

  async fetchContents() {
    const contents = await fetchContents();
    if (contents) this.setState({ contents });
  }

  async removeContent(index) {
    const content = this.state.contents[index];
    const result = await deleteContent(content.id);
    if (result) {
      notification.success({
        message: 'Contenido borrado',
        duration: 5,
        description: `Contenido: ${content.title} ha sido borrado`,
      });
      this.setState((prevState) => {
        const { contents } = prevState;
        contents.splice(index, 1);
        return { contents };
      });
    }
  }

  handleReload() {
    this.setState({ contents: [] }, () => this.fetchContents());
  }

  componentDidMount() {
    this.fetchContents();
  }

  render() {
    return (
      <div>
        <div
          className="row center"
          style={{ fontSize: '1.5em', marginTop: '1em' }}
        >
          <SettingOutlined style={{ marginRight: '10px' }} />
          <div>Configuración empresa</div>
        </div>
        <br />
        <br />
        <Row style={{ margin: '2%' }} gutter={24}>
          <Col style={{ paddingLeft: '25%', paddingRight: '25%' }} span={24}>
            <b>Contenidos</b>
            <br />
            <br />
            <UpsertContentModal handleReload={this.handleReload} />
            <br />
            {this.state.contents.map((content, i) => (
              <Content
                content={content}
                removeContent={this.removeContent}
                handleReload={this.handleReload}
                key={content.id}
                contentIndex={i}
              />
            ))}
          </Col>
        </Row>
      </div>
    );
  }
}

export default Settings;
