import XLSX from 'xlsx';

function getRow(data, headers, numRow, errors) {
  let refG = data[headers[0]];
  let name = data[headers[1]];
  let description = data[headers[2]];
  let companyCategory = data[headers[3]];
  let isDisabled = data[headers[4]];
  let category = data[headers[5]];
  let subCategory = data[headers[6]];
  let subSubCategory = data[headers[7]];
  let discountPercentage = data[headers[8]];

  refG = refG ? refG.toString().trim() : null;
  if (!refG || refG === '') {
    errors.push(`Error in line: ${numRow + 2}. No refG.`);
  }

  name = name ? name.toString().trim() : null;
  if (!name || name === '') {
    errors.push(`Error in line: ${numRow + 2}. No Name.`);
  }

  description = description ? description.toString().trim() : null;
  if (!description || description === '') {
    errors.push(`Error in line: ${numRow + 2}. No description.`);
  }

  companyCategory = companyCategory ? companyCategory.toString().trim() : null;
  if (!companyCategory || companyCategory === '') {
    errors.push(`Error in line: ${numRow + 2}. No companyCategory.`);
  }

  isDisabled = isDisabled ? isDisabled.toString().trim().toLowerCase() : null;
  if (
    !isDisabled ||
    isDisabled === '' ||
    (isDisabled !== 'si' && isDisabled !== 'no')
  ) {
    errors.push(
      `Error in line: ${
        numRow + 2
      }. Invalid isDisabled: ${isDisabled}. Must be 'si' o 'no'`,
    );
  }
  isDisabled = isDisabled ? isDisabled === 'si' : null;

  category = category ? category.toString().trim().toLowerCase() : null;
  if (category === '') {
    category = null;
  }

  subCategory = subCategory
    ? subCategory.toString().trim().toLowerCase()
    : null;
  if (subCategory === '') {
    subCategory = null;
  }

  subSubCategory = subSubCategory
    ? subSubCategory.toString().trim().toLowerCase()
    : null;
  if (subSubCategory === '') {
    subCategory = null;
  }

  discountPercentage =
    typeof discountPercentage === 'number'
      ? parseInt(discountPercentage, 10)
      : null;
  if (discountPercentage < 0) {
    errors.push(
      `Error in line: ${
        numRow + 2
      }. Invalid discountPercentage: ${discountPercentage}. Must be a number equal or greater than zero`,
    );
  }

  return {
    refG,
    name,
    description,
    companyCategory,
    isDisabled,
    category,
    subCategory,
    subSubCategory,
    discountPercentage,
  };
}

function formatProducts(products, headers) {
  const finalProducts = [];
  const errors = [];
  for (let k = 0; k < products.length; k += 1) {
    const {
      refG,
      name,
      description,
      companyCategory,
      isDisabled,
      category,
      subCategory,
      subSubCategory,
      discountPercentage,
    } = getRow(products[k], headers, k, errors);

    finalProducts.push({
      reference: refG,
      name,
      description,
      companyCategory,
      isDisabled,
      category,
      subCategory,
      subSubCategory,
      discountPercentage,
    });
  }
  return { finalProducts, errors };
}

export const XLSToJsonUpdateProducts = (file, headers) =>
  new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);
    // eslint-disable-next-line func-names
    fileReader.onload = function (e) {
      const workbook = XLSX.read(btoa(fixdata(e.target.result)), {
        type: 'base64',
      });
      const workbookJson = toJson(workbook);
      const products = workbookJson[Object.keys(workbookJson)[0]];

      if (!products) {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject({
          type: 'danger',
          msg: 'Este documento no funciona.',
        });
        return;
      }
      products.slice(1);
      const { finalProducts, errors } = formatProducts(products, headers);
      resolve({ finalProducts, errors });
    };
  });

export const getHeaders = (file) =>
  new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);
    // eslint-disable-next-line func-names
    fileReader.onload = function (e) {
      const workbook = XLSX.read(btoa(fixdata(e.target.result)), {
        type: 'base64',
      });
      const workbookJson = toJson(workbook);
      const products = workbookJson[Object.keys(workbookJson)[0]];
      if (!products) {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject({
          type: 'danger',
          msg: 'Este documento no funciona.',
        });
        return;
      }
      const keys = Object.keys(products[0]);
      resolve(keys);
    };
  });

function fixdata(data) {
  let o = '';
  let l = 0;
  const w = 10240;
  // eslint-disable-next-line no-plusplus
  for (; l < data.byteLength / w; ++l)
    o += String.fromCharCode.apply(
      null,
      new Uint8Array(data.slice(l * w, l * w + w)),
    );
  o += String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w)));
  return o;
}

function toJson(workbook) {
  const result = {};
  // eslint-disable-next-line func-names
  workbook.SheetNames.forEach(function (sheetName) {
    const roa = XLSX.utils.sheet_to_row_object_array(
      workbook.Sheets[sheetName],
    );
    if (roa.length > 0) {
      result[sheetName] = roa;
    }
  });
  return result;
}
