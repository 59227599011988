import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Divider, Modal } from 'antd';
import LoadingCard from '../../components/LoadingCard';
import ProductConfiguration from './ProductConfiguration';
import { fetchWarehouses } from '../../gateway/WarehouseServices';
import OverlayLoader from '../../components/OverlayLoader';
import AddNewProductConfiguration from './AddNewProductConfiguration';

function VariantsSection({
  variants,
  product,
  fetchData,
  handleProductConfigurationChange,
  reloadProductConfigurationMedia,
}) {
  const [warehouses, setWarehouses] = useState(null);

  const [loading, setLoading] = useState(false);

  const [isAddingProductConfiguration, setIsAddingProductConfiguration] =
    useState(false);

  useEffect(() => {
    fetchWarehouses().then((response) => setWarehouses(response));
  }, []);

  if (Object.entries(variants).length === 0)
    return <LoadingCard message="Cargando Variantes" />;

  return (
    <div className="variantsSectionContainer" style={{ position: 'relative' }}>
      {loading ? <OverlayLoader /> : null}
      <div className="col gap">
        <div className="card fit">
          <div className="col gap">
            <div style={{ fontWeight: '500' }}>Variantes Disponibles</div>
            <div className="col gap">
              {Object.keys(variants).map((variant) => (
                <div className="row gap no-padding" key={variant}>
                  <h6 style={{ marginBottom: 0 }}>{variant}:</h6>
                  <div className="row gap no-padding">
                    {(variants[variant].length &&
                      variants[variant].map((variantName, index) => (
                        <span key={`${variantName + index}`}>
                          {variantName}
                        </span>
                      ))) ||
                      'Ninguno'}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="card">
          <div className="col">
            <div className="row space-between gap">
              <h2
                className="left no-padding"
                style={{ padding: '10px 0 0 20px', marginBottom: 0 }}
              >
                Configuraciones de Productos
              </h2>
              <button
                type="button"
                className="button primary"
                onClick={() =>
                  setIsAddingProductConfiguration(!isAddingProductConfiguration)
                }
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  columnGap: '0.5em',
                  padding: '0 25px',
                }}
              >
                <ion-icon name="add-outline" size="large"></ion-icon> Agregar
                Configuración
              </button>
            </div>
            <Divider />
            {product.productConfigurations.map((productConfiguration) => (
              <ProductConfiguration
                key={productConfiguration.id}
                productConfiguration={productConfiguration}
                setLoading={setLoading}
                warehouses={warehouses}
                companyId={product.catalog.company.id}
                handleProductConfigurationChange={
                  handleProductConfigurationChange
                }
                fetchData={fetchData}
                reloadProductConfigurationMedia={
                  reloadProductConfigurationMedia
                }
              />
            ))}
          </div>
          <Modal
            visible={isAddingProductConfiguration}
            onCancel={() => setIsAddingProductConfiguration(false)}
            footer={null}
            closeIcon={
              <ion-icon name="close-circle-outline" size="large"></ion-icon>
            }
          >
            <AddNewProductConfiguration
              variants={variants}
              productId={product.id}
              setLoading={setLoading}
              fetchData={fetchData}
              showModal={setIsAddingProductConfiguration}
            />
          </Modal>
        </div>
      </div>
    </div>
  );
}

VariantsSection.propTypes = {
  variants: PropTypes.object,
  product: PropTypes.object,
  fetchData: PropTypes.func,
  handleProductConfigurationChange: PropTypes.func,
  reloadProductConfigurationMedia: PropTypes.func,
};

export default VariantsSection;
