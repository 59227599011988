import React from 'react';
import Phantom from './phantom.svg';
class Sorry extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div style={{ padding: '5%' }}>
        Lo sentimos mucho!
        <br />
        <br />
        <img alt="phantom" src={Phantom} />
        <br />
        <br />
        No pudimos encontrar lo que buscabas.
        <br />
        Recarga la pagina para intentar de nuevo.
      </div>
    );
  }
}

export default Sorry;
