import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Form, Input, InputNumber } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import displayErrorMessage from '../Errors/DisplayErrorMessage';

const ls = require('local-storage');
const axios = require('axios');

class UpdateBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      loadingOnUpdateBox: false,
    };
    this.handleOk = this.handleOk.bind(this);
    this.updateBox = this.updateBox.bind(this);
    this.deleteBox = this.deleteBox.bind(this);
  }

  showModal = () => this.setState({ visible: true });

  handleCancel = () => this.setState({ visible: false });

  handleOk(values) {
    const box = {
      name: values.name,
      maxItems: values.maxItems,
      maxWeight: values.maxWeight,
      length: values.length,
      width: values.width,
      height: values.height,
      sameCityPrice: values.sameCityPrice,
      differentCityPrice: values.differentCityPrice,
    };
    this.updateBox(box);
  }

  async updateBox(box) {
    this.setState({ loadingOnUpdateBox: true });
    let url = `${ls.get('gatewayAPI')}/company-service-api`;
    url += `/shipping-box/${this.props.box.id}`;
    const headers = { Authorization: `Bearer ${ls.get('authTokenCompany')}` };
    try {
      await axios({ method: 'put', url, data: box, headers });
      this.props.reload();
    } catch (e) {
      displayErrorMessage('Error actualizando caja', e);
    }
    this.setState({ loadingOnUpdateBox: false, visible: false });
  }

  async deleteBox() {
    this.setState({ loadingOnDeleteBox: true });
    let url = `${ls.get('gatewayAPI')}/company-service-api`;
    url += `/shipping-box/${this.props.box.id}`;
    const headers = { Authorization: `Bearer ${ls.get('authTokenCompany')}` };
    try {
      await axios({ method: 'delete', url, headers });
      this.props.reload();
    } catch (e) {
      displayErrorMessage('Error eliminando caja', e);
    }
    this.setState({ loadingOnDeleteBox: false, visible: false });
  }

  render() {
    const {
      name,
      maxItems,
      maxWeight,
      length,
      width,
      height,
      sameCityPrice,
      differentCityPrice,
    } = this.props.box;

    return (
      <div style={{ textAlign: 'right' }}>
        <Button
          style={{
            paddingTop: '0.3em',
            paddingBottom: '2.2em',
            color: '#646464',
            borderColor: '#646464',
            backgroundColor: 'white',
          }}
          type="primary"
          onClick={this.showModal}
          loading={this.state.loadingOnUpdateBox}
        >
          <EditOutlined />
        </Button>
        <br />
        <Modal
          title="Ingresa los datos de tu caja"
          visible={this.state.visible}
          onCancel={this.handleCancel}
          footer={[
            <Button key="cancel" onClick={this.handleCancel}>
              Cancelar
            </Button>,
          ]}
        >
          <Form onFinish={this.handleOk} className="Profile-form">
            <Form.Item label="Nombre" name="name" initialValue={name}>
              <Input />
            </Form.Item>
            <Form.Item
              label="Maximo número de articulos"
              name="maxItems"
              initialValue={maxItems}
            >
              <InputNumber />
            </Form.Item>
            <Form.Item
              label="Maximo peso (KG)"
              initialValue={maxWeight}
              name="maxWeight"
            >
              <InputNumber />
            </Form.Item>
            <Form.Item
              label="Dimensiones de la caja: Largo (cm)"
              name="length"
              initialValue={length}
            >
              <InputNumber />
            </Form.Item>
            <Form.Item
              label="Dimensiones de la caja: Ancho (cm)"
              initialValue={width}
              name="width"
            >
              <InputNumber />
            </Form.Item>
            <Form.Item
              label="Dimensiones de la caja: Alto (cm)"
              name="height"
              initialValue={height}
            >
              <InputNumber />
            </Form.Item>
            <Form.Item
              label="Precio misma ciudad"
              initialValue={sameCityPrice}
              name="sameCityPrice"
            >
              <InputNumber
                formatter={(value) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
              />
            </Form.Item>
            <Form.Item
              label="Precio distinta ciudad"
              name="differentCityPrice"
              initialValue={differentCityPrice}
            >
              <InputNumber
                formatter={(value) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={this.state.loadingOnUpdateBox}
              >
                Actualizar caja
              </Button>
            </Form.Item>
          </Form>
          <Button
            type="primary"
            danger
            onClick={this.deleteBox}
            loading={this.state.loadingOnDeleteBox}
          >
            Eliminar caja
          </Button>
        </Modal>
      </div>
    );
  }
}

UpdateBox.propTypes = {
  reload: PropTypes.func.isRequired,
  box: PropTypes.object.isRequired,
};

export default UpdateBox;
