import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Tabs } from 'antd';

import EditProductInformation from './components/EditProductInformation';
import EditVariantInformation from './components/EditVariantInformation';

function EditProductConfiguration({
  productConfigurationToEdit,
  dispatchEditOfProdConfig,
  showEditPC,
}) {
  const [productConfiguration, setProductConfiguration] = useState({
    ...productConfigurationToEdit,
    taxClassificationName: productConfigurationToEdit.taxClassification.name,
  });

  const [dimensions, setDimensions] = useState([]);

  const [copyDimensions, setCopyDimensions] = useState([]);

  const restartValuesOfProductConfiguration = () => {
    setProductConfiguration({
      ...productConfigurationToEdit,
      taxClassificationName: productConfigurationToEdit.taxClassification.name,
    });
    const aux = productConfigurationToEdit.productConfigurationValues.map(
      (item) => ({
        value: item.dimensionValue.value,
        name: item.dimensionValue.dimension.name,
        id: item.id,
        action: null,
      }),
    );
    setDimensions(JSON.parse(JSON.stringify(aux)));
    setCopyDimensions(JSON.parse(JSON.stringify(aux)));
  };

  useEffect(restartValuesOfProductConfiguration, [showEditPC]);

  const handleSaveChanges = () => {
    const newProductConfiguration = { ...productConfiguration };
    dispatchEditOfProdConfig(newProductConfiguration, [...dimensions]);
    setDimensions([]);
  };

  const handleDelete = (variant, index) => {
    let aux = [];
    if (variant.action === 'insert') {
      aux = dimensions.filter((item) => item.id !== variant.id);
    } else {
      aux = JSON.parse(JSON.stringify(dimensions));
      aux[index].action = 'delete';
    }
    setDimensions(aux);
  };

  const handleCancel = (index) => {
    dimensions[index] = copyDimensions[index];
    setDimensions([...dimensions]);
  };

  const handleAddVariant = () => {
    const newObj = {
      id: dimensions.length + 1 * -1,
      value: undefined,
      name: undefined,
      action: 'insert',
    };
    setDimensions([...dimensions, newObj]);
  }

  const handleProductConfigurationChange = (e) => {
    const obj = {
      ...productConfiguration,
      [e.target.name]: e.target.value,
    };
    if (e.target.name === 'taxClassificationName') {
      let vatPercentage = 19;
      if (e.target.value === 'EXEMPT' || e.target.value === 'EXCLUDED') {
        vatPercentage = 0;
      }
      obj.vatPercentage = vatPercentage;
    }
    setProductConfiguration(obj);
  };

  const handleEditDimensions = (key, value, index) => {
    const aux = JSON.parse(JSON.stringify(dimensions));
    if (key === 'value') aux[index].value = value.toUpperCase();
    if (key === 'name') aux[index].name = value.toUpperCase();
    if (!aux[index].action || aux[index].action === 'delete')
      aux[index].action = 'update';

    setDimensions(aux);
  };

  const taxClassificationNames = [
    { name: 'GRAVABLE', value: 'TAXABLE' },
    { name: 'EXENTO', value: 'EXEMPT' },
    { name: 'EXCLUIDO', value: 'EXCLUDED' },
  ];

  const canDelete = () => {
    const deleteValues = dimensions.filter((item) => item.action === 'delete');
    return dimensions.length - deleteValues.length > 1;
  };

  const getRowStyle = (variant) => {
    let className = 'row space-between no-padding ';
    if (!variant.action) className += 'default-row';
    if (variant.action === 'delete') className += 'delete-row';
    if (variant.action === 'update') className += 'update-row';
    if (variant.action === 'insert') className += 'new-row';
    return className;
  };

  const checkAllFields = () => {
    const vatPercentage = parseInt(productConfiguration.vatPercentage, 10) >= 0;
    const validProductConfiguration =
      !productConfiguration.reference ||
      !productConfiguration.basePrice ||
      !productConfiguration.retailPrice ||
      !vatPercentage ||
      !productConfiguration.taxClassificationName;
    const validDimensions = () => {
      const stringValid = dimensions.filter((item) => {
        return (
          !item.value || !item.name || item.value === '' || item.name === ''
        );
      });
      return stringValid.length === 0;
    };
    return !validDimensions() || validProductConfiguration;
  };

  return (
    <div className="card">
      <div className="col">
        <h2>Editar Configuracion de Producto</h2>
        <div className="col">
          <Tabs>
            <Tabs.TabPane tab="Configuración de producto " key="item1">
              <EditProductInformation
                productConfiguration={productConfiguration}
                handleProductConfigurationChange={
                  handleProductConfigurationChange
                }
                taxClassificationNames={taxClassificationNames}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Variante" key="item2">
              <EditVariantInformation
                dimensions={dimensions}
                copyDimensions={copyDimensions}
                setDimensions={setDimensions}
                getRowStyle={getRowStyle}
                handleEditDimensions={handleEditDimensions}
                canDelete={canDelete}
                handleDelete={handleDelete}
                handleCancel={handleCancel}
                handleAddVariant={handleAddVariant}
              />
            </Tabs.TabPane>
          </Tabs>
          <div className="row center">
            <button
              type="button"
              onClick={() => handleSaveChanges()}
              className={`button primary ${
                checkAllFields() ? 'disabled' : null
              }`}
              disabled={checkAllFields()}
              style={{ padding: '5px 20px' }}
            >
              Guardar Cambios
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

EditProductConfiguration.propTypes = {
  productConfigurationToEdit: PropTypes.object,
  dispatchEditOfProdConfig: PropTypes.func,
  showEditPC: PropTypes.bool,
};

export default EditProductConfiguration;
