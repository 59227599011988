import React from 'react';
import PropTypes from 'prop-types';
import { Button, Select, Upload, message, notification, Radio } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import {
  getHeaders,
  getProductConfigsWithQuantity,
} from './ExcelToJsonUpdateInventory';
import { fetchWarehouses } from '../../gateway/WarehouseServices';
import {
  updateInventory,
  createInventory,
} from '../../gateway/InventoryServices';
import { fetchProductConfigurations } from '../../gateway/ProductServices';

const { Option } = Select;

class UpdateInventory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      warehouses: [],
      showUpdateInventory: false,
      inventory: [],
      typeOfRequest: 'CREATE',
      errorsUpsertingInventory: {},
    };
    this.fetchWarehouses = this.fetchWarehouses.bind(this);
    this.proccessFile = this.proccessFile.bind(this);
    this.updateInventory = this.updateInventory.bind(this);
    this.reload = this.reload.bind(this);
    this.close = this.close.bind(this);
  }

  async fetchWarehouses(warehouseName) {
    this.setState({ fetchingWarehouses: true });
    const warehouses = await fetchWarehouses(warehouseName);
    this.setState({ fetchingWarehouses: false, warehouses });
  }

  reload() {
    this.setState({
      inventory: [],
      headers: undefined,
      pcColumn: undefined,
      quantityColumn: undefined,
      errorsUpsertingInventory: {},
    });
  }

  close() {
    this.reload();
    this.setState({ showUpdateInventory: false });
  }

  proccessFile() {
    const { pcColumn, quantityColumn } = this.state;
    const headers = [pcColumn, quantityColumn];
    if (!pcColumn) {
      notification.error({
        message: 'Debes seleccionar la columna de Referencia unica',
        duration: 0,
      });
    } else if (!quantityColumn) {
      notification.error({
        message: 'Debes seleccionar la columna de Inventario',
        duration: 0,
      });
    } else {
      getProductConfigsWithQuantity(this.state.file, headers).then(
        (response) => {
          if (response.errors.length === 0) {
            notification.success({
              message: `Inventario listo`,
              duration: 10,
              description: `${response.finalProducts.length} inventarios seran actualizados.`,
            });
            this.setState({ inventory: response.finalProducts });
          } else {
            notification.error({
              message: `Archivo con errores`,
              duration: 0,
              description: response.errors.join(','),
            });
          }
        },
      );
    }
  }

  async updateInventory() {
    this.setState({ updatingInventory: true });

    const { inventory, warehouseId } = this.state;

    const allPCReferences = [];
    const pcReferences = [];
    let currentPCReferencesList = [];

    for (let i = 0; i < inventory.length; i += 1) {
      const { pcReference } = inventory[i];

      allPCReferences.push(pcReference);
      currentPCReferencesList.push(pcReference);

      if (
        currentPCReferencesList.length === 100 ||
        i === inventory.length - 1
      ) {
        pcReferences.push(currentPCReferencesList);
        currentPCReferencesList = [];
      }
    }

    const AllProductConfigurations = [];

    for (let i = 0; i < pcReferences.length; i += 1) {
      const listOfPCReferences = pcReferences[i];

      // eslint-disable-next-line no-await-in-loop
      const { productConfigurations } = await fetchProductConfigurations(
        listOfPCReferences,
      );
      AllProductConfigurations.push(...productConfigurations);
    }

    if (AllProductConfigurations.length !== allPCReferences.length) {
      let pcRepeated = {};
      const productConfigReferencesFoundHash = {};
      for (let i = 0; i < allPCReferences.length; i += 1) {
        const reference = allPCReferences[i];
        if (!productConfigReferencesFoundHash[reference]) {
          productConfigReferencesFoundHash[reference] = true;
        } else {
          pcRepeated[reference] = true;
        }
      }
      for (let i = 0; i < AllProductConfigurations.length; i += 1) {
        const foundPc = AllProductConfigurations[i];
        delete productConfigReferencesFoundHash[foundPc.reference];
      }

      notification.error({
        message: `Error.\nSe encontraron ${AllProductConfigurations.length} configuraciones para actualizar las ${allPCReferences.length} configuraciones solicitadas.`,
        duration: 0,
      });
      pcRepeated = Object.keys(pcRepeated);
      const pcNotFound = Object.keys(productConfigReferencesFoundHash);
      this.setState({
        updatingInventory: false,
        errorsUpsertingInventory: {
          pcRepeated,
          pcNotFound,
        },
      });
      return;
    }

    const producConfigInfoByProductConfigReferenceHash = {};

    for (let i = 0; i < AllProductConfigurations.length; i += 1) {
      const pc = AllProductConfigurations[i];
      producConfigInfoByProductConfigReferenceHash[pc.reference] = {
        pcId: pc.id,
        pcOwnerId: pc.product.catalog.company.id,
      };
    }

    const groupedInventories = [];

    let inventoryTemp = [];

    for (let i = 0; i < inventory.length; i += 1) {
      const currentInventory = inventory[i];

      if (
        producConfigInfoByProductConfigReferenceHash[
          currentInventory.pcReference
        ].pcId
      ) {
        inventoryTemp.push({
          stock: currentInventory.stock,
          productConfigurationId:
            producConfigInfoByProductConfigReferenceHash[
              currentInventory.pcReference
            ].pcId,
          ownerId:
            producConfigInfoByProductConfigReferenceHash[
              currentInventory.pcReference
            ].pcOwnerId,
        });

        if (inventoryTemp.length === 400) {
          groupedInventories.push(inventoryTemp);
          inventoryTemp = [];
        }
      }
    }
    if (inventoryTemp.length > 0) {
      groupedInventories.push(inventoryTemp);
    }

    for (let i = 0; i < groupedInventories.length; i += 1) {
      const inventoryToUpdate = groupedInventories[i];

      if (this.state.typeOfRequest === 'CREATE') {
        // eslint-disable-next-line no-await-in-loop
        await createInventory(warehouseId, inventoryToUpdate);
      } else if (this.state.typeOfRequest === 'UPDATE') {
        // eslint-disable-next-line no-await-in-loop
        await updateInventory(warehouseId, inventoryToUpdate);
      }
    }

    this.setState({ updatingInventory: false });
    this.reload();
    this.props.reload();
  }

  componentDidMount() {
    this.fetchWarehouses('');
  }

  render() {
    return (
      <div>
        <br />
        <div style={{ textAlign: 'right' }}>
          <button
            type="button"
            className="button primary large"
            onClick={() => this.setState({ showUpdateInventory: true })}
          >
            Crear o Actualizar inventario con archivo
          </button>
        </div>
        {this.state.showUpdateInventory && (
          <div
            style={{
              textAlign: 'center',
              boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
              paddingTop: '5%',
              marginBottom: '5em',
            }}
          >
            <div style={{ width: '100%', textAlign: 'right' }}>
              <Button
                style={{
                  paddingTop: '1em',
                  marginRight: '2em',
                  paddingBottom: '2em',
                  color: '#646464',
                  borderColor: '#646464',
                  backgroundColor: 'white',
                }}
                type="primary"
                onClick={() => this.close()}
              >
                Cancelar actualización de inventario
              </Button>
            </div>
            <br />
            <Select
              showSearch
              onSearch={this.fetchWarehouses}
              placeholder="Escribe el nombre de una bodega"
              style={{ width: 300 }}
              loading={this.state.fetchingWarehouses}
              filterOption={false}
              onSelect={(warehouseId) => this.setState({ warehouseId })}
            >
              {this.state.warehouses.map((warehouse) => (
                <Option key={warehouse.id}>{warehouse.name}</Option>
              ))}
            </Select>
            <br />
            <br />
            <div>
              <div style={{ width: '100%', textAlign: 'right' }}>
                <Button
                  style={{
                    paddingTop: '1em',
                    marginRight: '2em',
                    paddingBottom: '3em',
                    color: '#646464',
                    borderColor: '#646464',
                    backgroundColor: 'white',
                  }}
                  type="primary"
                  onClick={() => this.reload()}
                >
                  Subir otro archivo
                </Button>
              </div>
              <br />
              <div>
                <Radio.Group
                  onChange={(e) =>
                    this.setState({ typeOfRequest: e.target.value })
                  }
                  value={this.state.typeOfRequest}
                >
                  <Radio value="CREATE">
                    Crear (si no existe en la bodega)
                  </Radio>
                  <Radio value="UPDATE">
                    Actualizar (si ya existe en la bodega)
                  </Radio>
                </Radio.Group>
              </div>
              <br />
              <Upload
                accept=".xlsx"
                showUploadList={false}
                beforeUpload={(file) => {
                  getHeaders(file)
                    .then((fileHeaders) =>
                      this.setState({ headers: fileHeaders, file }),
                    )
                    .catch((err) => message.error(err));
                  return false;
                }}
              >
                <Button>
                  <UploadOutlined /> Subir Archivo
                </Button>
              </Upload>
              <br />

              {this.state.headers && (
                <>
                  <Select
                    placeholder="Referencia Unica"
                    style={{ width: 200 }}
                    onChange={(value) => this.setState({ pcColumn: value })}
                  >
                    {this.state.headers.map((header) => (
                      <Option key={header} value={header}>
                        {header}
                      </Option>
                    ))}
                  </Select>
                  <Select
                    placeholder="Inventario"
                    style={{ width: 200 }}
                    onChange={(value) =>
                      this.setState({ quantityColumn: value })
                    }
                  >
                    {this.state.headers.map((header) => (
                      <Option key={header} value={header}>
                        {header}
                      </Option>
                    ))}
                  </Select>
                  <br />
                  <br />
                  <Button
                    type="primary"
                    onClick={this.proccessFile}
                    disabled={this.state.inventory.length !== 0}
                  >
                    Confirmar
                  </Button>
                </>
              )}
              <br />
              <br />
              {this.state.inventory.length !== 0 && (
                <>
                  <Button
                    type="primary"
                    onClick={this.updateInventory}
                    loading={this.state.updatingInventory}
                  >
                    {`Actualizar ${this.state.inventory.length} inventarios`}
                  </Button>
                </>
              )}
              <br />
              {this.state.errorsUpsertingInventory.pcRepeated && (
                <div>
                  {this.state.errorsUpsertingInventory.pcRepeated.map((pc) => (
                    <div style={{ color: 'red' }}>
                      Product Config Repetida: {pc}
                    </div>
                  ))}
                </div>
              )}
              {this.state.errorsUpsertingInventory.pcNotFound && (
                <div>
                  {this.state.errorsUpsertingInventory.pcNotFound.map(
                    (pc, i) => (
                      <div style={{ color: 'red' }}>
                        Product Config no encontrada #{i}: {pc}
                      </div>
                    ),
                  )}
                </div>
              )}
              <br />
            </div>
          </div>
        )}
        <br />
      </div>
    );
  }
}

UpdateInventory.propTypes = {
  reload: PropTypes.func,
};

export default UpdateInventory;
