import React from 'react';
import TakeOffPlanFeatureCard from '../../components/TakeOffPlan/TakeOffPlanFeatureCard';
import { Button } from 'antd';
import stage1 from '../../assets/images/TakeOffPlan/etapa-1.png';
import stage2 from '../../assets/images/TakeOffPlan/etapa-2.png';
import stage3 from '../../assets/images/TakeOffPlan/etapa-3.png';
import stage4 from '../../assets/images/TakeOffPlan/etapa-4.png';
import { useMatchMedia } from '../../hooks/useMatchMedia';
import bannerDesktop from '../../assets/images/TakeOffPlan/banner-desktop.png';
import bannerMobile from '../../assets/images/TakeOffPlan/banner-mobile.png';
import './TakeOffPlan.scss';
function TakeOfPlan() {
  const isMobile = useMatchMedia(600, true);
  const features = [
    {
      cardImg: stage1,
      cardText: (
        <p>
          Aprende a escoger el <strong>producto ganador</strong>
        </p>
      ),
      stateIndColor: '#06C2AC',
    },
    {
      cardImg: stage2,
      cardText: (
        <p>
          Aprende a <strong>escribir para vender</strong>
        </p>
      ),
      stateIndColor: '#C3A4D0',
    },
    {
      cardImg: stage3,
      cardText: (
        <p>
          Crea <strong>videos</strong> que rompen el scroll
        </p>
      ),
      stateIndColor: '#FD89CF',
    },
    {
      cardImg: stage4,
      cardText: (
        <p>
          Crea <strong>campañas</strong> de venta escalable
        </p>
      ),
      stateIndColor: '#63CFFB',
    },
  ];
  return (
    <div className="take-off-page">
      <h1 className="take-off-page-title">Aumenta tus ventas</h1>
      <div className="take-off-page-content">
        <div className="take-off-page-banner">
          <h2 className="take-off-page-subtitle">
            Curso de ventas en línea - Plan Despega
          </h2>
          <img
            className="bannerImg"
            src={isMobile ? bannerMobile : bannerDesktop}
            alt=""
          />
        </div>

        <div className="take-off-plan-description">
          <p>
            Nos aliamos con Plan Despega para traerte un curso que te permita
            vender en línea como un profesional.
          </p>
          <p>
            Las 4 etapas del curso te llevarán paso a paso para escalar tu
            negocio digital, desde los fundamentos de un producto hasta lanzar
            campañas publicitarias; todo enfocado en el modelo de venta
            dropshipping.
          </p>
        </div>
        <div className="take-off-plan-features">
          {features.map((feature, index) => (
            <TakeOffPlanFeatureCard
              stageNo={index + 1}
              cardImg={feature.cardImg}
              cardText={feature.cardText}
              stageIndColor={feature.stateIndColor}
            />
          ))}
        </div>
        <div className="take-off-plan-button-container">
          <Button
            className="take-off-plan-button"
            onClick={() => window.open('https://plandespega.com/flipcat')}
          >
            Me interesa
          </Button>
        </div>
      </div>
    </div>
  );
}

export default TakeOfPlan;
