import React from 'react';
import { Button, Empty, Row } from 'antd';
import { DropboxOutlined } from '@ant-design/icons';
import CreateBox from './CreateBox';
import Errors from '../Errors/Errors';
import DisplayBox from './DisplayBox';
import displayErrorMessage from '../Errors/DisplayErrorMessage';

const ls = require('local-storage');
const axios = require('axios');

class Boxes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      boxes: [],
      errorStatus: undefined,
      fetchingBoxes: false,
    };
    this.fetchBoxes = this.fetchBoxes.bind(this);
    this.reload = this.reload.bind(this);
  }

  reload() {
    this.setState({ boxes: [], fetchingBoxes: false }, () => this.fetchBoxes());
  }

  async fetchBoxes() {
    this.setState({ fetchingBoxes: true });
    const url = `${ls.get('gatewayAPI')}/company-service-api/shipping-box`;
    const headers = { Authorization: `Bearer ${ls.get('authTokenCompany')}` };
    try {
      const result = await axios({ method: 'get', url, headers });
      const boxes = result.data.sort((a, b) => (a.name > b.name ? 1 : -1));
      this.setState({ boxes });
    } catch (e) {
      displayErrorMessage('Error recuperando cajas', e);
      this.setState({ errorStatus: e.response ? e.response.status : 500 });
    } finally {
      this.setState({ fetchingBoxes: false });
    }
  }

  componentDidMount() {
    this.fetchBoxes();
  }

  render() {
    return (
      <div>
        <div style={{ fontSize: '1.5em', marginTop: '1em' }}>
          <DropboxOutlined />
          <div>Cajas</div>
        </div>
        <br />
        <br />
        <div style={{ width: '100%', textAlign: 'center' }}>
          <Button
            style={{ height: '3em' }}
            type="primary"
            onClick={this.reload}
            loading={this.state.fetchingBoxes}
          >
            Recargar
          </Button>
        </div>
        <br />
        <CreateBox reload={this.reload} />
        <br />
        <Errors errorStatus={this.state.errorStatus} />
        <Row gutter={24} style={{ paddingLeft: '10%', paddingRight: '10%' }}>
          {this.state.boxes.map((box) => (
            <DisplayBox box={box} reload={this.reload} key={box.name} />
          ))}
        </Row>
        {this.state.boxes.length === 0 && <Empty />}
      </div>
    );
  }
}

export default Boxes;
