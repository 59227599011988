import React from 'react';
import { Col, Row, Checkbox } from 'antd';
import { ReadOutlined, FallOutlined, LoadingOutlined } from '@ant-design/icons';
import CompanyCard from './CompanyCard';
import Errors from '../Errors/Errors';
import CreateCatalog from './CreateCatalog';
import { fetchCatalogs } from '../../gateway/CatalogServices';
import displayError from '../Errors/DisplayErrorMessage';
class Catalogs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      catalogsLoading: false,
      public: true,
      private: false,
      allowO: true,
      errorStatus: undefined,
      dontAllowO: false,
      colSpanValueXl: 0,
      colSpanValueLg: 0,
      filteredCatalogs: [],
      catalogs: [],
    };
    this.fetchCatalogs = this.fetchCatalogs.bind(this);
    this.onChangePublic = this.onChangePublic.bind(this);
    this.onChangePrivate = this.onChangePrivate.bind(this);
    this.onChangeAllowO = this.onChangeAllowO.bind(this);
    this.onChangeDontAllowO = this.onChangeDontAllowO.bind(this);
  }

  applyFilter(catalogs) {
    const filteredCatalogs = [];
    for (let i = 0; i < catalogs.length; i += 1) {
      if (catalogs[i].isPublic && this.state.public) {
        filteredCatalogs.push(catalogs[i]);
      } else if (!catalogs[i].isPublic && this.state.private) {
        filteredCatalogs.push(catalogs[i]);
      } else if (catalogs[i].allowOrders && this.state.allowO) {
        filteredCatalogs.push(catalogs[i]);
      } else if (!catalogs[i].allowOrders && this.state.dontAllowO) {
        filteredCatalogs.push(catalogs[i]);
      }
    }
    let colSpanValueXl = 0;
    let colSpanValueLg = 0;
    if (filteredCatalogs.length === 3) {
      colSpanValueXl = 8;
      colSpanValueLg = 8;
    } else if (filteredCatalogs.length === 2) {
      colSpanValueXl = 12;
      colSpanValueLg = 8;
    } else {
      colSpanValueXl = 6;
      colSpanValueLg = 8;
    }
    this.setState({
      filteredCatalogs,
      colSpanValueXl,
      colSpanValueLg,
    });
  }

  async fetchCatalogs() {
    this.setState({ catalogsLoading: true });
    try {
      const catalogs = await fetchCatalogs();
      if (catalogs.data) {
        this.applyFilter(catalogs.data);
        this.setState({ catalogs: catalogs.data });
      }
    } catch (e) {
      this.setState({ errorStatus: e.response ? e.response.status : 500 });
      displayError('Error obteniendo los catálogos', e);
    } finally {
      this.setState({ catalogsLoading: false });
    }
  }

  async onChangePublic() {
    await this.setState((prev) => ({ public: !prev.public }));
    this.applyFilter(this.state.catalogs);
  }

  async onChangePrivate() {
    await this.setState((prev) => ({ private: !prev.private }));
    this.applyFilter(this.state.catalogs);
  }

  async onChangeAllowO() {
    await this.setState((prev) => ({ allowO: !prev.allowO }));
    this.applyFilter(this.state.catalogs);
  }

  async onChangeDontAllowO() {
    await this.setState((prev) => ({ dontAllowO: !prev.dontAllowO }));
    this.applyFilter(this.state.catalogs);
  }

  componentDidMount() {
    this.fetchCatalogs();
  }

  render() {
    return (
      <div>
        <div
          className="row center"
          style={{ fontSize: '1.5em', marginTop: '1em' }}
        >
          <ReadOutlined style={{ marginRight: '10px' }} />
          <div>Catálogos:</div>
        </div>
        <br />
        <CreateCatalog fetchCatalogs={this.fetchCatalogs} />
        <br />
        <br />
        <div>
          <Checkbox onChange={this.onChangePublic} checked={this.state.public}>
            Publicos
          </Checkbox>
          <Checkbox
            onChange={this.onChangePrivate}
            checked={this.state.private}
          >
            Privados
          </Checkbox>
          <Checkbox onChange={this.onChangeAllowO} checked={this.state.allowO}>
            Permiten ordenes
          </Checkbox>
          <Checkbox
            onChange={this.onChangeDontAllowO}
            checked={this.state.dontAllowO}
          >
            No permiten ordenes
          </Checkbox>
        </div>
        <br />
        <br />
        <div>
          {this.state.filteredCatalogs.length === 0 && (
            <div>
              <FallOutlined />
              No tienes catalogos aún!
            </div>
          )}
          {this.state.catalogsLoading && <LoadingOutlined />}
          <Row>
            {this.state.filteredCatalogs.map((item) => (
              <Col
                key={item.id}
                md={12}
                lg={this.state.colSpanValueLg}
                xl={this.state.colSpanValueXl}
                style={{ marginBottom: '2em' }}
              >
                <CompanyCard item={item} />
              </Col>
            ))}
          </Row>
        </div>
        <Errors errorStatus={this.state.errorStatus} />
      </div>
    );
  }
}

export default Catalogs;
