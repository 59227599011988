import React from 'react';
import { Select, Table, InputNumber, notification } from 'antd';
import { CheckOutlined, LoadingOutlined } from '@ant-design/icons';
import UpdateInventory from './UpdateInventory';

import { fetchWarehouses } from '../../gateway/WarehouseServices';
import {
  fetchInventory,
  updateInventory,
} from '../../gateway/InventoryServices';
import {
  fetchProducts,
  fetchProductByReference,
} from '../../gateway/ProductServices';
import './inventories.scss';

const { Option } = Select;

class Inventory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: { current: 1, pageSize: 10 },
      warehouses: [],
      inventory: [],
      fetchingInventory: false,
      fetchingWarehouses: false,
      productConfigurationIds: [],
      stocksToUpdate: {},
    };
    this.fetchInventory = this.fetchInventory.bind(this);
    this.fetchWarehouses = this.fetchWarehouses.bind(this);
    this.fetchTotal = this.fetchTotal.bind(this);
    this.setProductConfigurationId = this.setProductConfigurationId.bind(this);
    this.setProductId = this.setProductId.bind(this);
    this.reload = this.reload.bind(this);
    this.updateStocks = this.updateStocks.bind(this);
  }

  getCols() {
    const cols = [
      {
        title: 'Referencia General',
        dataIndex: '',
        render: (p) => (
          <div style={{ color: p.color }}>{p.productReference}</div>
        ),
      },
      { title: 'Referencia Única', dataIndex: 'productConfigurationReference' },
      { title: 'Nombre', dataIndex: 'name' },
      { title: 'Propiedades', dataIndex: 'properties', key: 'properties' },
      { title: 'Compañia', dataIndex: 'companyName' },
      { title: 'Catálogo', dataIndex: 'catalogName' },
      { title: 'Bodega', dataIndex: 'warehouseName', key: 'warehouseName' },
      {
        title: 'Cantidad',
        dataIndex: '',
        key: 'stock',
        render: (row) => (
          <div>
            {this.state.stocksToUpdate[
              `${row.productConfigurationId}-${row.warehouseId}`
            ] && ' *'}
            <InputNumber
              min={0}
              defaultValue={row.stock}
              onChange={(newQuantity) => this.onChangeStock(newQuantity, row)}
            />
          </div>
        ),
      },
    ];
    return cols;
  }

  reload() {
    this.setState(
      {
        pagination: { current: 1, pageSize: 10 },
        inventory: [],
      },
      () => this.fetchInventory(this.state.pagination),
    );
  }

  async fetchInventory(params) {
    this.setState({ fetchingInventory: true });
    const { pageSize, current } = params;
    const { warehouseId, minStock, maxStock } = this.state;
    const { productConfigurationIds } = this.state;
    const { inventory, total } = await fetchInventory(
      current - 1,
      pageSize,
      warehouseId,
      undefined,
      minStock,
      maxStock,
      JSON.stringify(productConfigurationIds),
    );
    const neededProductConfigurations = inventory.map(
      (currentInventory) => currentInventory.productConfigurationId,
    );
    const { products } = await fetchProducts(
      0,
      neededProductConfigurations.length,
      neededProductConfigurations,
    );
    const hashProductByProductConfigurationId = {};
    for (let i = 0; i < products.length; i += 1) {
      const product = products[i];
      for (let j = 0; j < product.productConfigurations.length; j += 1) {
        const productConfiguration = product.productConfigurations[j];
        hashProductByProductConfigurationId[productConfiguration.id] = product;
      }
    }
    const inventoryParsed = this.parseResults(
      inventory,
      hashProductByProductConfigurationId,
    );
    await this.fetchTotal();
    this.setState({
      fetchingInventory: false,
      inventory: inventoryParsed,
      minStockSearched: minStock,
      maxStockSearched: maxStock,
      total,
      pagination: { ...params, total },
    });
  }

  async fetchWarehouses(warehouseName) {
    this.setState({ fetchingWarehouses: true });
    const warehouses = await fetchWarehouses(warehouseName);
    this.setState({ fetchingWarehouses: false, warehouses });
  }

  async fetchTotal() {
    const { warehouseId } = this.state;
    const { total } = await fetchInventory(0, 1, warehouseId);
    this.setState({ globalTotal: total });
  }

  parseResults(inventory, hashProductByProductConfigurationId) {
    const parsedProducts = [];
    for (let i = 0; i < inventory.length; i += 1) {
      const { stock, productConfigurationId, warehouse } = inventory[i];
      const warehouseName = warehouse.name;
      const warehouseId = warehouse.id;
      const product =
        hashProductByProductConfigurationId[productConfigurationId];
      let properties = '';
      let productConfigurationValues;
      let productConfiguration;
      if (product) {
        productConfiguration = product.productConfigurations.find(
          (pc) => pc.id === productConfigurationId,
        );
        productConfigurationValues =
          productConfiguration.productConfigurationValues;
        for (let l = 0; l < productConfigurationValues.length; l += 1) {
          const { dimensionValue } = productConfigurationValues[l];
          properties += `${dimensionValue.dimension.name}: ${dimensionValue.value} `;
        }
      }
      let color = 'black';
      if (stock === 0) {
        color = 'red';
      } else if (stock <= 5) {
        color = '#CCCC00';
      }

      const parsedProduct = {
        color,
        stock,
        properties,
        warehouseName,
        warehouseId,
        productConfigurationId,
        name: product?.name,
        catalogName: product?.catalog.name,
        companyName: product?.catalog.company.name,
        productReference: product?.reference,
        productConfigurationReference: productConfiguration?.reference,
      };
      parsedProducts.push(parsedProduct);
    }
    return parsedProducts;
  }

  async setProductConfigurationId(productConfigReference) {
    this.setState({ isPerformingApiCompositionProdConfigReference: true });
    const productConfigurationReference = productConfigReference.trim();
    const product = await fetchProductByReference(
      undefined,
      productConfigurationReference,
    );
    if (product) {
      const productConfigurationIds = [];
      for (let i = 0; i < product.productConfigurations.length; i += 1) {
        const productConfig = product.productConfigurations[i];
        if (productConfig.reference === productConfigurationReference) {
          productConfigurationIds.push(productConfig.id);
        }
      }
      this.setState({ productConfigurationIds, productConfigurationReference });
    } else {
      this.setState({
        productConfigurationIds: [],
        productConfigurationReference,
      });
    }
    this.setState({ isPerformingApiCompositionProdConfigReference: false });
  }

  getProductConfigReferenceFieldSuffix() {
    if (this.state.isPerformingApiCompositionProdConfigReference) {
      return <LoadingOutlined />;
    }
    if (
      this.state.productConfigurationIds.length > 0 &&
      this.state.productConfigurationReference
    ) {
      return <CheckOutlined />;
    }
    return <span />;
  }

  async setProductId(prodReference) {
    this.setState({ isPerformingApiCompositionProdReference: true });
    const productReference = prodReference.trim();
    const product = await fetchProductByReference(productReference, undefined);
    if (product) {
      if (product.reference === productReference) {
        const productConfigurationIds = product.productConfigurations.map(
          (pc) => pc.id,
        );
        this.setState({ productConfigurationIds, productReference });
      }
    } else {
      this.setState({ productConfigurationIds: [], productReference });
    }
    this.setState({ isPerformingApiCompositionProdReference: false });
  }

  getProductReferenceFieldSuffix() {
    if (this.state.isPerformingApiCompositionProdReference) {
      return <LoadingOutlined />;
    }
    if (
      this.state.productConfigurationIds.length > 0 &&
      this.state.productReference
    ) {
      return <CheckOutlined />;
    }
    return <span />;
  }

  async updateStocks() {
    const { stocksToUpdate } = this.state;
    const keys = Object.keys(stocksToUpdate);
    if (keys.length === 0) {
      notification.error({
        message: `No se actualizó ningún inventario`,
        duration: 0,
        description: `No has cambiado manualmente ningún inventario de la tabla`,
      });
      return;
    }
    this.setState({ updatingStocks: true });
    for (let i = 0; i < keys.length; i += 1) {
      const stockToUpdate = stocksToUpdate[keys[i]];
      // eslint-disable-next-line no-await-in-loop
      await updateInventory(stockToUpdate.warehouseId, [
        {
          stock: stockToUpdate.stock,
          productConfigurationId: stockToUpdate.productConfigurationId,
        },
      ]);
    }
    this.setState({ stocksToUpdate: {}, updatingStocks: false });
    this.reload();
  }

  onChangeStock(newQuantity, pc) {
    this.setState((prevState) => {
      const { stocksToUpdate } = prevState;
      const id = `${pc.productConfigurationId}-${pc.warehouseId}`;
      if (!stocksToUpdate[id]) {
        stocksToUpdate[id] = {};
      }
      stocksToUpdate[id] = {
        warehouseId: pc.warehouseId,
        stock: newQuantity,
        productConfigurationId: pc.productConfigurationId,
      };

      return { stocksToUpdate };
    });
  }

  componentDidMount() {
    this.fetchWarehouses();
  }

  render() {
    return (
      <div>
        <div className="row center">
          <span className="viewTitle">
            <ion-icon
              name="bag-handle-outline"
              style={{ height: '25px', width: '25px', marginRight: '10px' }}
            ></ion-icon>
            <h2>Inventarios</h2>
          </span>
        </div>

        <UpdateInventory
          reload={this.reload}
          warehouses={this.state.warehouses}
        />
        <br />
        <div className="row center card" style={{ flexDirection: 'row' }}>
          {/* Input fields column */}
          <div
            className="col center no-padding"
            style={{ maxWidth: '800px', width: '80%' }}
          >
            <div
              className="row center no-padding"
              style={{ width: '100%', marginBottom: '10px' }}
            >
              <div
                className="col no-padding center"
                style={{ maxWidth: '200px', minWidth: '120px', width: '100%' }}
              >
                <div className="inputLabel">Bodega:</div>
                <Select
                  className="antSelect"
                  style={{ width: '100%' }}
                  loading={this.state.fetchingWarehouses}
                  filterOption={false}
                  onSelect={(warehouseId) => this.setState({ warehouseId })}
                >
                  {this.state.warehouses.map((warehouse) => (
                    <Option key={warehouse.id}>{warehouse.name}</Option>
                  ))}
                </Select>
              </div>
              <div
                className="col no-padding center"
                style={{ maxWidth: '200px', minWidth: '120px', width: '100%' }}
              >
                <div className="inputLabel">Referencia General:</div>
                <div style={{ display: 'inline-flex' }}>
                  <input
                    type="text"
                    className="small"
                    placeholder="Referencia general"
                    onChange={(e) => this.setProductId(e.target.value)}
                  />
                  {this.getProductReferenceFieldSuffix()}
                </div>
              </div>
              <div
                className="col no-padding center"
                style={{ maxWidth: '200px', minWidth: '120px', width: '100%' }}
              >
                <div className="inputLabel">Referencia Única:</div>
                <div style={{ display: 'inline-flex' }}>
                  <input
                    type="text"
                    className="small"
                    placeholder="Referencia única"
                    onChange={(e) =>
                      this.setProductConfigurationId(e.target.value)
                    }
                  />
                  {this.getProductConfigReferenceFieldSuffix()}
                </div>
              </div>
            </div>
            <hr />
            <div className="row center no-padding" style={{ width: '100%' }}>
              <div
                className="col no-padding center"
                style={{ maxWidth: '200px', minWidth: '120px', width: '100%' }}
              >
                <div className="inputLabel">Inventario Mínimo:</div>
                <input
                  type="text"
                  className="small"
                  placeholder="Inventario mínimo"
                  value={this.state.minStock}
                  onChange={(e) => this.setState({ minStock: e.target.value })}
                />
              </div>
              <div
                className="col no-padding center"
                style={{ maxWidth: '200px', minWidth: '120px', width: '100%' }}
              >
                <div className="inputLabel">Inventario Máximo:</div>
                <input
                  type="text"
                  className="small"
                  placeholder="Inventario máximo"
                  value={this.state.maxStock}
                  onChange={(e) => this.setState({ maxStock: e.target.value })}
                />
              </div>
            </div>
          </div>
          {/* Button column */}
          <div
            className="col center"
            style={{ maxWidth: '480px', minWidth: '150px', width: '20%' }}
          >
            <button
              type="button"
              className={`button primary small ${
                (!this.state.warehouseId ||
                  this.state.isPerformingApiCompositionProdReference ||
                  this.state.isPerformingApiCompositionProdConfigReference) &&
                'disabled'
              }`}
              onClick={this.reload}
            >
              {(this.state.fetchingInventory ||
                this.state.fetchingWarehouses) && (
                <LoadingOutlined
                  style={{ marginRight: '10px' }}
                ></LoadingOutlined>
              )}
              Buscar
            </button>
          </div>
        </div>
        <div className="row">
          <div
            className="col no-padding"
            style={{ marginLeft: '0', marginRight: 'auto' }}
          >
            {this.state.globalTotal && (
              <div className="inputLabel">
                Total configuraciones: {this.state.globalTotal}
              </div>
            )}
            {this.state.globalTotal && this.state.total && (
              <div className="inputLabel">
                Existen {this.state.total} configuraciones con stock entre{' '}
                {this.state.minStockSearched || 0} y{' '}
                {this.state.maxStockSearched || 'max'} (
                {Math.floor((this.state.total / this.state.globalTotal) * 100)}
                %)
              </div>
            )}
          </div>
          <div
            className="col center no-padding"
            style={{ marginLeft: 'auto', marginRight: '0' }}
          >
            <button
              type="button"
              className="button primary center"
              onClick={this.updateStocks}
            >
              {this.state.updatingStocks ? (
                <LoadingOutlined
                  style={{ marginRight: '10px' }}
                ></LoadingOutlined>
              ) : (
                <ion-icon
                  name="save-outline"
                  style={{ width: '20px', height: '20px', marginRight: '5px' }}
                ></ion-icon>
              )}
              Guardar cambios
            </button>
          </div>
        </div>
        <Table
          bordered
          showHeader
          rowKey={(p) =>
            `${p.productConfigurationId}-${p.warehouseId}-${p.catalogName}`
          }
          columns={this.getCols()}
          dataSource={this.state.inventory}
          pagination={this.state.pagination}
          onChange={this.fetchInventory}
          loading={this.state.fetchingInventory}
        />
        <br />
      </div>
    );
  }
}

export default Inventory;
