import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Table, Checkbox, notification } from 'antd';
import {
  getCompanyCategories,
  patchCategories,
} from '../../gateway/ProductServices';

const text = { fontSize: '1.2em', color: '#1A002D' };

class ModifyProductCompanyCategories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categoriesSelected: {},
    };
    this.getAllCompanyCategories = this.getAllCompanyCategories.bind(this);
    this.patchCategoriesOfProduct = this.patchCategoriesOfProduct.bind(this);
    this.check = this.check.bind(this);
  }

  async getAllCompanyCategories() {
    this.setState({ loadingCategories: true });
    const result = await getCompanyCategories();
    if (result) {
      this.setState({ categories: result.data });
    }
    this.setState({ loadingCategories: false });
  }

  async patchCategoriesOfProduct() {
    this.setState({ loadingUpdateCategories: true });
    const message = 'Categorías actualizadas';
    const description = 'Categorías actualizadas';
    const result = await patchCategories(
      this.state.categoriesSelected,
      this.props.product.id,
    );
    if (result) {
      notification.success({ message, description });
      this.setState({ visible: false });
      this.props.reload();
    }
    this.setState({ loadingUpdateCategories: false });
  }

  getCols() {
    return [
      { title: 'Nombre', dataIndex: 'name' },
      {
        title: 'Estado',
        dataIndex: '',
        render: (c) => <div>{c.isActive ? 'Habilitada' : 'Deshabilitada'}</div>,
      },
      {
        title: 'Asignar',
        dataIndex: '',
        render: (c) => (
          <div style={{ display: 'inline-flex' }}>
            <Checkbox
              style={text}
              onChange={() => this.check(c)}
              checked={!!this.state.categoriesSelected[c.id]}
            />
          </div>
        ),
      },
    ];
  }

  check(category) {
    this.setState((prevState) => {
      const { categoriesSelected } = prevState;
      if (categoriesSelected[category.id]) {
        delete categoriesSelected[category.id];
      } else {
        categoriesSelected[category.id] = true;
      }
      return { categoriesSelected };
    });
  }

  componentDidMount() {
    this.getAllCompanyCategories();
    const categoriesSelected = {};
    for (let i = 0; i < this.props.product.companyCategories.length; i += 1) {
      const category = this.props.product.companyCategories[i];
      categoriesSelected[category.id] = category;
    }
    this.setState({ categoriesSelected });
  }

  render() {
    return (
      <div>
        <button
          type="button"
          className="button secondary"
          style={{ height: '22px', width: '100%' }}
          onClick={() => this.setState({ visible: true })}
        >
          Asignar
        </button>
        <Modal
          title="Categorías disponibles"
          visible={this.state.visible}
          onCancel={() => this.setState({ visible: false })}
          footer={[
            <Button
              key="back"
              onClick={() => this.setState({ visible: false })}
            >
              Cancelar
            </Button>,
            <Button
              key="submit"
              type="primary"
              loading={this.state.loadingUpdateCategories}
              onClick={this.patchCategoriesOfProduct}
            >
              Guardar
            </Button>,
          ]}
        >
          <div>
            <div style={text}>Selecciona las categorías a agregar</div>
            <br />
            <Table
              rowKey={(c) => c.id}
              dataSource={this.state.categories}
              columns={this.getCols()}
              loading={this.state.loadingCategories}
            />
          </div>
        </Modal>
      </div>
    );
  }
}
ModifyProductCompanyCategories.propTypes = {
  product: PropTypes.object,
  reload: PropTypes.func,
};
export default ModifyProductCompanyCategories;
