import displayError from '../components/Errors/DisplayErrorMessage';

const ls = require('local-storage');
const axios = require('axios');

export async function fetchContents() {
  const url = `${ls.get('gatewayAPI')}/company-service-api/company/content`;
  const headers = { Authorization: `Bearer ${ls.get('authTokenCompany')}` };
  try {
    const result = await axios({ method: 'get', url, headers });
    return result.data;
  } catch (e) {
    displayError('Error recuperando contenidos', e);
  }
  return undefined;
}

export async function deleteContent(contentId) {
  let url = `${ls.get('gatewayAPI')}`;
  url += '/company-service-api/company/';
  url += `content/${contentId}`;
  const headers = { Authorization: `Bearer ${ls.get('authTokenCompany')}` };
  try {
    await axios({ method: 'delete', url, headers });
    return true;
  } catch (e) {
    displayError('Error borrando contenido', e);
  }
  return false;
}

export async function addContent(title, description) {
  const url = `${ls.get('gatewayAPI')}/company-service-api/company/content`;
  const headers = { Authorization: `Bearer ${ls.get('authTokenCompany')}` };
  const data = { title, description };
  try {
    const result = await axios({ method: 'post', url, data, headers });
    return result.data;
  } catch (e) {
    displayError('Error guardando contenido', e);
  }
  return undefined;
}

export async function updateContent(contentId, title, description) {
  let url = `${ls.get('gatewayAPI')}/company-service-api/`;
  url += `company/content/${contentId}`;
  const headers = { Authorization: `Bearer ${ls.get('authTokenCompany')}` };
  const data = {};
  if (title) data.title = title;
  if (description) data.description = description;
  try {
    await axios({ method: 'patch', url, data, headers });
    return true;
  } catch (e) {
    displayError('Error actualizando contenido', e);
  }
  return false;
}
