import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Input, message, Select, notification } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import displayErrorMessage from '../Errors/DisplayErrorMessage';

const { Option } = Select;

const ls = require('local-storage');
const axios = require('axios');

class UpdateWarehouse extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingCountries: false,
      loadingStates: false,
      loadingCities: false,
      countries: [],
      selectedCountry: undefined,
      states: [],
      selectedState: undefined,
      cities: [],
      selectedCity: undefined,
      // To send
      cityOrigin: undefined,
      destinyCities: [],
      destinyStates: [],
      destinyCountries: [],
      visible: false,
      loadingOnUpdateWarehouse: false,
    };

    this.handleChangeCountry = this.handleChangeCountry.bind(this);
    this.handleChangeState = this.handleChangeState.bind(this);
    this.handleChangeCity = this.handleChangeCity.bind(this);

    this.addCurrentCountryADestiny = this.addCurrentCountryADestiny.bind(this);
    this.addCurrentStateAsDestiny = this.addCurrentStateAsDestiny.bind(this);
    this.addCurrentCityAsDestiny = this.addCurrentCityAsDestiny.bind(this);

    this.addCurrentCityAsOrigin = this.addCurrentCityAsOrigin.bind(this);

    this.handleOk = this.handleOk.bind(this);
    this.updateWarehouse = this.updateWarehouse.bind(this);
  }

  showModal = () => this.setState({ visible: true });

  handleCancel = () => this.setState({ visible: false });

  handleOk() {
    const { cityOrigin, name, street1, street2 } = this.state;
    const { destinyCountries, destinyStates, destinyCities } = this.state;
    const destinyValidation =
      destinyCountries.length + destinyStates.length + destinyCities.length > 0;
    if (cityOrigin && destinyValidation && name && street1) {
      const shippingCountries = [];
      for (let i = 0; i < destinyCountries.length; i += 1) {
        shippingCountries.push(destinyCountries[i].split('.Name:')[0]);
      }
      const shippingStates = [];
      for (let i = 0; i < destinyStates.length; i += 1) {
        shippingStates.push(destinyStates[i].split('.Name:')[0]);
      }
      const shippingCities = [];
      for (let i = 0; i < destinyCities.length; i += 1) {
        shippingCities.push(destinyCities[i].split('.Name:')[0]);
      }
      const warehouse = {
        name,
        cityId: cityOrigin.split('.Name:')[0],
        street1,
        street2,
        shippingCountries,
        shippingStates,
        shippingCities,
      };
      this.updateWarehouse(warehouse);
      this.setState({
        visible: false,
        loadingOnUpdateWarehouse: true,
      });
    } else {
      message.error('Ingresa todos los campos!');
    }
  }

  handleChangeCountry(value) {
    this.setState({
      selectedCountry: value,
      states: [],
      selectedState: undefined,
      cities: [],
      selectedCity: undefined,
    });
    this.fetchStates(value);
  }

  handleChangeState(value) {
    this.setState({
      selectedState: value,
      cities: [],
      selectedCity: undefined,
    });
    this.fetchCities(value);
  }

  handleChangeCity(value) {
    this.setState({ selectedCity: value });
  }

  isDuplicate(array, value) {
    for (let i = 0; i < array.length; i += 1) {
      if (array[i] === value) return true;
    }
    return false;
  }

  addCurrentCountryADestiny() {
    this.setState((prevState) => {
      const country = prevState.selectedCountry;
      if (!this.isDuplicate(prevState.destinyCountries, country)) {
        prevState.destinyCountries.push(country);
      }
      return { destinyCountries: prevState.destinyCountries };
    });
  }

  addCurrentStateAsDestiny() {
    this.setState((prevState) => {
      const state = prevState.selectedState;
      if (!this.isDuplicate(prevState.destinyStates, state)) {
        prevState.destinyStates.push(state);
      }
      return { destinyStates: prevState.destinyStates };
    });
  }

  displayUpdateWarehouseError(e) {
    const error = e.response;
    let errorMessage = `${error.status} : ${error.data.message}`;
    if (
      error.data.message ===
      'duplicate key value violates unique constraint "warehouse_location_name_unique_company"'
    ) {
      errorMessage = 'No se puede crear una bodega con el mismo nombre';
    }
    notification.error({
      message: `Ocurrió un problema`,
      duration: 0,
      description: errorMessage,
    });
  }

  addCurrentCityAsDestiny() {
    this.setState((prevState) => {
      const city = prevState.selectedCity;
      if (!this.isDuplicate(prevState.destinyCities, city)) {
        prevState.destinyCities.push(city);
      }
      return { destinyCities: prevState.destinyCities };
    });
  }

  addCurrentCityAsOrigin() {
    this.setState((prevState) => ({ cityOrigin: prevState.selectedCity }));
  }

  async updateWarehouse(warehouse) {
    this.setState({ loadingOnUpdateWarehouse: true });
    let url = `${ls.get('gatewayAPI')}/company-service-api`;
    url += `/warehouse-location/${this.props.warehouse.id}`;
    const headers = { Authorization: `Bearer ${ls.get('authTokenCompany')}` };
    try {
      await axios({ method: 'put', url, data: warehouse, headers });
      this.props.reload();
    } catch (e) {
      this.displayUpdateWarehouseError(e);
    }
    this.setState({ loadingOnUpdateWarehouse: false });
  }

  async fetchCountries() {
    this.setState({ loadingCountries: true });
    const headers = { Authorization: `Bearer ${ls.get('authTokenCompany')}` };
    const url = `${ls.get('gatewayAPI')}/company-service-api/country`;
    try {
      const result = await axios({ method: 'get', url, headers });
      this.setState({ countries: result.data });
    } catch (e) {
      displayErrorMessage('Error obteniendo los países', e);
    }
    this.setState({ loadingCountries: false });
  }

  async fetchStates(value) {
    this.setState({ loadingStates: true });
    const id = value.split('.Name:')[0];
    const headers = { Authorization: `Bearer ${ls.get('authTokenCompany')}` };
    let url = `${ls.get('gatewayAPI')}/company-service-api`;
    url += `/country/${id}/state`;
    try {
      const result = await axios({ method: 'get', url, headers });
      this.setState({ states: result.data });
    } catch (e) {
      displayErrorMessage('Error obteniendo los departamentos', e);
    }
    this.setState({ loadingStates: false });
  }

  async fetchCities(value) {
    this.setState({ loadingCities: true });
    const id = value.split('.Name:')[0];
    const headers = { Authorization: `Bearer ${ls.get('authTokenCompany')}` };
    const url = `${ls.get('gatewayAPI')}/company-service-api/state/${id}/city`;
    try {
      const result = await axios({ method: 'get', url, headers });
      this.setState({ cities: result.data });
    } catch (e) {
      displayErrorMessage('Error obteniendo las ciudades', e);
    }
    this.setState({ loadingCities: false });
  }

  componentDidMount() {
    this.fetchCountries();
    this.setState({
      name: this.props.warehouse.name,
      street1: this.props.warehouse.street1,
      street2: this.props.warehouse.street2,
    });
  }

  render() {
    return (
      <div>
        <div style={{ textAlign: 'right' }}>
          <Button
            style={{
              paddingTop: '0.3em',
              paddingBottom: '2.2em',
              color: '#646464',
              borderColor: '#646464',
              backgroundColor: 'white',
            }}
            type="primary"
            onClick={this.showModal}
            loading={this.state.loadingOnUpdateWarehouse}
          >
            <EditOutlined />
          </Button>
        </div>
        <br />
        <Modal
          title="Ingresa los datos de tu bodega"
          visible={this.state.visible}
          onCancel={this.handleCancel}
          footer={[
            <Button
              key="close"
              type="primary"
              danger
              onClick={this.handleCancel}
            >
              Cancelar
            </Button>,
            <Button key="create" type="primary" onClick={this.handleOk}>
              Actualizar bodega
            </Button>,
          ]}
        >
          Nombre:
          <Input
            value={this.state.name}
            onChange={(v) => this.setState({ name: v.target.value })}
          />
          <br />
          <br />
          Dirección:
          <Input
            value={this.state.street1}
            onChange={(v) => this.setState({ street1: v.target.value })}
          />
          <br />
          <br />
          Especificaciones de la dirección:
          <Input
            value={this.state.street2}
            onChange={(v) => this.setState({ street2: v.target.value })}
          />
          <br />
          <br />
          Datos de la locación:
          <br />
          <Select
            style={{ width: 130 }}
            onChange={this.handleChangeCountry}
            loading={this.state.loadingCountries}
          >
            {this.state.countries.map((country) => (
              <Option
                key={country.id}
                value={`${country.id}.Name:${country.name}`}
              >
                {country.name}
              </Option>
            ))}
          </Select>
          <Select
            style={{ width: 160 }}
            onChange={this.handleChangeState}
            loading={this.state.loadingStates}
          >
            {this.state.states.map((state) => (
              <Option key={state.id} value={`${state.id}.Name:${state.name}`}>
                {state.name}
              </Option>
            ))}
          </Select>
          <Select
            style={{ width: 180 }}
            onChange={this.handleChangeCity}
            loading={this.state.loadingCities}
          >
            {this.state.cities.map((city) => (
              <Option key={city.id} value={`${city.id}.Name:${city.name}`}>
                {city.name}
              </Option>
            ))}
          </Select>
          <br />
          <br />
          {this.state.selectedCountry && !this.state.selectedState && (
            <Button type="primary" onClick={this.addCurrentCountryADestiny}>
              Esta bodega puede enviar a todo el país
            </Button>
          )}
          {this.state.selectedState && !this.state.selectedCity && (
            <Button type="primary" onClick={this.addCurrentStateAsDestiny}>
              Esta bodega puede enviar a todo el departamento
            </Button>
          )}
          {this.state.selectedCity && (
            <div>
              <Button type="primary" onClick={this.addCurrentCityAsOrigin}>
                Agregar esta ciudad como origen
              </Button>
              <br />
              <br />
              <Button type="primary" onClick={this.addCurrentCityAsDestiny}>
                Agregar esta ciudad como Destino
              </Button>
            </div>
          )}
          <br />
          <br />
          Esta bodega esta ubicada en:{' '}
          <b>
            {this.state.cityOrigin
              ? this.state.cityOrigin.split('Name:')[1]
              : ''}
          </b>
          <br />
          <br />
          Esta bodega puede enviar a:
          <br />
          <br />
          {this.state.destinyCountries.map((country) => (
            <div key={country.split(':')[0]}>- {country.split('Name:')[1]}</div>
          ))}
          {this.state.destinyStates.map((state) => (
            <div key={state.split(':')[0]}>- {state.split('Name:')[1]}</div>
          ))}
          {this.state.destinyCities.map((city) => (
            <div key={city.split(':')[0]}>- {city.split('Name:')[1]}</div>
          ))}
          <br />
        </Modal>
      </div>
    );
  }
}

UpdateWarehouse.propTypes = {
  reload: PropTypes.func.isRequired,
  warehouse: PropTypes.object.isRequired,
};

export default UpdateWarehouse;
