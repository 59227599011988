import React from 'react';
import { Form, Input, Button, Divider, notification } from 'antd';
import { MailOutlined, LockOutlined, LoadingOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import flipcatSmallLogo from './flipcatSmallLogo.png';

const axios = require('axios');
const ls = require('local-storage');

class MobileView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleErrorDisplay(error) {
    if (error.data) {
      if (error.data.message === 'Incorrect password') {
        notification.error({
          message: 'Contraseña incorrecta',
          duration: 0,
        });
      } else if (error.data.message === 'Email not found') {
        notification.error({
          message: 'El correo ingresado no correspone a una cuenta registrada',
          duration: 0,
        });
      } else {
        notification.error({
          message: `Ocurrió un problema`,
          duration: 0,
          description: `${error.status} : ${error.data.message}`,
        });
      }
    } else {
      notification.error({
        message: `Ocurrió un problema`,
        duration: 0,
        description: `${error.status} : Error`,
      });
    }
  }

  async handleSubmit(values) {
    this.setState({ loading: true });
    const url = `${ls.get('gatewayAPI')}/identity-service-api/auth/login/`;
    const headers = { Authorization: `Bearer ${ls.get('authTokenCompany')}` };
    try {
      const result = await axios({
        method: 'post',
        url,
        data: values,
        headers,
      });
      this.props.login(result.data.accessToken, result.data.refreshToken);
    } catch (e) {
      const error = e.response;
      this.handleErrorDisplay(error);
    } finally {
      this.setState({ loading: false });
    }
  }

  render() {
    return (
      <div
        style={{
          background:
            'linear-gradient( #1A002D, #06C2AC)',
          height: window.innerHeight,
          width: '100%',
        }}
      >
        <img style={{ margin: '10%', width: 75, height: 75 }} src={flipcatSmallLogo} alt="Flipcat" />
        <div
          style={{
            background: 'white',
            height: window.innerHeight,
            borderRadius: '3em',
          }}
        >
          <br />
          <br />
          <br />
          <br />
          <div style={{ marginRight: '35%' }}>
            <div
              style={{
                fontFamily: 'Lato',
                fontWeight: 'bold',
                fontSize: '18px',
                textAlign: 'left',
                lineHeight: '35px',
                marginLeft: '25%',
                color: '#1A002D',
              }}
            >
              Iniciar sesión
            </div>
            <div style={{ width: '44%', marginLeft: '25%' }}>
              <Divider style={{ borderTop: '1.5px solid #06C2AC' }} />
            </div>
            <br />
            <Form onFinish={this.handleSubmit}>
              <div
                style={{
                  fontFamily: 'Lato',
                  textAlign: 'left',
                  marginLeft: '25%',
                  color: '#1A002D',
                }}
              >
                Correo electrónico
              </div>
              <br />
              <Form.Item name="email">
                <Input
                  placeholder="Ingresa tu Correo"
                  style={{
                    width: '100%',
                    height: '2em',
                    fontSize: '1.5em',
                    marginLeft: '25%',
                  }}
                  prefix={<MailOutlined />}
                />
              </Form.Item>
              <div
                style={{
                  fontFamily: 'Lato',
                  textAlign: 'left',
                  marginLeft: '25%',
                  color: '#1A002D',
                }}
              >
                Contraseña
              </div>
              <br />
              <Form.Item name="password">
                <Input.Password
                  placeholder="Ingresa tu contrasena"
                  style={{
                    width: '100%',
                    height: '2em',
                    fontSize: '1.5em',
                    marginLeft: '25%',
                  }}
                  prefix={<LockOutlined />}
                />
              </Form.Item>
              <br />
              <br />
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{
                    width: '100%',
                    height: '2em',
                    fontSize: '1.5em',
                    background: '#1A002D',
                    border: 'none',
                    borderRadius: '11px',
                    marginLeft: '25%',
                  }}
                >
                  {this.state.loading && <LoadingOutlined />}
                  {!this.state.loading && (
                    <div style={{ color: 'white', fontSize: '0.7em' }}>
                      Ingresar
                    </div>
                  )}
                </Button>
                <br />
                <br />
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

MobileView.propTypes = {
  login: PropTypes.func,
};

export default MobileView;
