import React from 'react';
import PropTypes from 'prop-types';
import Plot from 'react-plotly.js';

class ProductsCharts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <Plot
          data={[
            {
              type: 'bar',
              y: this.props.topProductsData.variant,
              x: this.props.topProductsData.units,
              text: this.props.topProductsData.units,
              hovertemplate: 'unidades=%{x}<br>referencia=%{y}<extra></extra>',
              orientation: 'h',
            },
          ]}
          layout={{
            width: '100%',
            height: '100%',
            title: 'Top productos',
            xaxis: { title: { text: 'cantidad' } },
            yaxis: { title: { text: 'variante' }, showticklabels: false },
          }}
          config={{
            displaylogo: false,
            displayModeBar: false,
          }}
        />
      </div>
    );
  }
}

ProductsCharts.propTypes = {
  topProductsData: PropTypes.object.isRequired,
};

export default ProductsCharts;
