import axios from 'axios';
import { notification } from 'antd';
import displayErrorMessage from '../../components/Errors/DisplayErrorMessage';
const ls = require('local-storage');

export async function fetchProduct(id) {
  const url = `${ls.get('gatewayAPI')}/company-service-api/product/${id}`;
  const headers = { Authorization: `Bearer ${ls.get('authTokenCompany')}` };
  try {
    const result = await axios({ method: 'get', url, headers });
    const product = result.data;
    return product;
  } catch (e) {
    return displayErrorMessage('Error recuperando producto', e);
  }
}

export function handleProduct(product) {
  const finalProduct = { ...product };
  const variantsInfo = {};
  for (let i = 0; i < product.productConfigurations.length; i += 1) {
    const { variantName, variantDisplay } = getVariant(
      product.productConfigurations[i],
      variantsInfo,
    );
    finalProduct.productConfigurations[i].variantName = variantName;
    finalProduct.productConfigurations[i].variantDisplay = variantDisplay;
    finalProduct.productConfigurations[i].taxClassification =
      product.productConfigurations[i].taxClassification;
  }
  const orderedProductConfig = product.productConfigurations.sort((a, b) =>
    a.variantName.localeCompare(b.variantName),
  );
  finalProduct.productConfigurations = orderedProductConfig;
  const { isDisabled } = finalProduct;
  return { productInfo: finalProduct, variantsInfo, isDisabled };
}

export function getVariant(productConfiguration, variantsInfo) {
  const variants = {};
  const values = productConfiguration.productConfigurationValues;
  for (let i = 0; values && i < values.length; i += 1) {
    const { value } = values[i].dimensionValue;
    const { name } = values[i].dimensionValue.dimension;
    variants[name] = value;
    // eslint-disable-next-line no-param-reassign
    if (!variantsInfo[name]) variantsInfo[name] = [];
    if (productConfiguration.isAvailable) {
      // eslint-disable-next-line no-param-reassign
      variantsInfo[name].push(value);
    }
  }
  const ordered = Object.keys(variants || {}).sort();

  let variantName = '';
  for (let i = 0; i < ordered.length; i += 1) {
    variantName += `${ordered[i]}: ${variants[ordered[i]]}.`;
  }
  const variantDisplay = ordered.map((name) => `${name}: ${variants[name]}`);

  return { variantName, variantDisplay };
}

export async function updateProduct(product) {
  let url = `${ls.get('gatewayAPI')}/company-service-api`;
  url += `/product/${product.id}`;
  const headers = { Authorization: `Bearer ${ls.get('authTokenCompany')}` };

  // eslint-disable-next-line no-param-reassign
  delete product.commercialAgreement;

  const data = product;
  try {
    await axios({ method: 'patch', url, headers, data });
    return notification.success({
      message: `Producto actualizado`,
      duration: 10,
      description: `Los datos del producto fueron actualizados.`,
    });
  } catch (e) {
    return displayErrorMessage('Error actualizando este producto', e);
  }
}
export async function updateProductStatus(product) {
  let url = `${ls.get('gatewayAPI')}/company-service-api`;
  url += `/product/${product.id}`;
  const headers = { Authorization: `Bearer ${ls.get('authTokenCompany')}` };
  const data = product;
  try {
    await axios({ method: 'patch', url, headers, data });
    return notification.success({
      message: `Producto actualizado`,
      duration: 10,
      description: `El producto ha sido ${
        !product.isDisabled ? 'deshabilitado' : 'habilitado'
      }`,
    });
  } catch (e) {
    return displayErrorMessage('Error actualizando este producto', e);
  }
}

export async function addProductImage(product, newImage) {
  let url = `${ls.get('gatewayAPI')}/company-service-api`;
  url += `/catalog/${product.catalog.id}/productImage`;
  const headers = {
    Authorization: `Bearer ${ls.get('authTokenCompany')}`,
    'Content-Type': 'multipart/form-data',
  };
  const data = new FormData();
  data.append('file', newImage.file);
  data.append('reference', product.reference);
  data.append('isCoverImage', newImage.isCoverImage);
  try {
    await axios({ method: 'post', url, headers, data });
    return notification.success({
      message: `Imagen añadida`,
      duration: 10,
      description: `Nueva imagen añadida.`,
    });
  } catch (e) {
    return displayErrorMessage('Error actualizando este producto', e);
  }
}

export async function deleteImage(productId, imageId) {
  let url = `${ls.get('gatewayAPI')}/company-service-api`;
  url += `/product/${productId}/productMedia/${imageId}`;
  const headers = { Authorization: `Bearer ${ls.get('authTokenCompany')}` };
  try {
    await axios({ method: 'delete', url, headers });
    return notification.success({
      message: `Imagen borrada`,
      duration: 10,
      description: `Imagen eliminada.`,
    });
  } catch (e) {
    return displayErrorMessage('Error borrando esta imagen', e);
  }
}

export async function updateImage(productId, image) {
  let url = `${ls.get('gatewayAPI')}/company-service-api`;
  url += `/product/${productId}/productMedia/${image.id}`;
  const headers = { Authorization: `Bearer ${ls.get('authTokenCompany')}` };
  const data = {
    isCoverImage: image.isCoverImage,
  };
  try {
    await axios({ method: 'patch', url, headers, data });
    return notification.success({
      message: `Imagen actualizada`,
      duration: 10,
      description: `Imagen actualizada.`,
    });
  } catch (e) {
    return displayErrorMessage('Error actualizando esta imagen', e);
  }
}

export function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    notification.error({
      message: `Imagen con error`,
      duration: 0,
      description: `La imagen ${file.name} debe ser .jpg o .png`,
    });
  }
  const isLt2M = file.size / 1024 < 9500;
  if (!isLt2M) {
    notification.error({
      message: `Imagen con error`,
      duration: 0,
      description: `La imagen ${file.name} debe ser menor a 500KB`,
    });
  }
  return isJpgOrPng && isLt2M;
}

export async function updateProductConfiguration(
  productConfiguration,
  propertiesToAdd,
  propertiesToDelete,
) {
  let url = `${ls.get('gatewayAPI')}/company-service-api`;
  url += `/product-configuration/${productConfiguration.id}`;
  const headers = { Authorization: `Bearer ${ls.get('authTokenCompany')}` };
  const data = {
    ...productConfiguration,
    propertiesToDelete,
    propertiesToAdd,
    retailPrice: parseFloat(productConfiguration.retailPrice),
    basePrice: parseFloat(productConfiguration.basePrice),
    vatPercentage: parseInt(productConfiguration.vatPercentage, 0),
  };

  try {
    await axios({ method: 'patch', url, headers, data });
    notification.success({
      message: `Configuracion de producto actualizada`,
      duration: 10,
      description: `La configuracion se actualizo satisfactoriamente.`,
    });
    return true;
  } catch (e) {
    displayErrorMessage('Error actualizando la configuración de producto', e);
    return false;
  }
}

export function addVariant(properties, variantName) {
  for (let i = 0; i < properties.length; i += 1) {
    const property = properties[i];
    if (property.name === `${variantName}`.trim().toUpperCase()) {
      return notification.warning({
        message: 'Dimensión Repetida',
        duration: 0,
        description: `Ya agregaste la dimensión ${variantName} a esta configuración`,
      });
    }
  }
  return true;
}

export async function addProductConfiguration(
  productId,
  newProductCondiguration,
) {
  let url = `${ls.get('gatewayAPI')}/company-service-api`;
  url += `/product/${productId}/product-configuration`;
  const headers = { Authorization: `Bearer ${ls.get('authTokenCompany')}` };
  const data = {
    ...newProductCondiguration,
    retailPrice: parseFloat(newProductCondiguration.retailPrice),
    basePrice: parseFloat(newProductCondiguration.basePrice),
  };

  try {
    await axios({ method: 'post', url, headers, data });

    notification.success({
      message: 'Configuración Creada',
      duration: 30,
      description: ``,
    });
  } catch (e) {
    displayErrorMessage('Error creando la nueva configuración producto', e);
  }
}
