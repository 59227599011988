import displayError from '../components/Errors/DisplayErrorMessage';

const ls = require('local-storage');
const axios = require('axios');
export const fetchWarehouseBoxes = async (warehouseId) => {
  let url = `${ls.get('gatewayAPI')}/warehouse-service-api/`;
  url += `warehouse/${warehouseId}/box`;

  try {
    const headers = { Authorization: `Bearer ${ls.get('authTokenCompany')}` };
    const result = await axios({ method: 'get', url, headers });
    return result.data;
  } catch (e) {
    displayError('Error recuperando las bodegas', e);
  }
  return [];
};
