import React, { Component } from 'react';
import { BarChartOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import OrdersCharts from './OrdersCharts';
import SellersCharts from './SellersCharts';
import ProductsCharts from './ProductsCharts';
import displayErrorMessage from '../Errors/DisplayErrorMessage';
const ls = require('local-storage');
const axios = require('axios');
const { Option } = Select;
const DAILY_ORDERS = 'orders/daily';
const MONTHLY_ORDERS = 'orders/monthly';
const TOP_STATES_ORDERS = 'orders/top-states';
const TOP_CITIES_ORDERS = 'orders/top-cities';
const TOP_PRODUCTS = 'products/top-products';
const GENDER_SELLER = 'sellers/gender';
const AGE_SELLER = 'sellers/age';

class AnalyticsCharts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dailyOrdersData: [],
      monthlyOrdersData: [],
      topCitiesData: [],
      topStatesData: [],
      topProductsData: [],
      sellersAgeData: [],
      sellersGenderData: [],
      currentFilter: 'Ordenes',
      filters: [
        { name: 'Ordenes' },
        { name: 'Productos' },
        { name: 'Vendedores' },
      ],
    };
    this.fetchAllData = this.fetchAllData.bind(this);
    this.makeFetchRequest = this.makeFetchRequest.bind(this);
  }

  async fetchAllData() {
    this.makeFetchRequest(DAILY_ORDERS);
    this.makeFetchRequest(MONTHLY_ORDERS);
    this.makeFetchRequest(TOP_CITIES_ORDERS);
    this.makeFetchRequest(TOP_STATES_ORDERS);
    this.makeFetchRequest(TOP_PRODUCTS);
    this.makeFetchRequest(GENDER_SELLER);
    this.makeFetchRequest(AGE_SELLER);
  }

  async makeFetchRequest(endpoint) {
    const url = `${ls.get('gatewayAPI')}/analytics-company-api/${endpoint}`;
    const headers = { Authorization: `Bearer ${ls.get('authTokenCompany')}` };
    try {
      const result = await axios({ method: 'get', url, headers });
      switch (endpoint) {
        case DAILY_ORDERS:
          this.setState({ dailyOrdersData: result.data });
          break;
        case MONTHLY_ORDERS:
          this.setState({ monthlyOrdersData: result.data });
          break;
        case TOP_CITIES_ORDERS:
          this.setState({ topCitiesData: result.data });
          break;
        case TOP_STATES_ORDERS:
          this.setState({ topStatesData: result.data });
          break;
        case TOP_PRODUCTS:
          this.setState({ topProductsData: result.data });
          break;
        case GENDER_SELLER: {
          const newGender = [];
          if (result.data.gender) {
            result.data.gender.forEach((g) => {
              if (g === 'M') {
                newGender.push('Masculino');
              } else if (g === 'F') {
                newGender.push('Femenino');
              } else if (g === 'N') {
                newGender.push('Ninguno');
              }
            });
            const data = {
              count: result.data.count,
              gender: newGender,
            };
            this.setState({ sellersGenderData: data });
          }
          break;
        }
        case AGE_SELLER:
          this.setState({ sellersAgeData: result.data });
          break;
        default:
          break;
      }
    } catch (e) {
      displayErrorMessage('Error recuperando estadísticas', e);
    }
  }

  componentDidMount() {
    this.fetchAllData();
  }

  render() {
    return (
      <div style={{ width: '100%', height: '100%' }}>
        <div
          className="row center"
          style={{ fontSize: '1.5em', marginTop: '1em' }}
        >
          <BarChartOutlined />
          <div>Estadísticas</div>
        </div>
        <br />
        <Select
          style={{ width: '20%' }}
          placeholder="Filtro"
          value={this.state.currentFilter}
          filterOption={false}
          onSelect={(currentFilter) => this.setState({ currentFilter })}
        >
          {this.state.filters.map((f) => (
            <Option key={f.name}>{f.name}</Option>
          ))}
        </Select>
        <br />
        {this.state.currentFilter === 'Ordenes' ? (
          <div>
            <OrdersCharts
              dailyOrdersData={this.state.dailyOrdersData}
              monthlyOrdersData={this.state.monthlyOrdersData}
              topCitiesData={this.state.topCitiesData}
              topStatesData={this.state.topStatesData}
            />
          </div>
        ) : (
          <span></span>
        )}
        {this.state.currentFilter === 'Productos' ? (
          <div>
            <ProductsCharts topProductsData={this.state.topProductsData} />
          </div>
        ) : (
          <span></span>
        )}
        {this.state.currentFilter === 'Vendedores' ? (
          <div>
            <SellersCharts
              sellersAgeData={this.state.sellersAgeData}
              sellersGenderData={this.state.sellersGenderData}
            />
          </div>
        ) : (
          <span></span>
        )}
      </div>
    );
  }
}

export default AnalyticsCharts;
