import React from 'react';
import PropTypes from 'prop-types';
import {
  Upload,
  Button,
  Modal,
  Input,
  DatePicker,
  message,
  Checkbox,
  notification,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import CategoriesTable from '../Catalog/Categories/CategoriesTable';
import displayErrorMessage from '../Errors/DisplayErrorMessage';
const ls = require('local-storage');
const axios = require('axios');

const { RangePicker } = DatePicker;

class CreateCatalog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      isPublic: false,
      ordersAllowed: false,
      createCatalogLoading: false,
      categories: [],
      categoriesSelected: {},
      loadingCategories: false,
    };
    this.handleOk = this.handleOk.bind(this);
    this.check = this.check.bind(this);
  }

  showModal = () => {
    this.setState({ visible: true });
  };

  async handleOk() {
    const { categoriesSelected, imageUrl, name, linkURL } = this.state;
    const { ordersAllowed, isPublic, dates } = this.state;
    const hasAtLeastOneCategory = Object.keys(categoriesSelected).length > 0;
    if (imageUrl && name && linkURL && dates && hasAtLeastOneCategory) {
      this.setState({ createCatalogLoading: true });
      dates[0].set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      dates[1].set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      const catalog = {
        image: imageUrl,
        name,
        since: dates[0].toISOString(),
        to: dates[1].toISOString(),
        public: isPublic,
        ordersAllowed,
        linkURL,
        categoriesIds: Object.keys(this.state.categoriesSelected),
      };
      const url = `${ls.get('gatewayAPI')}/company-service-api/catalog/add`;
      const headers = { Authorization: `Bearer ${ls.get('authTokenCompany')}` };
      try {
        await axios({ method: 'post', url, data: catalog, headers });
        notification.success({
          message: `Catalogo creado`,
          duration: 10,
          description: `Catálogo creado correctamente.`,
        });
        this.props.fetchCatalogs();
      } catch (e) {
        displayErrorMessage('Error creando este catalogo', e);
      }
      this.setState({ createCatalogLoading: false, visible: false });
    } else {
      message.error('Ingresa todos los campos!');
    }
  }

  handleCancel = () => {
    this.setState({ visible: false });
  };

  getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('La imagen debe ser .JPG o .PNG');
    }
    const isLt2M = file.size / 1024 / 1024 < 0.5;
    if (!isLt2M) {
      message.error('La imagen debe ser menor a 500KB!');
    }
    return isJpgOrPng && isLt2M;
  }

  handleChange = (info) => {
    this.getBase64(info.file.originFileObj, (imageUrl) =>
      this.setState({ imageUrl }),
    );
  };

  // Categories

  async getCategories() {
    this.setState({ loadingCategories: true });
    let url = `${ls.get('gatewayAPI')}/company-service-api/category`;
    url += `?categoryLevel=1`;
    const headers = { Authorization: `Bearer ${ls.get('authTokenCompany')}` };
    try {
      const result = await axios({ method: 'get', url, headers });
      this.setState({ categories: result.data });
    } catch (e) {
      displayErrorMessage('Error obteniendo las categorias.', e);
    }
    this.setState({ loadingCategories: false });
  }

  check(category) {
    this.setState((prevState) => {
      const { categoriesSelected } = prevState;
      if (categoriesSelected[category.id]) {
        delete categoriesSelected[category.id];
      } else {
        categoriesSelected[category.id] = true;
      }
      return { categoriesSelected };
    });
  }

  componentDidMount() {
    this.getCategories();
  }

  render() {
    return (
      <div>
        <Button type="primary" onClick={this.showModal}>
          Crea un nuevo catálogo
        </Button>
        <Modal
          title="Ingresa los datos de tu nuevo catálogo"
          visible={this.state.visible}
          onCancel={this.handleCancel}
          footer={[
            <Button key="cancel" onClick={this.handleCancel}>
              Cancelar
            </Button>,
            <Button
              key="create"
              type="primary"
              onClick={this.handleOk}
              loading={this.state.createCatalogLoading}
            >
              Crear catálogo
            </Button>,
          ]}
        >
          Nombre:
          <Input onChange={(e) => this.setState({ name: e.target.value })} />
          <br />
          <br />
          Ipaper URL:
          <Input onChange={(e) => this.setState({ linkURL: e.target.value })} />
          <br />
          <br />
          <CategoriesTable
            categories={this.state.categories}
            categoriesSelected={this.state.categoriesSelected}
            loadingCategories={this.state.loadingCategories}
            check={this.check}
            action="add"
          />
          <br />
          Fecha Inicio y fecha fin:
          <br />
          <RangePicker onChange={(e) => this.setState({ dates: e })} />
          <br />
          <br />
          Imagen de portada:
          <br />
          <Upload
            listType="picture-card"
            showUploadList={false}
            beforeUpload={this.beforeUpload}
            onChange={this.handleChange}
          >
            {this.state.imageUrl ? (
              <img
                src={this.state.imageUrl}
                alt="avatar"
                style={{ width: '100%', height: '100%' }}
              />
            ) : (
              <div>
                <PlusOutlined />
                <div className="ant-upload-text">Subir</div>
              </div>
            )}
          </Upload>
          <br />
          <br />
          Mostrar a las vendedoras (Es público?):
          <br />
          <Checkbox
            onChange={(e) => this.setState({ isPublic: e.target.checked })}
          />
          <br />
          <br />
          Permite órdenes?:
          <br />
          <Checkbox
            onChange={(e) => this.setState({ ordersAllowed: e.target.checked })}
          />
        </Modal>
      </div>
    );
  }
}

CreateCatalog.propTypes = {
  fetchCatalogs: PropTypes.func,
};

export default CreateCatalog;
