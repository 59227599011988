import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { toCurrency } from '../Utils/formatters';
import { PACKING_COMPLETED } from '../Utils/constants';

class Packing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      productsPerPacking: [],
      boxesPerPacking: [],
      productsColumns: [],
      boxesColumns: [],
    };
    this.setColumns = this.setColumns.bind(this);
  }

  setColumns() {
    const productsColumns = [
      {
        title: 'Producto',
        dataIndex: '',
        key: 'product',
        render: (p) => {
          const info = this.props.informationHash[p.productConfigurationId];
          return (
            <div>
              {info?.productName ||
                `Su usuario no cuenta con los permisos para ver la información de este producto. (productConfigurationId: ${p.productConfigurationId})`}
            </div>
          );
        },
      },
      {
        title: 'Ref. General',
        dataIndex: '',
        key: 'generalRef',
        render: (p) => {
          const info = this.props.informationHash[p.productConfigurationId];
          return <div>{info?.generalReference || ''}</div>;
        },
      },
      {
        title: 'Ref. Única',
        dataIndex: '',
        key: 'uniqueRef',
        render: (p) => {
          const info = this.props.informationHash[p.productConfigurationId];
          return <div>{info?.uniqueReference || ''}</div>;
        },
      },
      {
        title: 'Catálogo',
        dataIndex: '',
        key: 'catalog',
        render: (p) => {
          const info = this.props.informationHash[p.productConfigurationId];
          return <div>{info?.catalogName || ''}</div>;
        },
      },
      {
        title: 'Configuración',
        dataIndex: '',
        key: 'configuration',
        render: (p) => {
          const info = this.props.informationHash[p.productConfigurationId];
          return <div>{info?.config || ''}</div>;
        },
      },
      { title: 'Cantidad', dataIndex: 'quantity', key: 'quantity' },
      {
        title: 'Precio Base',
        dataIndex: '',
        key: 'basePrice',
        render: (p) => {
          const info = this.props.informationHash[p.productConfigurationId];
          return <div>{info ? toCurrency(info.basePrice) : ''}</div>;
        },
      },
      {
        title: 'Precio Seller',
        dataIndex: '',
        key: 'sellerPrice',
        render: (p) => {
          const info = this.props.informationHash[p.productConfigurationId];
          return <div>{info ? toCurrency(info.sellerPrice) : ''}</div>;
        },
      },
      {
        title: 'Precio Base Total',
        dataIndex: '',
        key: 'totalPrice',
        render: (p) => {
          const info = this.props.informationHash[p.productConfigurationId];
          return (
            <div>{info ? toCurrency(info.basePrice * p.quantity) : ''}</div>
          );
        },
      },
    ];

    const boxesColumns = [
      {
        title: 'Nombre',
        dataIndex: '',
        key: 'name',
        render: (b) => <div>{b.box ? b.box.name : ''}</div>,
      },
      {
        title: 'Largo (cm)',
        dataIndex: '',
        key: 'length',
        render: (b) => <div>{b.box ? b.box.length : ''}</div>,
      },
      {
        title: 'Ancho (cm)',
        dataIndex: '',
        key: 'width',
        render: (b) => <div>{b.box ? b.box.width : ''}</div>,
      },
      {
        title: 'Alto (cm)',
        dataIndex: '',
        key: 'height',
        render: (b) => <div>{b.box ? b.box.height : ''}</div>,
      },
      {
        title: 'Peso (kg)',
        dataIndex: '',
        key: 'weight',
        render: (b) => <div>{b.box ? b.box.weight : ''}</div>,
      },
      { title: 'Cantidad', dataIndex: 'quantity', key: 'quantity' },
    ];
    this.setState({ productsColumns, boxesColumns });
  }

  componentDidMount() {
    this.setState({
      productsPerPacking: this.props.packing.productsPerPacking,
      boxesPerPacking: this.props.packing.boxesPerPacking,
    });
    this.setColumns();
  }

  render() {
    const { productsPerPacking, boxesPerPacking } = this.state;
    const { loading, currentStatus } = this.props;
    return (
      <div>
        <br />
        <br />
        <h3>Productos del paquete:</h3>
        <Table
          loading={loading}
          rowKey={(p) => p.id}
          columns={this.state.productsColumns}
          dataSource={productsPerPacking}
        />
        {currentStatus === PACKING_COMPLETED ? (
          <div>
            <br />
            <h3>Cajas del paquete:</h3>
            <Table
              loading={loading}
              rowKey={(b) => (b.box ? b.box.id : b)}
              columns={this.state.boxesColumns}
              dataSource={boxesPerPacking}
            />
          </div>
        ) : (
          <div />
        )}

        <br />
        <br />
      </div>
    );
  }
}

Packing.propTypes = {
  packing: PropTypes.object,
  informationHash: PropTypes.object,
  currentStatus: PropTypes.string,
  loading: PropTypes.bool,
};

export default Packing;
