import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'antd';
import ImagesCarousel from '../../components/ImagesCarousel';
import LoadingCard from '../../components/LoadingCard';
import OverlayLoader from '../../components/OverlayLoader';
import {
  addProductImage,
  deleteImage,
  updateImage,
  updateProduct,
  updateProductStatus,
} from './actions';
import ModifyProductCompanyCategories from './ModifyCompanyCategories';

let idOfImagePending = 0;

function ProductSection({ product, setProduct, fetchData }) {
  const [loading, setLoading] = useState(false);

  const handleImageDelete = (imageId) => {
    if (typeof imageId === 'number') {
      // caso eliminar una foto que no esta en la BD aun
      const newImages = product.productMedia.filter(
        (image) => image.id !== imageId,
      );
      setProduct({ ...product, productMedia: newImages });
    } else {
      // caso eliminar una foto que esta en la BD
      const newImages = product.productMedia.map((image) => {
        if (image.id === imageId) {
          const newImage = { ...image, status: 'pendingToRemove' };
          return newImage;
        }
        return image;
      });
      setProduct({ ...product, productMedia: newImages });
    }
  };

  const handleImageAdd = (newImage) => {
    const data = {
      id: (idOfImagePending += 1),
      status: 'pendingToPost',
      mediaURL: URL.createObjectURL(newImage),
      reference: product.reference,
      isCoverImage: false,
      file: newImage,
    };
    const newImages = product.productMedia.concat(data);
    setProduct({ ...product, productMedia: newImages });
  };

  const handleChangeOfCoverImage = (value, index) => {
    const newImages = [...product.productMedia];
    let currentImageCoverage = -1;
    if (newImages[index].isCoverImage) {
      currentImageCoverage = index;
    } else {
      currentImageCoverage = newImages.findIndex((item) => item.isCoverImage);
    }
    if (currentImageCoverage >= 0 && currentImageCoverage !== index) {
      newImages[currentImageCoverage].isCoverImage = false;
    }
    newImages[index].isCoverImage = value;
    if (
      newImages[index].status !== 'pendingToPost' &&
      newImages[index].status !== 'pendingToDelete'
    )
      newImages[index].status = 'pendingToUpdate';
    setProduct({ ...product, productMedia: newImages });
  };

  const handleProductChange = (e) => {
    let { value } = e.target;
    if (e.target.name === 'discountPercentage') {
      value = parseInt(e.target.value, 10);
    }
    setProduct({ ...product, [e.target.name]: value });
  };

  const updateImages = async (arrayOfImages) => {
    const calls = [];
    for (let i = 0; i < arrayOfImages.length; i += 1) {
      if (arrayOfImages[i].status === 'pendingToPost')
        calls.push(addProductImage(product, arrayOfImages[i]));
      else if (arrayOfImages[i].status === 'pendingToRemove')
        calls.push(deleteImage(product.id, arrayOfImages[i].id));
      else if (arrayOfImages[i].status === 'pendingToUpdate') {
        calls.push(updateImage(product.id, arrayOfImages[i]));
      }
    }
    await Promise.all(calls);
  };

  const handleUpdateProduct = async () => {
    setLoading(true);
    await updateProduct(product);
    const imagesCall = updateImages(product.productMedia);
    await imagesCall;
    fetchData();
    setLoading(false);
  };

  if (!product.name) return <LoadingCard message="Cargando producto.." />;

  return (
    <div className="card-container">
      {loading ? <OverlayLoader /> : null}
      <div className="card fit">
        <div className="row gap no-padding">
          <div className="col gap">
            <div style={{ fontWeight: '500' }}>Estado del producto</div>
            <div className="row reverse gap no-padding">
              Habilitado
              <Switch
                checked={!product.isDisabled}
                onChange={async (checked) => {
                  setProduct((prevState) => ({
                    ...prevState,
                    isDisabled: !checked,
                  }));
                  setLoading(true);
                  await updateProductStatus(product);
                  setLoading(false);
                }}
              />
              Deshabilitado
            </div>
          </div>
          <div className="col center gap">
            <div style={{ fontWeight: '500' }}>Agrupaciones</div>
            <div style={{ width: '100%' }}>
              <ModifyProductCompanyCategories
                product={product}
                reload={fetchData}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row center no-padding">
        <div className="card fit">
          <h2
            className="row left no-padding"
            style={{ padding: '10px 0 0 20px', marginBottom: 0 }}
          >
            Informacion del Producto
          </h2>
          <div className="col gapOnSmallScreen" style={{ paddingTop: 0 }}>
            <div className="col center rowOnDesktop no-padding">
              <div className="col no-padding" style={{ marginBottom: 'auto' }}>
                <div className="row no-padding">
                  <div className="col">
                    Nombre
                    <input
                      type="text"
                      name="name"
                      value={product.name}
                      onChange={handleProductChange}
                    />
                  </div>
                  <div className="col">
                    Referencia
                    <input
                      type="text"
                      value={product.reference}
                      name="reference"
                      onChange={handleProductChange}
                    />
                  </div>
                </div>
                <div className="col">
                  Descripcion
                  <textarea
                    value={product.description}
                    onChange={handleProductChange}
                    name="description"
                    style={{ height: '9rem' }}
                  />
                </div>
              </div>
              <div className="col center gap no-padding">
                <ImagesCarousel
                  imagesArray={product.productMedia}
                  handleImageDelete={handleImageDelete}
                  handleImageAdd={handleImageAdd}
                  handleChangeOfCoverImage={handleChangeOfCoverImage}
                />
              </div>
            </div>
            <div className="row center no-padding">
              <button
                type="button"
                className="button primary"
                onClick={handleUpdateProduct}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  columnGap: '0.5em',
                  padding: '5px 30px',
                }}
              >
                <ion-icon
                  name="save-outline"
                  style={{ fontSize: '1.5em' }}
                ></ion-icon>
                Guardar Cambios
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

ProductSection.propTypes = {
  product: PropTypes.object,
  setProduct: PropTypes.func,
  fetchData: PropTypes.func,
};

export default ProductSection;
