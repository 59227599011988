const ls = require('local-storage');
const axios = require('axios');

export const fetchCompany = async () => {
  const url = `${ls.get('gatewayAPI')}/company-service-api/company`;
  const headers = { Authorization: `Bearer ${ls.get('authTokenCompany')}` };
  try {
    const result = await axios({ method: 'get', url, headers });
    const company = result.data;
    return company;
  } catch (e) {
    //
  }
  return undefined;
};
