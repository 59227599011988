import displayError from '../components/Errors/DisplayErrorMessage';

const ls = require('local-storage');
const axios = require('axios');

export async function addContentFile(
  contentId,
  relevance,
  contentFileTypeName,
  name,
  fileUrl,
  file,
) {
  let url = `${ls.get('gatewayAPI')}/company-service-api/`;
  const headers = {
    Authorization: `Bearer ${ls.get('authTokenCompany')}`,
    'Content-Type': 'multipart/form-data',
  };
  url += `company/content/${contentId}/contentFile`;
  const data = new FormData();
  data.append('relevance', relevance);
  data.append('contentFileType', contentFileTypeName);
  data.append('url', fileUrl);
  data.append('file', file);
  data.append('name', name);
  try {
    await axios({ method: 'post', url, data, headers });
    return true;
  } catch (e) {
    displayError(`Error guardando archivo de contenido: ${name}`, e);
  }
  return false;
}

export async function deleteContentFile(contentId, contentFileId, name) {
  let url = `${ls.get('gatewayAPI')}`;
  url += '/company-service-api/company/';
  url += `content/${contentId}/contentFile/${contentFileId}`;
  const headers = { Authorization: `Bearer ${ls.get('authTokenCompany')}` };
  try {
    await axios({ method: 'delete', url, headers });
    return true;
  } catch (e) {
    displayError(`Error borrando archivo de contenido: ${name}`, e);
  }
  return false;
}

export async function updateContentFile(
  contentFileId,
  relevance,
  contentFileTypeName,
  name,
  fileUrl,
  file,
  oldName,
) {
  let url = `${ls.get('gatewayAPI')}/company-service-api/`;
  const headers = {
    Authorization: `Bearer ${ls.get('authTokenCompany')}`,
    'Content-Type': 'multipart/form-data',
  };
  url += `company/content/contentFile/${contentFileId}`;
  const data = new FormData();
  if (relevance) data.append('relevance', relevance);
  if (contentFileTypeName) data.append('contentFileType', contentFileTypeName);
  if (fileUrl) data.append('url', fileUrl);
  if (file) data.append('file', file);
  if (name) data.append('name', name);
  try {
    await axios({ method: 'patch', url, data, headers });
    return true;
  } catch (e) {
    displayError(`Error actualizando archivo de contenido: ${oldName}`, e);
  }
  return false;
}
