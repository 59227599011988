import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Upload,
  message,
  Select,
  notification,
  Modal,
  Row,
  Divider,
  Col,
} from 'antd';
import { UploadOutlined, ReloadOutlined } from '@ant-design/icons';
import { XLSToJsonUpdateProducts, getHeaders } from './XMLToJSONUpdateProducts';
import displayErrorMessage from '../Errors/DisplayErrorMessage';

const { Option } = Select;

const ls = require('local-storage');
const axios = require('axios');

class UpdateProducts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      catalogs: [],
    };

    this.proccessFile = this.proccessFile.bind(this);
    this.updateProducts = this.updateProducts.bind(this);
    this.updateProductsInBatch = this.updateProductsInBatch.bind(this);
    this.reload = this.reload.bind(this);
    this.fetchCatalogs = this.fetchCatalogs.bind(this);
    this.setHeadersValues = this.setHeadersValues.bind(this);
  }

  reload() {
    this.setState({ headers: undefined, file: undefined, products: [] });
  }

  headers(fileHeaders, file) {
    this.setHeadersValues(fileHeaders);
    this.setState({ headers: fileHeaders, file });
  }

  setHeadersValues(headers) {
    if (headers && headers.includes('Referencia General') && !this.state.refG) {
      this.setState({ refG: 'Referencia General' });
    }
    if (headers && headers.includes('NOMBRE') && !this.state.name) {
      this.setState({ name: 'NOMBRE' });
    }
    if (headers && headers.includes('DESCRIPCION') && !this.state.description) {
      this.setState({ description: 'DESCRIPCION' });
    }
    if (
      headers &&
      headers.includes('AGRUPACION') &&
      !this.state.companyCategory
    ) {
      this.setState({ companyCategory: 'AGRUPACION' });
    }
    if (
      headers &&
      headers.includes('DESHABILITADO') &&
      !this.state.isDisabled
    ) {
      this.setState({ isDisabled: 'DESHABILITADO' });
    }
    if (headers && headers.includes('CATEGORIA') && !this.state.category) {
      this.setState({ category: 'CATEGORIA' });
    }
    if (
      headers &&
      headers.includes('SUB-CATEGORIA') &&
      !this.state.subCategory
    ) {
      this.setState({ subCategory: 'SUB-CATEGORIA' });
    }
    if (
      headers &&
      headers.includes('SUB-SUB-CATEGORIA') &&
      !this.state.subSubCategory
    ) {
      this.setState({ subSubCategory: 'SUB-SUB-CATEGORIA' });
    }
    if (
      headers &&
      headers.includes('PORCENTAJE-DESCUENTO') &&
      !this.state.discountPercentage
    ) {
      this.setState({ discountPercentage: 'PORCENTAJE-DESCUENTO' });
    }
  }

  proccessFile() {
    const { refG, name, description, companyCategory } = this.state;
    const { isDisabled, discountPercentage } = this.state;
    const { category, subCategory, subSubCategory } = this.state;
    const headers = [
      refG,
      name,
      description,
      companyCategory,
      isDisabled,
      category,
      subCategory,
      subSubCategory,
      discountPercentage,
    ];
    if (!refG || !name || !description || !companyCategory || !isDisabled) {
      message.error('Hay campos requeridos sin llenar.');
    } else {
      XLSToJsonUpdateProducts(this.state.file, headers).then((response) => {
        if (response.errors.length === 0) {
          notification.warn({
            message: `Productos listos`,
            duration: 10,
            description: `${response.finalProducts.length} Nuevos productos seran actualizados.`,
          });
          this.setState({ products: response.finalProducts });
        } else {
          notification.error({
            message: `Productos con errores`,
            duration: 0,
            description: response.errors.join(','),
          });
        }
      });
    }
  }

  async updateProducts() {
    this.setState({ loading: true });
    const productsByBatch = [];
    if (this.state.products.length < 1000) {
      productsByBatch[0] = this.state.products;
    } else {
      for (let i = 0; i < this.state.products.length; i += 1) {
        const product = this.state.products[i];
        const batch = i % 20;
        if (!productsByBatch[batch]) productsByBatch[batch] = [];
        productsByBatch[batch].push(product);
      }
    }
    for (let i = 0; i < productsByBatch.length; i += 1) {
      const products = productsByBatch[i];
      // eslint-disable-next-line no-await-in-loop
      await this.updateProductsInBatch(products);
    }
    this.props.reload();
    this.setState({ loading: false });
  }

  async updateProductsInBatch(products) {
    const url = `${ls.get('gatewayAPI')}/company-service-api/product`;
    const headers = { Authorization: `Bearer ${ls.get('authTokenCompany')}` };
    const data = {
      products,
      catalogId: this.state.catalogId,
    };
    try {
      await axios({ method: 'put', url, data, headers });
      notification.success({
        message: `Productos actualizados`,
        duration: 10,
        description: `${products.length} productos fueron actualizados.`,
      });
    } catch (e) {
      displayErrorMessage('Error actualizando productos', e);
    }
  }

  async fetchCatalogs() {
    const url = `${ls.get('gatewayAPI')}/company-service-api/company/catalogs`;
    const headers = { Authorization: `Bearer ${ls.get('authTokenCompany')}` };
    try {
      const result = await axios({ method: 'get', url, headers });
      const catalogsRaw = result.data === '' ? [] : result.data;
      const catalogs = [];
      for (let i = 0; i < catalogsRaw.length; i += 1) {
        if (catalogsRaw[i].isPublic) {
          catalogs.push(catalogsRaw[i]);
        }
      }
      this.setState({ catalogs });
    } catch (e) {
      displayErrorMessage('Error recuperando catálogos', e);
    }
  }

  componentDidMount() {
    this.fetchCatalogs();
  }

  render() {
    return (
      <div style={{ width: '100%' }}>
        <button
          type="button"
          className="button secondary center"
          onClick={() => this.setState({ visible: true })}
          style={{ width: '100%' }}
        >
          <ion-icon
            name="create-outline"
            style={{ width: '20px', height: '20px', marginRight: '5px' }}
          ></ion-icon>
          Editar productos
        </button>
        <Modal
          title="Editar productos con Excel"
          visible={this.state.visible}
          onOk={this.handleOk}
          width="50%"
          onCancel={() => {
            this.reload();
            this.setState({ visible: false });
          }}
          footer={[]}
        >
          <div>
            <Row gutter={24}>
              <Col span={8}>
                <Select
                  style={{ width: '100%' }}
                  onChange={(e) => this.setState({ catalogId: e })}
                  onSelect={(e) => this.setState({ catalogId: e })}
                  placeholder="Selecciona el catálogo"
                  value={this.state.catalogId}
                >
                  {this.state.catalogs.map((item) => (
                    <Option value={item.id} key={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Col>
              <Col span={8}>
                <Upload
                  accept=".xlsx"
                  showUploadList={false}
                  beforeUpload={(file) => {
                    getHeaders(file)
                      .then((res) => this.headers(res, file))
                      .catch((err) => message.error(err));
                    return false;
                  }}
                >
                  <Button>
                    <UploadOutlined /> Subir productos
                  </Button>
                </Upload>
              </Col>
              <Col span={8}>
                <div style={{ width: '100%', textAlign: 'right' }}>
                  <Button
                    style={{
                      fontSize: '1.5em',
                      color: '#646464',
                      borderColor: '#646464',
                      backgroundColor: 'white',
                      height: 'auto',
                    }}
                    type="primary"
                    onClick={() => this.reload()}
                  >
                    <ReloadOutlined />
                  </Button>
                </div>
              </Col>
            </Row>
            <br />
            {this.state.headers && (
              <>
                <Select
                  placeholder="Referencia General"
                  style={{ width: 200 }}
                  onChange={(value) => this.setState({ refG: value })}
                  value={this.state.refG}
                >
                  {this.state.headers.map((header) => (
                    <Option key={header} value={header}>
                      {header}
                    </Option>
                  ))}
                </Select>
                <Select
                  placeholder="Nombre"
                  style={{ width: 200 }}
                  onChange={(value) => this.setState({ name: value })}
                  value={this.state.name}
                >
                  {this.state.headers.map((header) => (
                    <Option key={header} value={header}>
                      {header}
                    </Option>
                  ))}
                </Select>
                <Select
                  placeholder="Descripción"
                  style={{ width: 200 }}
                  onChange={(value) => this.setState({ description: value })}
                  value={this.state.description}
                >
                  {this.state.headers.map((header) => (
                    <Option key={header} value={header}>
                      {header}
                    </Option>
                  ))}
                </Select>
                <Select
                  placeholder="Agrupación"
                  style={{ width: 200 }}
                  onChange={(value) =>
                    this.setState({ companyCategory: value })
                  }
                  value={this.state.companyCategory}
                >
                  {this.state.headers.map((header) => (
                    <Option key={header} value={header}>
                      {header}
                    </Option>
                  ))}
                </Select>
                <Select
                  placeholder="Deshabilitado"
                  style={{ width: 200 }}
                  onChange={(value) => this.setState({ isDisabled: value })}
                  value={this.state.isDisabled}
                >
                  {this.state.headers.map((header) => (
                    <Option key={header} value={header}>
                      {header}
                    </Option>
                  ))}
                </Select>
                <Select
                  placeholder="Porcentaje de descuento"
                  style={{ width: 200 }}
                  onChange={(value) =>
                    this.setState({ discountPercentage: value })
                  }
                  value={this.state.discountPercentage}
                >
                  {this.state.headers.map((header) => (
                    <Option key={header} value={header}>
                      {header}
                    </Option>
                  ))}
                </Select>
                <br />
                <Divider />
                <Select
                  placeholder="Categoría"
                  style={{ width: 200 }}
                  onChange={(value) => this.setState({ category: value })}
                  value={this.state.category}
                >
                  {this.state.headers.map((header) => (
                    <Option key={header} value={header}>
                      {header}
                    </Option>
                  ))}
                </Select>
                <Select
                  placeholder="Sub categoría"
                  style={{ width: 200 }}
                  onChange={(value) => this.setState({ subCategory: value })}
                  value={this.state.subCategory}
                >
                  {this.state.headers.map((header) => (
                    <Option key={header} value={header}>
                      {header}
                    </Option>
                  ))}
                </Select>
                <Select
                  placeholder="Sub sub categoría"
                  style={{ width: 200 }}
                  onChange={(value) => this.setState({ subSubCategory: value })}
                  value={this.state.subSubCategory}
                >
                  {this.state.headers.map((header) => (
                    <Option key={header} value={header}>
                      {header}
                    </Option>
                  ))}
                </Select>
                <br />
                <br />
                {this.state.products.length === 0 && (
                  <Button type="primary" onClick={this.proccessFile}>
                    Confirmar
                  </Button>
                )}
              </>
            )}
            <br />
            <br />
            {this.state.products.length !== 0 && (
              <Button
                type="primary"
                onClick={this.updateProducts}
                loading={this.state.loading}
              >
                {`Actualizar ${this.state.products.length} productos`}
              </Button>
            )}
            <br />
            <br />
          </div>
        </Modal>
      </div>
    );
  }
}

UpdateProducts.propTypes = {
  reload: PropTypes.func,
};

export default UpdateProducts;
