import React from 'react';
import XLSX from 'xlsx';
import PropTypes from 'prop-types';
import PrintComponents from 'react-print-components';
import { Table, Checkbox, Button, Row, Col, notification } from 'antd';
import {
  PrinterOutlined,
  CloudDownloadOutlined,
  CloseCircleOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import { toCurrency } from '../Utils/formatters';
import Packing from './Packing';
import {
  PACKING_COMPLETED,
  PACKING_DELIVERED,
  PACKING_IN_PROCESS,
  PACKING_READY_FOR_PACKING,
  PACKING_DISPATCHED,
} from '../Utils/constants';

class PackingsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.fetchPackings = this.fetchPackings.bind(this);
    this.getCols = this.getCols.bind(this);
    this.printShipmentGuide = this.printShipmentGuide.bind(this);
    this.translatePaymentType = this.translatePaymentType.bind(this);
  }

  getCols() {
    const columns = [
      {
        title: 'Fecha creación',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (text) => {
          const d = moment(new Date(text));
          d.format('YYYY/MM/DD');
          return <div>{d.format('YYYY/MM/DD')}</div>;
        },
      },
      {
        title: 'Ultimo cambio',
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        render: (text) => {
          const d = moment(new Date(text));
          d.format('YYYY/MM/DD');
          return <div>{d.format('YYYY/MM/DD')}</div>;
        },
      },
      {
        title: 'Destino',
        dataIndex: '',
        key: 'destination',
        render: (packing) => {
          const order = this.props.ordersInfoHash[packing.id];
          const city = this.props.citiesHash[order?.id];
          let response;
          if (!order) {
            response = <LoadingOutlined />;
          } else {
            response = (
              <div>
                {city?.name} - {city?.state?.name}
              </div>
            );
          }

          return response;
        },
      },
      {
        title: 'Número pedido',
        dataIndex: 'id',
        render: (id) => {
          const orderInfo = this.props.ordersInfoHash[id];
          return orderInfo ? (
            <div>{`${orderInfo?.suborders[0].companyOrderNumber}`}</div>
          ) : (
            <div>-</div>
          );
        },
        key: 'status',
      },
      {
        title: 'Vendedora',
        dataIndex: 'id',
        key: 'seller',
        render: (id) => {
          const orderInfo = this.props.ordersInfoHash[id];
          return orderInfo ? (
            <div>{`${orderInfo?.billingFirstName} ${orderInfo?.billingLastName}`}</div>
          ) : (
            <div>-</div>
          );
        },
      },
      {
        title: 'Cliente',
        dataIndex: 'id',
        key: 'client',
        render: (id) => {
          const orderInfo = this.props.ordersInfoHash[id];
          return orderInfo ? (
            <div>{`${orderInfo?.shippingFirstName} ${orderInfo?.shippingLastName}`}</div>
          ) : (
            <div>-</div>
          );
        },
      },
      {
        title: 'Teléfono Vendedora',
        dataIndex: 'id',
        key: 'sellerPhone',
        render: (id) => {
          const orderInfo = this.props.ordersInfoHash[id];
          return orderInfo ? (
            <div>{`${orderInfo.billingPhoneCode} ${orderInfo.billingPhone}`}</div>
          ) : (
            <div>-</div>
          );
        },
      },
      {
        title: 'Tipo de pago',
        dataIndex: 'id',
        key: 'payment',
        render: (id) => {
          const orderInfo = this.props.ordersInfoHash[id];
          return orderInfo ? (
            <div>{this.translatePaymentType(orderInfo.paymentCarrier)}</div>
          ) : (
            <div>-</div>
          );
        },
      },
      {
        title: 'Descargar Excel',
        dataIndex: '',
        render: (packing) => {
          const orderInfo = this.props.ordersInfoHash[packing.id];
          const information = this.props.allPackingInfoHash[packing.id];

          if (!information || !orderInfo) {
            return (
              <div>
                <Button type="text" disabled>
                  <CloseCircleOutlined />
                </Button>
              </div>
            );
          }
          return (
            <Button
              disabled={this.props.loading}
              type="text"
              shape="round"
              onClick={() => this.saveToExcel(packing)}
            >
              <CloudDownloadOutlined />
            </Button>
          );
        },
        key: 'status',
      },
      {
        title: 'Imprimir paquete',
        dataIndex: '',
        key: 'printing',
        render: (packing) => {
          const orderInfo = this.props.ordersInfoHash[packing.id];
          const information = this.props.allPackingInfoHash[packing.id];
          const shipmentInfo = this.props.shipmentsHash[packing.id];

          if (!information || !orderInfo) {
            return (
              <div>
                <Button type="text" disabled>
                  <CloseCircleOutlined />
                </Button>
              </div>
            );
          }

          let totalItems = 0;
          let totalBase = 0;
          let totalSeller = 0;
          for (let i = 0; i < packing.productsPerPacking.length; i += 1) {
            const product = packing.productsPerPacking[i];

            totalItems += product.quantity;
            totalBase +=
              information[product.productConfigurationId]?.basePrice *
              product.quantity;
            totalSeller +=
              information[product.productConfigurationId]?.sellerPrice *
              product.quantity;
          }
          return (
            <PrintComponents
              trigger={
                <Button type="text" shape="round" loading={this.props.loading}>
                  <PrinterOutlined />
                </Button>
              }
            >
              <br />
              <h2 style={{ fontSize: '15px' }}>Información general</h2>
              <Row
                gutter={24}
                style={{ borderBottom: 'solid 1px', fontSize: '10px' }}
              >
                <Col span={4}>Fecha Creación</Col>
                <Col span={3}># Pedido</Col>
                <Col span={6}>Vendedora</Col>
                <Col span={6}>Cliente</Col>
                <Col span={5}>Teléfono</Col>
              </Row>
              <Row gutter={24} style={{ fontSize: '10px' }}>
                <Col span={4}>
                  {moment(new Date(packing.createdAt)).format('YYYY/MM/DD')}
                </Col>
                <Col span={3}>{orderInfo?.suborders[0].companyOrderNumber}</Col>
                <Col span={6}>
                  {`${orderInfo?.billingFirstName} ${orderInfo?.billingLastName}`}
                </Col>
                <Col span={6}>
                  {`${orderInfo?.shippingFirstName} ${orderInfo?.shippingLastName}`}
                </Col>
                <Col
                  span={5}
                >{`${orderInfo?.billingPhoneCode} ${orderInfo?.billingPhone}`}</Col>
              </Row>
              <br />
              <h2 style={{ fontSize: '15px' }}>Productos del paquete</h2>
              <Row
                gutter={24}
                style={{ borderBottom: 'solid 1px', fontSize: '10px' }}
              >
                <Col span={4}>Nombre</Col>
                <Col span={3}>Ref</Col>
                <Col span={3}>Ref Única</Col>
                <Col span={3}>Precio Base</Col>
                <Col span={3}>Precio Seller</Col>
                <Col span={1}>#</Col>
                <Col span={7}>Elección</Col>
              </Row>
              {packing.productsPerPacking.map((product) => (
                <Row key={product.id} gutter={24} style={{ fontSize: '10px' }}>
                  <Col span={4}>
                    {information[product.productConfigurationId]?.productName}
                  </Col>
                  <Col span={3}>
                    {
                      information[product.productConfigurationId]
                        ?.generalReference
                    }
                  </Col>
                  <Col span={3}>
                    {
                      information[product.productConfigurationId]
                        ?.uniqueReference
                    }
                  </Col>
                  <Col span={3}>
                    {toCurrency(
                      information[product.productConfigurationId]?.basePrice,
                    )}
                  </Col>
                  <Col span={3}>
                    {toCurrency(
                      information[product.productConfigurationId]?.sellerPrice,
                    )}
                  </Col>
                  <Col span={1}>{product.quantity}</Col>
                  <Col span={7}>
                    {information[product.productConfigurationId]?.config}
                  </Col>
                </Row>
              ))}
              <br />
              <div>
                <div style={{ fontSize: '10px' }}>
                  Cantidad productos: {totalItems}
                </div>
                <div style={{ fontSize: '10px' }}>
                  Total Base: {`${totalBase} $`}
                </div>
                <div style={{ fontSize: '10px' }}>
                  Total Seller: {`${totalSeller} $`}
                </div>
              </div>
              <br />
              {(this.props.currentStatus === PACKING_COMPLETED ||
                this.props.currentStatus === PACKING_DELIVERED ||
                this.props.currentStatus === PACKING_DISPATCHED) && (
                <div>
                  <h3 style={{ fontSize: '15px' }}>Envío</h3>
                  <div style={{ fontSize: '10px' }}>
                    Transportadora:{' '}
                    {shipmentInfo ? shipmentInfo.shippingCarrier.name : ''}
                  </div>
                  <div style={{ fontSize: '10px' }}>
                    Número de guía:{' '}
                    {shipmentInfo ? shipmentInfo.trackingNumber : ''}
                  </div>
                </div>
              )}
            </PrintComponents>
          );
        },
      },
    ];
    if (
      this.props.currentStatus === PACKING_READY_FOR_PACKING ||
      this.props.currentStatus === PACKING_IN_PROCESS
    ) {
      const changeStateColumn = {
        title: 'Cambiar de estado',
        dataIndex: '',
        render: (packing) => (
          <Checkbox
            loading={this.props.loading}
            onChange={() =>
              this.props.changePackingStatus(
                this.props.currentStatus === PACKING_READY_FOR_PACKING
                  ? PACKING_IN_PROCESS
                  : PACKING_COMPLETED,
                packing,
              )
            }
          >
            {this.props.currentStatus === PACKING_READY_FOR_PACKING
              ? 'Empezar a procesar'
              : 'Finalizar empacado'}
          </Checkbox>
        ),
        key: 'status',
      };
      columns.push(changeStateColumn);
    } else if (
      this.props.currentStatus === PACKING_COMPLETED ||
      this.props.currentStatus === PACKING_DELIVERED ||
      this.props.currentStatus === PACKING_DISPATCHED
    ) {
      const printColumn = {
        title: 'Imprimir guía',
        dataIndex: '',
        render: (packing) => {
          const shipment = this.props.shipmentsHash[packing.id];
          if (!shipment?.trackingPdf) {
            return (
              <div style={{ width: '100%', textAlign: 'center' }}>En proceso</div>
            );
          }
          return (
            <Button
              disabled={this.props.loading}
              type="text"
              shape="round"
              onClick={() => this.printShipmentGuide(shipment)}
            >
              <PrinterOutlined />
            </Button>
          );
        },
        key: 'status',
      };
      columns.push(printColumn);
    }
    columns.push({ title: 'Id', dataIndex: 'id', key: 'id' });
    return columns;
  }

  fetchPackings(pagination) {
    this.props.fetchPackings(pagination);
  }

  printShipmentGuide(shipment) {
    if (shipment && shipment.trackingPdf) {
      window.open(shipment.trackingPdf);
    } else {
      notification.warn({
        message: `Guía en proceso`,
        duration: 5,
        description: 'La guía está en proceso de generación.',
      });
    }
  }

  async saveToExcel(packing) {
    const name = `paquete ${packing.id}.xlsx`;
    const firstSheet = [
      'Fecha Creación',
      'Ultimo cambio',
      'Vendedora',
      'Teléfono',
      'Tipo de pago',
    ];
    if (this.props.currentStatus === PACKING_COMPLETED) {
      firstSheet.push('Transportadora');
      firstSheet.push('Número de guía');
    }
    const secondSheet = [
      'Ref. General',
      'Ref. Unica',
      'Catálogo',
      'Precio Base',
      'Precio Seller',
      'Cantidad',
      'Total precio Base',
      'Total precio Seller',
    ];
    const thirdSheet = [
      'Nombre',
      'Largo (cm)',
      'Ancho (cm)',
      'Alto (cm)',
      'Peso (kg)',
      'Cantidad',
    ];
    const dataFirstSheet = [];
    dataFirstSheet.push(firstSheet);
    const dataSecondSheet = [];
    dataSecondSheet.push(secondSheet);
    const dataThirdSheet = [];
    dataThirdSheet.push(thirdSheet);

    const orderInfo = this.props.ordersInfoHash[packing.id];
    const shipmentInfo = this.props.shipmentsHash[packing.id];
    const information = this.props.allPackingInfoHash[packing.id];

    const infoFirstSheet = [
      moment(new Date(packing.createdAt)).format('YYYY/MM/DD'),
      moment(new Date(packing.updatedAt)).format('YYYY/MM/DD'),
      `${orderInfo?.billingFirstName} ${orderInfo?.billingLastName}`,
      `${orderInfo?.billingPhoneCode} ${orderInfo?.billingPhone}`,
      this.translatePaymentType(orderInfo.paymentCarrier),
    ];
    if (this.props.currentStatus === PACKING_COMPLETED) {
      infoFirstSheet.push(
        shipmentInfo ? shipmentInfo.shippingCarrier.name : '',
      );
      infoFirstSheet.push(shipmentInfo ? shipmentInfo.trackingNumber : '');
    }
    dataFirstSheet.push(infoFirstSheet);

    for (let i = 0; i < packing.productsPerPacking.length; i += 1) {
      const product = packing.productsPerPacking[i];
      dataSecondSheet.push([
        information[product.productConfigurationId]?.generalReference,
        information[product.productConfigurationId]?.uniqueReference,
        information[product.productConfigurationId]?.catalogName,
        {
          v: information[product.productConfigurationId]?.basePrice,
          t: 'n',
          z: '$#,##0.00',
        },
        {
          v: information[product.productConfigurationId]?.sellerPrice,
          t: 'n',
          z: '$#,##0.00',
        },
        product.quantity,
        {
          v:
            information[product.productConfigurationId]?.basePrice *
            product.quantity,
          t: 'n',
          z: '$#,##0.00',
        },
        {
          v:
            information[product.productConfigurationId]?.sellerPrice *
            product.quantity,
          t: 'n',
          z: '$#,##0.00',
        },
      ]);
    }

    if (this.props.currentStatus === PACKING_COMPLETED) {
      for (let i = 0; i < packing.boxesPerPacking.length; i += 1) {
        const b = packing.boxesPerPacking[i];
        dataThirdSheet.push([
          b.box.name,
          b.box.length,
          b.box.width,
          b.box.height,
          b.box.weight,
          b.quantity,
        ]);
      }
    }

    const ws1cols = [
      { wch: 15 },
      { wch: 15 },
      { wch: 40 },
      { wch: 18 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
    ];

    const ws2cols = [
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 10 },
      { wch: 20 },
      { wch: 20 },
    ];

    const ws3cols = [
      { wch: 20 },
      { wch: 12 },
      { wch: 12 },
      { wch: 12 },
      { wch: 12 },
      { wch: 12 },
      { wch: 12 },
    ];

    const worksheet1 = XLSX.utils.aoa_to_sheet(dataFirstSheet);
    const worksheet2 = XLSX.utils.aoa_to_sheet(dataSecondSheet);
    const worksheet3 = XLSX.utils.aoa_to_sheet(dataThirdSheet);
    worksheet1['!cols'] = ws1cols;
    worksheet2['!cols'] = ws2cols;
    worksheet3['!cols'] = ws3cols;
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet1, 'Info general');
    XLSX.utils.book_append_sheet(workbook, worksheet2, 'Productos del paquete');
    if (this.props.currentStatus === PACKING_COMPLETED)
      XLSX.utils.book_append_sheet(workbook, worksheet3, 'Cajas del paquete');
    XLSX.writeFile(workbook, name);
  }

  translatePaymentType(paymentType) {
    if (paymentType === 'ON_DELIVERY') return 'CONTRAENTREGA';
    if (paymentType === 'LOANS') return 'PRESTAMO';
    return paymentType;
  }

  render() {
    const { packings, allPackingInfoHash, loading, currentStatus } = this.props;

    return (
      <div>
        <Table
          scroll={{ x: 'max-content' }}
          rowKey={(p) => p.id}
          dataSource={packings}
          columns={this.getCols()}
          loading={loading}
          expandedRowRender={(p) => (
            <Packing
              packing={p}
              informationHash={allPackingInfoHash[p.id] || {}}
              loading={loading}
              currentStatus={currentStatus}
            />
          )}
          onChange={this.fetchPackings}
          pagination={this.props.pagination}
        />
      </div>
    );
  }
}

PackingsTable.propTypes = {
  packings: PropTypes.array,
  fetchPackings: PropTypes.func,
  pagination: PropTypes.object,
  loading: PropTypes.bool,
  allPackingInfoHash: PropTypes.object,
  shipmentsHash: PropTypes.object,
  ordersInfoHash: PropTypes.object,
  citiesHash: PropTypes.object,
  currentStatus: PropTypes.string,
  changePackingStatus: PropTypes.func,
};

export default PackingsTable;
