import XLSX from 'xlsx';

function getRow(data, headers, numRow, errors) {
  let reference = data[headers[0]];
  let basePrice = data[headers[1]];
  let retailPrice = data[headers[2]];

  if (!basePrice && !retailPrice) {
    errors.push(`Error in line: ${numRow + 2}. No prices specified.`);
  }

  reference = reference ? reference.toString().trim() : null;
  if (!reference || reference === '') {
    errors.push(`Error in line: ${numRow + 2}. No reference.`);
  }

  basePrice = basePrice ? parseFloat(basePrice.toString().trim()) : null;
  retailPrice = retailPrice ? parseFloat(retailPrice.toString().trim()) : null;

  if (Number.isNaN(basePrice) || basePrice < 0) {
    errors.push(
      `Error in line: ${
        numRow + 2
      }. Base price must be positive number. Current value: ${basePrice}`,
    );
    basePrice = null;
  }
  if (Number.isNaN(retailPrice) || retailPrice < 0) {
    errors.push(
      `Error in line: ${
        numRow + 2
      }. Retail price must be positive number. Current value: ${retailPrice}`,
    );
    retailPrice = null;
  }

  return { reference, basePrice, retailPrice };
}

function formatProductConfigurations(productConfigurations, headers) {
  const finalProductConfigurations = [];
  const errors = [];
  for (let k = 0; k < productConfigurations.length; k += 1) {
    const { reference, basePrice, retailPrice } = getRow(
      productConfigurations[k],
      headers,
      k,
      errors,
    );
    finalProductConfigurations.push({
      reference,
      basePrice,
      retailPrice,
    });
  }
  return { finalProductConfigurations, errors };
}

export const XLSToJsonUpdateProductConfigurationsPrices = (file, headers) =>
  new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);
    // eslint-disable-next-line func-names
    fileReader.onload = function (e) {
      const workbook = XLSX.read(btoa(fixdata(e.target.result)), {
        type: 'base64',
      });
      const workbookJson = toJson(workbook);
      const productConfigurations = workbookJson[Object.keys(workbookJson)[0]];

      if (!productConfigurations) {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject({
          type: 'danger',
          msg: 'Este documento no funciona.',
        });
        return;
      }
      productConfigurations.slice(1);
      const {
        finalProductConfigurations,
        errors,
      } = formatProductConfigurations(productConfigurations, headers);
      resolve({ finalProductConfigurations, errors });
    };
  });

export const getHeaders = (file) =>
  new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);
    // eslint-disable-next-line func-names
    fileReader.onload = function (e) {
      const workbook = XLSX.read(btoa(fixdata(e.target.result)), {
        type: 'base64',
      });

      if (!workbook) {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject({
          type: 'danger',
          msg: 'Este documento no funciona.',
        });
        return;
      }

      // range.s is the upper-left corner
      // range.s.r is the first row
      // range.s.c is the first column
      // range.e is the lower right corner
      // range.e.c is the last column

      const ws = workbook.Sheets[workbook.SheetNames[0]];
      const headers = [];
      const range = XLSX.utils.decode_range(ws['!ref']);
      let C;
      const R = range.s.r;
      for (C = range.s.c; C <= range.e.c; C += 1) {
        const cell = ws[XLSX.utils.encode_cell({ c: C, r: R })];

        if (cell && cell.t) headers.push(XLSX.utils.format_cell(cell));
      }

      resolve(headers);
    };
  });

function fixdata(data) {
  let o = '';
  let l = 0;
  const w = 10240;
  // eslint-disable-next-line no-plusplus
  for (; l < data.byteLength / w; ++l)
    o += String.fromCharCode.apply(
      null,
      new Uint8Array(data.slice(l * w, l * w + w)),
    );
  o += String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w)));
  return o;
}

function toJson(workbook) {
  const result = {};
  // eslint-disable-next-line func-names
  workbook.SheetNames.forEach(function (sheetName) {
    const roa = XLSX.utils.sheet_to_row_object_array(
      workbook.Sheets[sheetName],
    );
    if (roa.length > 0) {
      result[sheetName] = roa;
    }
  });
  return result;
}
