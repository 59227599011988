/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  ArrowsAltOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import confirm from 'antd/lib/modal/confirm';
import { Checkbox, Modal, Upload } from 'antd';
import { beforeUpload } from '../views/Product/actions';

function ImagesCarousel({
  imagesArray,
  handleImageDelete,
  handleImageAdd,
  handleChangeOfCoverImage,
}) {
  const [active, setActive] = useState(0);
  const [previewImage, setPreviewImage] = useState(null);

  function showConfirm() {
    confirm({
      title: '¿Estás seguro que deseas eliminar esta imagen?',
      icon: <ExclamationCircleOutlined />,
      onOk() {
        handleImageDelete(imagesArray[active].id);
        setActive(0);
      },
    });
  }

  return (
    <div className="col center gap">
      {imagesArray.map(
        (image, index) =>
          index === active && (
            <div
              className="col no-padding"
              key={image.id}
              style={{
                position: 'relative',
                backgroundColor: 'white',
                borderRadius: '12px',
              }}
            >
              <div
                className="row reverse gap"
                style={{
                  paddingTop: 0,
                  position: 'absolute',
                  top: '10px',
                  right: '10px',
                }}
              >
                <button
                  type="button"
                  className="button secondary"
                  style={{ height: '2rem' }}
                  onClick={() => showConfirm()}
                >
                  <DeleteOutlined />
                </button>
                <button
                  type="button"
                  className="button secondary"
                  style={{ height: '2rem' }}
                  onClick={() => setPreviewImage(image.mediaURL)}
                >
                  <ArrowsAltOutlined />
                </button>
              </div>
              <div style={stylesOfIsCoverImageInput}>
                Imagen de portada:
                <Checkbox
                  checked={image.isCoverImage}
                  onChange={(e) => {
                    handleChangeOfCoverImage(e.target.checked, index);
                  }}
                />
              </div>
              <div className="row center no-padding">
                <img
                  src={image.mediaURL}
                  alt="imgProduct"
                  style={{
                    objectFit: 'contain',
                    height: '180px',
                    width: '360px',
                  }}
                />
              </div>
            </div>
          ),
      )}
      <div
        style={{
          maxWidth: '360px',
          display: 'flex',
          columnGap: '1em',
          padding: '0 1em 1em 1em',
          overflowX: `${imagesArray.length > 3 ? 'scroll' : 'none'}`,
        }}
      >
        {imagesArray.map(
          (image, index) =>
            image.status !== 'pendingToRemove' && (
              <button
                key={image.id}
                className="button"
                type="button"
                onClick={() => setActive(index)}
                style={{
                  border: `${active === index ? '2px solid lightblue' : ''}`,
                  borderRadius: '5px',
                  height: '4rem',
                }}
              >
                <img
                  src={image.mediaURL}
                  alt="imgSmallProd"
                  style={{ height: '3rem', objectFit: 'contain', width: '4em' }}
                />
              </button>
            ),
        )}

        <Upload
          beforeUpload={(file) => {
            const isSupported = beforeUpload(file);
            if (isSupported) handleImageAdd(file);
          }}
          showUploadList={false}
        >
          <button
            type="button"
            className="button secondary"
            style={{
              borderRadius: '5px',
              height: '4rem',
              width: '5em',
            }}
          >
            <ion-icon name="add-outline" size="large"></ion-icon>
          </button>
        </Upload>
      </div>
      <Modal
        title={`Imagen ${active + 1} de ${imagesArray.length}`}
        visible={previewImage}
        onCancel={() => setPreviewImage(null)}
        footer={[]}
        className="col center"
      >
        <img src={previewImage} alt="img" style={{ maxHeight: '69vh' }} />
      </Modal>
    </div>
  );
}

const stylesOfIsCoverImageInput = {
  position: 'absolute',
  right: '5px',
  bottom: '5px',
  display: 'flex',
  alignItems: 'center',
  borderRadius: '1em',
  padding: '5px 5px',
  columnGap: '10px',
  backgroundColor: 'whitesmoke',
};

ImagesCarousel.propTypes = {
  imagesArray: PropTypes.array,
  handleImageDelete: PropTypes.func,
  handleImageAdd: PropTypes.func,
  handleChangeOfCoverImage: PropTypes.func,
};

export default ImagesCarousel;
