import React from 'react';
import PropTypes from 'prop-types';
import UpdateCatalog from './UpdateCatalog';
import UploadProducts from './UploadProducts';
import displayErrorMessage from '../Errors/DisplayErrorMessage';
import { fetchCatalogs } from '../../gateway/CatalogServices';

class Catalog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    if (this.props.location.desiredCatalog) {
      this.setState({ catalog: this.props.location.desiredCatalog });
    } else {
      try {
        const id = window.location.pathname.split('catalogos/')[1];
        const catalogs = await fetchCatalogs();
        const catalog = catalogs.filter((cCatalog) => cCatalog.id === id);
        if (catalog.length === 1) this.setState({ catalog: catalog[0] });
      } catch (e) {
        displayErrorMessage('Error obteniendo los catálogos', e);
      }
    }
  }

  render() {
    const { catalog } = this.state;
    if (!catalog) return <div />;

    return (
      <div>
        <img style={{ width: '12em' }} alt="img" src={catalog.imageURL} />
        <br />
        <br />
        <UpdateCatalog catalog={catalog} />
        <br />
        <UploadProducts catalogId={catalog.id} />
        <br />
      </div>
    );
  }
}

Catalog.propTypes = {
  location: PropTypes.any,
};

export default Catalog;
