import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  updateSession: false,
  errorStatus: '',
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    updateAuth: (state, action) => {
      state.updateSession = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateAuth } = authSlice.actions;

export default authSlice.reducer;
