import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
  Row,
  Col,
  Input,
  InputNumber,
  notification,
  Divider,
  Select,
  Tooltip,
} from 'antd';
import { VariantPropertyInput } from './VariantPropertyInput';
import { DeleteOutlined } from '@ant-design/icons';
import displayErrorMessage from '../Errors/DisplayErrorMessage';

const { Option } = Select;
const { TextArea } = Input;

const ls = require('local-storage');
const axios = require('axios');

class AddProduct extends React.Component {
  /*Para el plan chile se deberia especificar dinamicamente el porcentage del iva*/
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      taxClassificationName: 'TAXABLE',
      newCurrencyCodeName: 'COP',
      properties: [],
      variants: [],
      catalogs: [],
    };
    this.fetchCatalogs = this.fetchCatalogs.bind(this);
    this.createProduct = this.createProduct.bind(this);
    this.addProductConfiguration = this.addProductConfiguration.bind(this);
    this.addVariant = this.addVariant.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.editVariantProperty = this.editVariantProperty.bind(this);
    this.deleteVariantProperty = this.deleteVariantProperty.bind(this);
    this.deleteVariant = this.deleteVariant.bind(this);
  }

  async fetchCatalogs() {
    const url = `${ls.get('gatewayAPI')}/company-service-api/company/catalogs`;
    const headers = { Authorization: `Bearer ${ls.get('authTokenCompany')}` };
    try {
      const result = await axios({ method: 'get', url, headers });
      const catalogsRaw = result.data === '' ? [] : result.data;
      const catalogs = [];
      for (let i = 0; i < catalogsRaw.length; i += 1) {
        if (catalogsRaw[i].allowOrders) {
          catalogs.push(catalogsRaw[i]);
        }
      }
      this.setState({ catalogs });
    } catch (e) {
      displayErrorMessage('Error recuperando catálogos', e);
    }
  }

  async createProduct() {
    const data = [
      {
        name: this.state.name,
        reference: this.state.reference,
        description: this.state.description,
        variants: this.state.variants,
      },
    ];
    let url = `${ls.get('gatewayAPI')}/company-service-api`;
    url += `/catalog/${this.state.catalogId}/addProducts`;
    const headers = { Authorization: `Bearer ${ls.get('authTokenCompany')}` };
    try {
      const result = await axios({ method: 'post', url, headers, data });
      const { referencesThatCouldNotBeCreated } = result.data;

      if (referencesThatCouldNotBeCreated.length > 0) {
        notification.error({
          message: `No se añadió producto`,
          duration: 10,
          description: `No se pudo crear la referencia`,
        });
      } else {
        notification.success({
          message: `Producto añadido`,
          duration: 10,
          description: `Producto añadido exitosamente`,
        });
        this.setState({ visible: false, variants: [] });
        this.props.reload();
      }
    } catch (e) {
      let errorMessage = e.response.data.message;
      if (
        errorMessage.includes('product in this catalog with that reference')
      ) {
        errorMessage = `Ya existe un producto en el catálogo con esa referencia.`;
      } else if (errorMessage.includes("Couldn't find warehouse with name")) {
        errorMessage = `No se pudo encontrar una bodega con ese nombre`;
      }
      notification.error({
        message: `Productos no añadidos`,
        duration: 0,
        description: errorMessage,
      });
    }
  }

  async addProductConfiguration() {
    let vatPercentage = 0;
    if (this.state.taxClassificationName === 'TAXABLE') {
      vatPercentage = 19;
    }
    const variant = {
      currencyCode: this.state.newCurrencyCodeName,
      properties: [...this.state.properties],
      reference: this.state.newPCReference,
      retailPrice: this.state.newRetailPrice,
      basePrice: this.state.newBasePrice,
      taxClassificationName: this.state.taxClassificationName,
      vatPercentage: vatPercentage,
      images: [],
    };
    this.setState((prevState) => {
      const { variants } = prevState;
      variants.push(variant);
      return { variants, properties: [] };
    });
  }

  addVariant() {
    for (let i = 0; i < this.state.properties.length; i += 1) {
      const property = this.state.properties[i];
      if (property.name === `${this.state.variantName}`.trim().toUpperCase()) {
        notification.error({
          message: 'Dimensión Repetida',
          duration: 0,
          description: `Ya agregaste la dimensión ${this.state.variantName} a esta configuración`,
        });
        return;
      }
    }
    this.setState((prevState) => {
      const { properties } = prevState;
      properties.push({
        name: `${prevState.variantName}`.trim().toUpperCase(),
        value: `${prevState.variantValue}`.trim().toUpperCase(),
      });
      return { properties, variantName: undefined, variantValue: undefined };
    });
  }

  async closeModal() {
    this.setState({
      name: undefined,
      reference: undefined,
      description: undefined,
      variants: [],
      properties: [],
      newPCReference: undefined,
      newRetailPrice: undefined,
      newBasePrice: undefined,
      taxClassificationName: 'TAXABLE',
      newCurrencyCodeName: 'COP',
      visible: false,
    });
  }

  editVariantProperty({ name, value }, index) {
    const item = this.state.properties[index];
    if (item) {
      const val = {
        name,
        value,
      };
      const unmutbubble = [...this.state.properties];
      unmutbubble[index] = val;
      this.setState({
        properties: [...unmutbubble],
      });
    }
  }

  deleteVariantProperty(index) {
    const item = this.state.properties[index];
    if (item) {
      this.setState((prevState) => {
        const unmutbubble = prevState.properties;
        unmutbubble.splice(index, 1);
        return {
          properties: [...unmutbubble],
        };
      });
    }
  }
  deleteVariant(index) {
    const item = this.state.variants[index];
    if (item) {
      this.setState((prevState) => {
        const unmutbubble = prevState.variants;
        unmutbubble.splice(index, 1);
        return {
          variants: [...unmutbubble],
        };
      });
    }
  }

  componentDidMount() {
    this.fetchCatalogs();
  }

  render() {
    return (
      <div style={{ width: '100%' }}>
        <button
          type="button"
          className="button primary center"
          onClick={() => this.setState({ visible: true })}
          style={{ width: '100%' }}
        >
          <ion-icon
            name="add-outline"
            style={{ height: '20px', width: '20px' }}
          ></ion-icon>
          Nuevo producto
        </button>
        <Modal
          title="Ingresa los datos de tu nuevo producto"
          visible={this.state.visible}
          onCancel={() => this.closeModal()}
          width="80%"
          footer={[
            <Button
              key="save"
              type="primary"
              onClick={this.createProduct}
              loading={this.state.addingProductConfiguration}
              disabled={
                !this.state.name ||
                !this.state.reference ||
                !this.state.description ||
                this.state.variants.length < 1
              }
            >
              Crear producto
            </Button>,
            <Button key="cancel" onClick={() => this.closeModal()}>
              Cancelar
            </Button>,
          ]}
        >
          <div className="add-product-form">
            <div className="product-data-form">
              <div style={{ fontSize: '1.2em' }}>Datos del producto:</div>
              <br />
              <Row gutter={24}>
                <Col span={12}>Nombre</Col>
                <Col span={12}>Referencia</Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <Input
                    onChange={(e) => this.setState({ name: e.target.value })}
                    value={this.state.name}
                    style={{
                      height: 50,
                      borderColor: 'darkGrey',
                      fontSize: '1.2em',
                    }}
                  />
                </Col>
                <Col span={12}>
                  <Input
                    onChange={(e) =>
                      this.setState({ reference: e.target.value })
                    }
                    value={this.state.reference}
                    style={{
                      height: 50,
                      borderColor: 'darkGrey',
                      fontSize: '1.2em',
                    }}
                  />
                </Col>
              </Row>
              <br />
              <Row gutter={24}>
                <Col span={24}>Descripción</Col>
                <Col span={24}>
                  <TextArea
                    rows={5}
                    onChange={(e) =>
                      this.setState({ description: e.target.value })
                    }
                    value={this.state.description}
                    style={{
                      borderColor: 'darkGrey',
                      fontSize: '1.2em',
                    }}
                  />
                </Col>
              </Row>
              <br />
              <Select
                style={{ width: '100%' }}
                onChange={(e) => this.setState({ catalogId: e })}
                onSelect={(e) => this.setState({ catalogId: e })}
                placeholder="Selecciona el catálogo de este producto"
                value={this.state.catalogId}
              >
                {this.state.catalogs.map((item) => (
                  <Option value={item.id} key={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </div>
            <Divider />
            <div className="variant-form">
              <p style={{ fontSize: '1.3rem' }}>Crear variante</p>
              <Row gutter={24}>
                <Col span={8}>Referencia única</Col>
                <Col span={8}>
                  Precio sugerido
                  <Tooltip
                    title="Es el precio (incluído IVA) al que los vendedores ofreceran tus productos."
                    placement="top"
                  >
                    <ion-icon
                      name="help-circle-outline"
                      style={{
                        marginBottom: '-5px',
                        width: '20px',
                        height: '20px',
                        cursor: 'pointer',
                      }}
                    />
                  </Tooltip>
                </Col>
                <Col span={8}>
                  Precio base
                  <Tooltip
                    title={
                      <span>
                        Es el precio (incluido IVA) al que ustedes como
                        proveedores esperan que Flipcat les pague los productos.
                        <br />
                        *Nota: Si haces parte del régimen simplificado
                        igualmente deberás subirle el valor del IVA
                        correspondiente.
                      </span>
                    }
                  >
                    <ion-icon
                      name="help-circle-outline"
                      style={{
                        marginBottom: '-5px',
                        width: '20px',
                        height: '20px',
                        cursor: 'pointer',
                      }}
                    />
                  </Tooltip>
                </Col>
                <Col span={8}>
                  <Input
                    onChange={(e) =>
                      this.setState({ newPCReference: e.target.value })
                    }
                    value={this.state.newPCReference}
                    style={{
                      height: 50,
                      borderColor: 'darkGrey',
                      fontSize: '1.2em',
                    }}
                  />
                </Col>
                <Col span={8}>
                  <InputNumber
                    onChange={(e) => this.setState({ newRetailPrice: e })}
                    value={this.state.newRetailPrice}
                    style={{
                      width: '100%',
                      height: 50,
                      borderColor: 'darkGrey',
                      fontSize: '1.2em',
                    }}
                    formatter={(value) =>
                      `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }
                  />
                </Col>
                <Col span={8}>
                  <InputNumber
                    onChange={(e) => this.setState({ newBasePrice: e })}
                    value={this.state.newBasePrice}
                    style={{
                      width: '100%',
                      height: 50,
                      borderColor: 'darkGrey',
                      fontSize: '1.2em',
                    }}
                    formatter={(value) =>
                      `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }
                  />
                </Col>
              </Row>
              <br />
              <Row gutter={24}>
                <Col span={6}>Moneda</Col>
                <Col span={4}>
                  Impuesto %{' '}
                  <Tooltip
                    title="(Impuesto sobre el Valor Añadido) Es un incremento de un porcentaje en el precio de cada artículo que compramos."
                    placement="top"
                  >
                    <ion-icon
                      name="help-circle-outline"
                      style={{
                        marginBottom: '-5px',
                        width: '20px',
                        height: '20px',
                        cursor: 'pointer',
                      }}
                    ></ion-icon>
                  </Tooltip>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={6}>
                  <Input
                    placeholder="CurrencyCode"
                    onChange={(e) =>
                      this.setState({ newCurrencyCodeName: e.target.value })
                    }
                    value={this.state.newCurrencyCodeName}
                    style={{
                      height: 50,
                      borderColor: 'darkGrey',
                      fontSize: '1.2em',
                    }}
                  />
                </Col>
                <Col span={4}>
                  <Select
                    placeholder="Tipo impuesto"
                    value={this.state.taxClassificationName}
                    filterOption={false}
                    onSelect={(taxClassificationName) =>
                      this.setState({ taxClassificationName })
                    }
                  >
                    <Option key="TAXABLE">GRAVABLE</Option>
                    <Option key="EXEMPT">EXENTO</Option>
                    <Option key="EXCLUDED">EXCLUIDO</Option>
                  </Select>
                </Col>
              </Row>
              <div className="variant-property-form">
                <p>Propiedades: </p>
                <Row gutter={24}>
                  {this.state.properties.map((property, index) => (
                    <VariantPropertyInput
                      editVariantProperty={this.editVariantProperty}
                      deleteVariantProperty={this.deleteVariantProperty}
                      index={index}
                      variantProperty={property}
                      key={index}
                    />
                  ))}
                  <Col span={5}>
                    <Input
                      placeholder="Dimensión (COLOR)"
                      onChange={(e) =>
                        this.setState({ variantName: e.target.value })
                      }
                      value={this.state.variantName}
                      style={{
                        height: 50,
                        borderColor: 'darkGrey',
                        fontSize: '1.2em',
                      }}
                    />
                    <Input
                      placeholder="Valor (ROJO)"
                      onChange={(e) =>
                        this.setState({ variantValue: e.target.value })
                      }
                      value={this.state.variantValue}
                      style={{
                        height: 50,
                        borderColor: 'darkGrey',
                        fontSize: '1.2em',
                        marginTop: '1em',
                      }}
                    />
                    <Button
                      style={{ marginTop: '1rem' }}
                      type="primary"
                      onClick={this.addVariant}
                      disabled={
                        !this.state.variantValue || !this.state.variantName
                      }
                    >
                      Añadir propiedad
                    </Button>
                  </Col>
                  <Divider type="vertical" />
                </Row>
              </div>
              <div className="create-variant-button-container">
                <Button
                  type="primary"
                  style={{ marginTop: '1rem', textAlign: 'right' }}
                  onClick={this.addProductConfiguration}
                  disabled={
                    !this.state.newPCReference ||
                    !this.state.newRetailPrice ||
                    !this.state.newBasePrice ||
                    !this.state.newCurrencyCodeName ||
                    this.state.properties.length < 1
                  }
                >
                  Crear variante
                </Button>
              </div>
            </div>
            {this.state.variants.length > 0 && (
              <div className="variants-table">
                <div style={{ fontSize: '1.2em' }}>
                  Variantes de este producto:
                </div>
                <Row gutter={24}>
                  <Col span={4}>Propiedades</Col>
                  <Divider type="vertical" />
                  <Col span={4}>Referencia única</Col>
                  <Divider type="vertical" />
                  <Col span={3}>Precio Público</Col>
                  <Divider type="vertical" />
                  <Col span={3}>Precio Base</Col>
                  <Divider type="vertical" />
                  <Col span={2}>Moneda</Col>
                  <Divider type="vertical" />
                  <Col span={2}>Impuesto %</Col>
                  <Divider />
                </Row>
                {this.state.variants &&
                  this.state.variants.map((variant, index) => (
                    <Row gutter={24} key={variant.reference}>
                      <Col span={4}>
                        <div style={{ textAlign: 'left' }}>
                          {variant.properties.map((property) => (
                            <div key={`${property.name}-${property.value}`}>
                              {property.name}: {property.value}
                            </div>
                          ))}
                        </div>
                      </Col>
                      <Divider type="vertical" />
                      <Col span={3}>{variant.reference}</Col>
                      <Divider type="vertical" />
                      <Col span={2}>{variant.retailPrice}</Col>
                      <Divider type="vertical" />
                      <Col span={2}>{variant.basePrice}</Col>
                      <Divider type="vertical" />
                      <Col span={2}>{variant.currencyCode}</Col>
                      <Divider type="vertical" />
                      <Col span={2}>{variant.vatPercentage}</Col>
                      <Divider />
                      <Col span={3}>
                        <div
                          style={{ cursor: 'pointer' }}
                          onClick={() => this.deleteVariant(index)}
                        >
                          <span>
                            Eliminar <DeleteOutlined />
                          </span>
                        </div>
                      </Col>
                    </Row>
                  ))}
                <Divider />
              </div>
            )}
          </div>
        </Modal>
      </div>
    );
  }
}

AddProduct.propTypes = {
  reload: PropTypes.func.isRequired,
};

export default AddProduct;
