import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';

const columns = [
  { title: '#', dataIndex: 'index' },
  { title: 'Referencia', dataIndex: 'reference' },
  { title: 'Nombre', dataIndex: 'name' },
  { title: 'Descripción', dataIndex: 'description' },
];

const traductor = {
  TAXABLE: 'GRAVABLE',
  EXEMPT: 'EXENTO',
  EXCLUDED: 'EXCLUIDO',
};

class ProductsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  rowByUniqueRef = (item) => {
    const columnsDetailed = [
      { title: 'Referencia unica', dataIndex: 'reference' },
      { title: 'Precio catalogo', dataIndex: 'retailPrice' },
      { title: 'Precio vendedora', dataIndex: 'sellerPrice' },
      { title: 'Propiedades', dataIndex: 'properties' },
      { title: 'Tipo de IVA', dataIndex: 'taxClassificationName' },
      { title: 'Porcentaje de IVA', dataIndex: 'vatPercentage' },
    ];

    const data = [];
    for (let i = 0; i < item.variants.length; i += 1) {
      let properties = '';
      for (
        let j = 0;
        item.variants[i].properties && j < item.variants[i].properties.length;
        j += 1
      ) {
        properties += `${item.variants[i].properties[j].name}: ${item.variants[i].properties[j].value} & `;
      }
      data.push({
        key: i,
        sellerPrice: item.variants[i].sellerPrice,
        retailPrice: item.variants[i].retailPrice,
        reference: item.variants[i].reference,
        stock: item.variants[i].stock,
        properties,
        vatPercentage: item.variants[i].vatPercentage,
        taxClassificationName:
          traductor[item.variants[i].taxClassificationName],
      });
    }
    return (
      <Table
        rowKey={(p) => p.reference}
        columns={columnsDetailed}
        dataSource={data}
        pagination={false}
      />
    );
  };

  render() {
    const { products } = this.props;
    for (let i = 0; i < products.length; i += 1) {
      products[i].index = i + 1;
    }
    return (
      <div>
        Revisa la tabla y abajo encuentras la opción de subir tus productos
        <Table
          rowKey={(p) => p.reference}
          columns={columns}
          dataSource={products}
          expandedRowRender={this.rowByUniqueRef}
          bordered
          pagination={{ pageSize: 10, total: products.length }}
          showHeader
        />
        <div
          style={{
            paddingLeft: '20%',
            paddingTop: '2em',
            paddingRight: '20%',
            paddingBottom: '2em',
          }}
        >
          Verifica bien en la tabla el datalle de los productos que vas a subir,
          luego no podrán ser modificados para este catalogo
        </div>
      </div>
    );
  }
}

ProductsTable.propTypes = {
  products: PropTypes.array.isRequired,
};

export default ProductsTable;
