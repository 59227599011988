import React from 'react';
import PropTypes from 'prop-types';
import { LoadingOutlined } from '@ant-design/icons';

function LoadingCard({ message }) {
  return (
    <div className="card">
      <div className="row center gap">
        <LoadingOutlined /> {message || ''}
      </div>
    </div>
  );
}

LoadingCard.propTypes = {
  message: PropTypes.string,
};

export default LoadingCard;
