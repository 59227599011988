/* eslint-disable no-await-in-loop */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Table, InputNumber } from 'antd';
import { PACKING_COMPLETED } from '../Utils/constants';

class BoxesModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedBoxes: [],
    };
    this.getCols = this.getCols.bind(this);
    this.sendBoxes = this.sendBoxes.bind(this);
  }

  getCols() {
    const boxColumns = [
      { title: 'Nombre', dataIndex: 'name', key: 'name' },
      { title: 'Largo (cm)', dataIndex: 'length', key: 'length' },
      { title: 'Ancho (cm)', dataIndex: 'width', key: 'width' },
      { title: 'Alto (cm)', dataIndex: 'height', key: 'height' },
      { title: 'Peso (Kg)', dataIndex: 'weight', key: 'weight' },
      {
        title: 'Cantidad',
        dataIndex: '',
        render: (box) => {
          const foundBox = this.state.selectedBoxes.find(
            (b) => b.boxId === box.id,
          );
          return (
            <InputNumber
              min={0}
              defaultValue={0}
              value={foundBox ? foundBox.quantity : 0}
              onChange={(newQuantity) =>
                this.changeBoxQuantity(newQuantity, box)
              }
            />
          );
        },
      },
    ];
    return boxColumns;
  }

  closeModal() {
    this.setState({ selectedBoxes: [] });
    this.props.changeBoxesModalVisibility(false);
  }

  changeBoxQuantity(quantity, box) {
    let { selectedBoxes } = this.state;
    if (quantity === 0) {
      selectedBoxes = selectedBoxes.filter((b) => b.boxId !== box.id);
    } else {
      const foundBox = selectedBoxes.find((b) => b.boxId === box.id);
      if (!foundBox) {
        selectedBoxes.push({ boxId: box.id, quantity });
      } else {
        foundBox.quantity = quantity;
      }
    }
    this.setState({ selectedBoxes });
  }

  async sendBoxes() {
    const { packings } = this.props;

    for (let i = 0; i < packings.length; i += 1) {
      await this.props.sendBoxes(
        PACKING_COMPLETED,
        packings[i],
        this.state.selectedBoxes,
      );
    }
    this.setState({ selectedBoxes: [] });
  }

  render() {
    const { visible, boxes, loading } = this.props;

    return (
      <div>
        <Modal
          width="55%"
          title="Seleccione las cajas que uso para empacar"
          visible={visible}
          onCancel={() => this.closeModal()}
          footer={[
            <Button
              loading={loading}
              key="back"
              onClick={() => this.closeModal()}
            >
              Cancelar
            </Button>,
            <Button
              key="submit"
              loading={loading}
              type="primary"
              disabled={this.state.selectedBoxes.length === 0}
              onClick={() => this.sendBoxes()}
            >
              Guardar
            </Button>,
          ]}
        >
          <h2>Cajas de la bodega:</h2>
          <br />
          <Table
            loading={loading}
            rowKey={(box) => box.id}
            columns={this.getCols()}
            dataSource={boxes}
          />
        </Modal>
      </div>
    );
  }
}
BoxesModal.propTypes = {
  boxes: PropTypes.array,
  visible: PropTypes.bool,
  changeBoxesModalVisibility: PropTypes.func,
  sendBoxes: PropTypes.func,
  packings: PropTypes.array,
  loading: PropTypes.bool,
};
export default BoxesModal;
