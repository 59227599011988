import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Upload,
  Button,
  Modal,
  Input,
  DatePicker,
  message,
  Checkbox,
  notification,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import CategoriesTable from './Categories/CategoriesTable';
import displayErrorMessage from '../Errors/DisplayErrorMessage';

const { RangePicker } = DatePicker;

const ls = require('local-storage');
const axios = require('axios');

class UpdateCatalog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      isPublic: false,
      ordersAllowed: false,
      imageUrl: undefined,
      updatingCatalog: false,
      categories: [],
      categoriesSelected: {},
      loadingCategories: false,
    };
    this.check = this.check.bind(this);
    this.handleOk = this.handleOk.bind(this);
  }

  showModal = () => {
    this.setState({ visible: true });
  };

  handleOk() {
    const { categoriesSelected, name, linkURL } = this.state;
    const { ordersAllowed, isPublic, dates } = this.state;
    const hasAtLeastOneCategory = Object.keys(categoriesSelected).length > 0;
    if (name && linkURL && dates && hasAtLeastOneCategory) {
      dates[0].set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      dates[1].set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      const catalog = {
        name,
        since: dates[0].toISOString(),
        to: dates[1].toISOString(),
        public: isPublic,
        ordersAllowed,
        linkURL,
        id: this.props.catalog.id,
      };
      if (this.state.imageUrl) {
        catalog.image = this.state.imageUrl;
      }
      this.updateCatalog(catalog);
    } else {
      message.error('Ingresa todos los campos!');
    }
  }

  async updateCatalog(catalog) {
    this.setState({ updatingCatalog: true });
    const data = catalog;
    data.categoriesIds = Object.keys(this.state.categoriesSelected);
    let url = `${ls.get('gatewayAPI')}/company-service-api`;
    url += `/catalog/${catalog.id}`;
    const headers = { Authorization: `Bearer ${ls.get('authTokenCompany')}` };
    try {
      await axios({ method: 'put', url, headers, data });
      notification.success({
        message: `Catalogo actualizado`,
        duration: 10,
        description: `Los datos del catalogo fueron actualizados.`,
      });
      this.setState({ visible: false });
    } catch (e) {
      displayErrorMessage('Error actualizando este catálogo', e);
    }
    this.setState({ updatingCatalog: false });
  }

  handleCancel = () => this.setState({ visible: false });

  getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('La imagen debe ser .JPG o .PNG');
    }
    const isLt2M = file.size / 1024 / 1024 < 0.5;
    if (!isLt2M) {
      message.error('La imagen debe ser menor a 500KB!');
    }
    return isJpgOrPng && isLt2M;
  }

  handleChange = (info) => {
    this.getBase64(info.file.originFileObj, (imageUrl) =>
      this.setState({ imageUrl }),
    );
  };

  // Categories

  async getCategories() {
    this.setState({ loadingCategories: true });
    let url = `${ls.get('gatewayAPI')}/company-service-api/category`;
    url += `?categoryLevel=1`;
    const headers = { Authorization: `Bearer ${ls.get('authTokenCompany')}` };
    try {
      const result = await axios({ method: 'get', url, headers });
      this.setState({ categories: result.data });
    } catch (e) {
      displayErrorMessage('Error recuperando las categorias', e);
    }
    this.setState({ loadingCategories: false });
  }

  check(category) {
    this.setState((prevState) => {
      const { categoriesSelected } = prevState;
      if (categoriesSelected[category.id]) {
        delete categoriesSelected[category.id];
      } else {
        categoriesSelected[category.id] = true;
      }
      return { categoriesSelected };
    });
  }

  componentDidMount() {
    this.getCategories();
    for (let i = 0; i < this.props.catalog.categories.length; i += 1) {
      const category = this.props.catalog.categories[i];
      this.setState((prevState) => {
        const { categoriesSelected } = prevState;
        categoriesSelected[category.id] = true;
        return { categoriesSelected };
      });
    }
    this.setState({
      name: this.props.catalog.name,
      linkURL: this.props.catalog.linkURL,
      isPublic: this.props.catalog.isPublic,
      ordersAllowed: this.props.catalog.allowOrders,
      dates: [
        moment(this.props.catalog.openingDate),
        moment(this.props.catalog.closingDate),
      ],
    });
  }

  render() {
    return (
      <div className="Catalogs">
        <Button type="primary" onClick={this.showModal}>
          Actualiza los datos de tu catálogo
        </Button>
        <Modal
          title="Actualiza los datos de tu catálogo"
          visible={this.state.visible}
          onCancel={this.handleCancel}
          footer={[
            <Button danger key="cancel" onClick={this.handleCancel}>
              Cancelar
            </Button>,
            <Button
              key="create"
              type="primary"
              onClick={this.handleOk}
              loading={this.state.updatingCatalog}
            >
              Actualizar catálogo
            </Button>,
          ]}
        >
          Nombre:
          <Input
            value={this.state.name}
            onChange={(e) => this.setState({ name: e.target.value })}
          />
          <br />
          <br />
          Ipaper URL:
          <Input
            value={this.state.linkURL}
            onChange={(e) => this.setState({ linkURL: e.target.value })}
          />
          <br />
          <br />
          <CategoriesTable
            categories={this.state.categories}
            categoriesSelected={this.state.categoriesSelected}
            loadingCategories={this.state.loadingCategories}
            check={this.check}
            action="add"
          />
          <br />
          Fecha Inicio y fecha fin:
          <br />
          <RangePicker
            value={this.state.dates}
            onChange={(e) => this.setState({ dates: e })}
          />
          <br />
          <br />
          Imagen de portada:
          <br />
          <img
            src={this.props.catalog.imageURL}
            alt="avatar"
            style={{ width: '100%', height: '100%' }}
          />
          <br />
          Cambiar imagen:
          <br />
          <Upload
            listType="picture-card"
            showUploadList={false}
            beforeUpload={this.beforeUpload}
            onChange={this.handleChange}
          >
            {this.state.imageUrl ? (
              <img
                src={this.state.imageUrl}
                alt="avatar"
                style={{ width: '100%', height: '100%' }}
              />
            ) : (
              <div>
                <PlusOutlined />
                <div className="ant-upload-text">Subir</div>
              </div>
            )}
          </Upload>
          <br />
          <br />
          Mostrar a las vendedoras (Es público?):
          <br />
          <Checkbox
            checked={this.state.isPublic}
            onChange={(e) => this.setState({ isPublic: e.target.checked })}
          />
          <br />
          <br />
          Permite órdenes?:
          <br />
          <Checkbox
            checked={this.state.ordersAllowed}
            onChange={(e) => this.setState({ ordersAllowed: e.target.checked })}
          />
        </Modal>
      </div>
    );
  }
}

UpdateCatalog.propTypes = {
  catalog: PropTypes.object,
};
export default UpdateCatalog;
