function compare(a, b) {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
}

function FindUniqueRefDuplicates(products) {
  const uniqueReferenceHashMap = {};

  const uniqueReferencesSmallerThan1Digit = [];
  const repeatedUniqueReferences = [];
  const productsWithPCWithSameDimensions = [];

  for (let i = 0; i < products.length; i += 1) {
    const currentProduct = products[i];

    if (currentProduct.reference.length < 2)
      uniqueReferencesSmallerThan1Digit.push(
        `Ref general invalida. Minimo 3 digitos. Ref general: ${currentProduct.reference}`,
      );

    const uniqueReferenceDimensionAndValuesForThisProduct = {};

    for (let j = 0; j < currentProduct.variants.length; j += 1) {
      const currentVariant = currentProduct.variants[j];

      if (currentVariant.reference.length < 2)
        uniqueReferencesSmallerThan1Digit.push(
          `Ref Unica invalida. Minimo 3 digitos. Ref unica: ${currentVariant.reference}`,
        );

      // If reference already exist, there is a problem
      if (uniqueReferenceHashMap[currentVariant.reference])
        repeatedUniqueReferences.push(
          `Ref Unica invalida. Repetida en el archivo. Ref unica:${currentVariant.reference}`,
        );

      uniqueReferenceHashMap[currentVariant.reference] = true;

      // If values are already present in product, there is a problem
      const productConfigurationValues = currentVariant.properties;
      productConfigurationValues.sort(compare);
      let productConfigKey = '';
      productConfigurationValues.forEach((productConfigValue) => {
        const dimensionValue = productConfigValue.value;
        const dimension = productConfigValue.name;

        productConfigKey += `${dimension}-${dimensionValue}-`;
      });

      if (uniqueReferenceDimensionAndValuesForThisProduct[productConfigKey])
        productsWithPCWithSameDimensions.push(
          `Ref Unica invalida. Mismas dimensiones que otra dentro del mismo producto. Ref unica: ${currentVariant.reference}`,
        );

      uniqueReferenceDimensionAndValuesForThisProduct[productConfigKey] = true;
    }
  }
  return {
    repeatedUniqueReferences,
    productsWithPCWithSameDimensions,
    uniqueReferencesSmallerThan1Digit,
  };
}

export default FindUniqueRefDuplicates;
