import displayError from '../components/Errors/DisplayErrorMessage';

const ls = require('local-storage');
const axios = require('axios');

export const createGuide = async (shipmentId) => {
  let url = `${ls.get('gatewayAPI')}/shipping-service-api`;
  url += `/public/shipment/${shipmentId}/guide`;
  const headers = { Authorization: `Bearer ${ls.get('authTokenCompany')}` };
  try {
    const result = await axios({ method: 'post', url, headers });
    const { trackingNumber, trackingPdfBase64, trackingPdfUrl } = result;
    return { trackingNumber, trackingPdfBase64, trackingPdfUrl };
  } catch (e) {
    displayError(e, 'Error creando guía');
  }
  return {
    trackingNumber: undefined,
    trackingPdfBase64: undefined,
    trackingPdfUrl: undefined,
  };
};

export const getShipment = async (shipmentId) => {
  let url = `${ls.get('gatewayAPI')}/shipping-service-api/public`;
  url += `/shipment/${shipmentId}`;
  const headers = { Authorization: `Bearer ${ls.get('authTokenCompany')}` };
  try {
    const result = await axios({ method: 'get', url, headers });
    return result.data;
  } catch (e) {
    displayError(e, 'Error recuperando guía');
  }
  return undefined;
};
