import XLSX from 'xlsx';

function getRow(data, headers, numRow, errors) {
  let pcReference = data[headers[0]];
  let stock = data[headers[1]];

  pcReference = pcReference ? pcReference.toString().trim() : null;
  if (pcReference === '') {
    pcReference = null;
  }

  stock = parseInt(`${stock}`.replace('$', '').replace(',', ''), 0);
  if (Number.isNaN(stock) || stock < 0) {
    errors.push(`Error in line: ${numRow + 2}. Invalid stock. (${stock}) `);
  }

  return { pcReference, stock };
}

function formatProducts(products, headers) {
  const finalProducts = [];
  const errors = [];
  for (let k = 0; k < products.length; k += 1) {
    const { pcReference, stock } = getRow(products[k], headers, k, errors);
    if (pcReference) {
      finalProducts.push({ pcReference, stock });
    }
  }
  return { finalProducts, errors };
}

export const getProductConfigsWithQuantity = (file, headers) =>
  new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);
    // eslint-disable-next-line func-names
    fileReader.onload = function (e) {
      const workbook = XLSX.read(btoa(fixdata(e.target.result)), {
        type: 'base64',
      });
      const workbookJson = toJson(workbook);
      const products = workbookJson[Object.keys(workbookJson)[0]];

      if (!products) {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject({
          type: 'danger',
          msg: 'Este documento no funciona.',
        });
        return;
      }
      products.slice(1);
      const { finalProducts, errors } = formatProducts(products, headers);
      resolve({ finalProducts, errors });
    };
  });

export const getHeaders = (file) =>
  new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);
    // eslint-disable-next-line func-names
    fileReader.onload = function (e) {
      const workbook = XLSX.read(btoa(fixdata(e.target.result)), {
        type: 'base64',
      });
      const workbookJson = toJson(workbook);
      const products = workbookJson[Object.keys(workbookJson)[0]];
      if (!products) {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject({
          type: 'danger',
          msg: 'Este documento no funciona.',
        });
        return;
      }
      const keys = Object.keys(products[0]);
      resolve(keys);
    };
  });

function fixdata(data) {
  let o = '';
  let l = 0;
  const w = 10240;
  // eslint-disable-next-line no-plusplus
  for (; l < data.byteLength / w; ++l)
    o += String.fromCharCode.apply(
      null,
      new Uint8Array(data.slice(l * w, l * w + w)),
    );
  o += String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w)));
  return o;
}

function toJson(workbook) {
  const result = {};
  // eslint-disable-next-line func-names
  workbook.SheetNames.forEach(function (sheetName) {
    const roa = XLSX.utils.sheet_to_row_object_array(
      workbook.Sheets[sheetName],
    );
    if (roa.length > 0) {
      result[sheetName] = roa;
    }
  });
  return result;
}
