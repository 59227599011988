import React from 'react';
import XLSX from 'xlsx';
import moment from 'moment';
import { Select, Table, DatePicker, Modal } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { fetchSuborders } from '../../../gateway/OrderServices';
import { fetchCompany } from '../../../gateway/CompanyServices';
import { fetchAllCities } from '../../../gateway/CountryServices';
import { fetchPackings } from '../../../gateway/WarehouseServices';
import { getShipment } from '../../../gateway/ShippingServices';
import Suborder from './Suborder';
import ProgressBar from '../../ProgressBar/ProgressBar';
import OverlayLoader from '../../OverlayLoader';
import './suborders.scss';

const { Option } = Select;

class Suborders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: { current: 1, pageSize: 10 },
      suborders: [],
      fetchingSuborders: false,
      generatingExcelFile: false,
      statusNames: [],
      allStatusNames: [
        'ORDER_CREATED',
        'ORDER_REJECTED',
        'PAYMENT_IN_PROCESS',
        'PAYMENT_COMPLETED',
        'ORDER_IN_PACKING',
        'ORDER_PACKED',
        'ORDER_IN_SHIPPING',
        'ORDER_DELIVERED',
        'CANCELED_BY_USER',
        'ORDER_CANCELED',
      ],
      createdBefore: moment(),
      createdAfter: moment(new Date().setDate(1)),
    };
    this.fetchSuborders = this.fetchSuborders.bind(this);
    this.fetchCompany = this.fetchCompany.bind(this);
    this.generateExcelFile = this.generateExcelFile.bind(this);
    this.setShippingCityToSuborders = this.setShippingCityToSuborders.bind(
      this,
    );
    this.reload = this.reload.bind(this);
    this.getProgressValue = this.getProgressValue.bind(this);
    this.getShipments = this.getShipments.bind(this);
    this.shipmentsTrackingHash = {};
    this.shippingCarrierHash = {};
    this.shippingCostByValientaHash = {};
  }

  getCols() {
    const cols = [
      {
        title: 'Fecha creación',
        dataIndex: '',
        key: 'createdAt',
        render: (s) => {
          const d = moment(new Date(s.order.createdAt));
          d.format('YYYY/MM/DD');
          return <div>{d.format('YYYY/MM/DD')}</div>;
        },
      },
      { title: 'Número de orden', dataIndex: 'companyOrderNumber' },
      {
        title: 'Vendedora',
        dataIndex: '',
        key: 'seller',
        render: (s) => (
          <div>
            {s.order.billingFirstName} {s.order.billingLastName}
          </div>
        ),
      },
      {
        title: 'Cliente',
        dataIndex: '',
        key: 'client',
        render: (s) => (
          <div>
            {s.order.shippingFirstName} {s.order.shippingLastName}
          </div>
        ),
      },
      {
        title: 'Estado',
        dataIndex: 'status',
        key: 'status',
        render: (status) => <div>{status.value}</div>,
      },
    ];
    return cols;
  }

  async setShippingCityToSuborders(suborders) {
    const governmentCodes = suborders.map(
      (suborder) => suborder.order.shippingCityCode,
    );
    const { cities } = await fetchAllCities(
      0,
      governmentCodes.length,
      undefined,
      undefined,
      governmentCodes,
    );
    suborders.forEach((suborder) => {
      const { shippingCityCode } = suborder.order;
      const city = cities.find((c) => c.governmentCode === shippingCityCode);
      // eslint-disable-next-line no-param-reassign
      suborder.shippingCityName = city.name;
      this.setState((prevState) => ({
        suborderProcess: prevState.suborderProcess + 1,
      }));
    });
  }

  async getShipments(suborder) {
    const { packings, total } = await fetchPackings(
      0,
      20,
      undefined,
      suborder.id,
    );

    if (total > 0) {
      const shipmentIds = packings.map((p) => p.shipmentId);

      const shipments = [];
      for (let i = 0; i < shipmentIds.length; i += 1) {
        if (shipmentIds[i] !== null) {
          // eslint-disable-next-line no-await-in-loop
          const res = await getShipment(shipmentIds[i]);
          if (res !== undefined) {
            shipments.push(res);
          }
        }
      }
      if (shipments.length > 0) {
        packings.forEach((pack) => {
          const shipment = shipments.find((s) => s.id === pack.shipmentId);
          if (shipment) {
            // eslint-disable-next-line no-param-reassign
            pack.shipment = shipment;
            this.shipmentsTrackingHash[suborder.id] = shipment.trackingNumber;
            this.shippingCarrierHash[suborder.id] =
              shipment.shippingCarrier.name;
            this.shippingCostByValientaHash[suborder.id] =
              pack.shippingCostChargedByValienta;
          }
        });
      }
    }
  }

  async generateExcelFile() {
    this.setState({ generatingExcelFile: true });

    const {
      totalSubordersNumber,
      companyId,
      statusNames,
      companyOrderNumber,
      createdAfter,
      createdBefore,
    } = this.state;

    let name = 'Subordenes';
    if (createdAfter) {
      name += ` creadas desde: ${moment(createdAfter).format('YYYY/MM/DD')}`;
    }

    if (createdBefore) {
      name += ` creadas hasta: ${moment(createdBefore).format('YYYY/MM/DD')}`;
    }
    name += '.xlsx';
    const firstSheet = [
      'Fecha creación de orden',
      'Número de orden',
      'Vendedora',
      'Cliente',
      'Estado',
      'Ciudad Destino',
      'Nombre del producto',
      'Configuración',
      'Ref. Única',
      'Cantidad',
      'Precio base unitario',
      'Precio mayorista unitario',
      'Precio público unitario',
      'Valor de flete',
      'Número de Guía',
      'Transportadora',
    ];

    const dataFirstSheet = [];
    dataFirstSheet.push(firstSheet);
    const totalBatches = Math.ceil(totalSubordersNumber / 50);

    const allFetchsForSuborderPromises = [];
    for (let i = 1; i <= totalBatches; i += 1) {
      allFetchsForSuborderPromises.push(
        fetchSuborders(
          i - 1,
          50,
          companyId,
          statusNames,
          createdBefore,
          createdAfter,
          companyOrderNumber,
        ),
      );
    }

    const allSuborders = await Promise.all(allFetchsForSuborderPromises);

    const promiseCities = [];
    for (let i = 0; i < allSuborders.length; i += 1) {
      promiseCities.push(
        this.setShippingCityToSuborders(allSuborders[i].suborders),
      );
    }
    await Promise.all(promiseCities);

    const suborders = [];
    for (let i = 0; i < allSuborders.length; i += 1) {
      for (let j = 0; j < allSuborders[i].suborders.length; j += 1) {
        suborders.push(allSuborders[i].suborders[j]);
      }
    }

    const promiseShipments = [];
    for (let i = 0; i < suborders.length; i += 1) {
      promiseShipments.push(this.getShipments(suborders[i]));
    }
    await Promise.all(promiseShipments);

    for (let i = 0; i < suborders.length; i += 1) {
      const suborder = suborders[i];

      for (let j = 0; j < suborder.suborderItems.length; j += 1) {
        if (j === 0) {
          dataFirstSheet.push([
            moment(suborder.order.createdAt).format('YYYY/MM/DD hh:mm a'),
            suborder.companyOrderNumber,
            `${suborder.order.billingFirstName} ${suborder.order.billingLastName}`,
            `${suborder.order.shippingFirstName} ${suborder.order.shippingLastName}`,
            suborder.status.value,
            suborder.shippingCityName,
            suborder.suborderItems[j].name,
            suborder.suborderItems[j].configuration,
            suborder.suborderItems[j].uniqueReference,
            suborder.suborderItems[j].quantity,
            {
              v: suborder.suborderItems[j].basePrice,
              t: 'n',
              z: '$#,##0.00',
            },
            {
              v: suborder.suborderItems[j].sellerPrice,
              t: 'n',
              z: '$#,##0.00',
            },
            {
              v: suborder.suborderItems[j].publicPrice,
              t: 'n',
              z: '$#,##0.00',
            },
            this.shippingCostByValientaHash[suborder.id] || '',
            this.shipmentsTrackingHash[suborder.id] || '',
            this.shippingCarrierHash[suborder.id] || '',
          ]);
        } else {
          dataFirstSheet.push([
            '',
            '',
            '',
            '',
            '',
            '',
            suborder.suborderItems[j].name,
            suborder.suborderItems[j].configuration,
            suborder.suborderItems[j].uniqueReference,
            suborder.suborderItems[j].quantity,
            {
              v: suborder.suborderItems[j].basePrice,
              t: 'n',
              z: '$#,##0.00',
            },
            {
              v: suborder.suborderItems[j].sellerPrice,
              t: 'n',
              z: '$#,##0.00',
            },
            {
              v: suborder.suborderItems[j].publicPrice,
              t: 'n',
              z: '$#,##0.00',
            },
            this.shippingCostByValientaHash[suborder.id] || '',
            this.shipmentsTrackingHash[suborder.id] || '',
            this.shippingCarrierHash[suborder.id] || '',
          ]);
        }
      }
    }

    const ws1cols = [
      { wch: 20 },
      { wch: 15 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 30 },
      { wch: 20 },
      { wch: 20 },
      { wch: 15 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
    ];

    const worksheet1 = XLSX.utils.aoa_to_sheet(dataFirstSheet);
    worksheet1['!cols'] = ws1cols;
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet1, 'Subordenes');
    XLSX.writeFile(workbook, name);
    this.shipmentsTrackingHash = {};
    this.shippingCarrierHash = {};
    this.shippingCostByValientaHas = {};
    this.setState({ generatingExcelFile: false, suborderProcess: 0 });
  }

  reload() {
    this.setState(
      {
        pagination: { current: 1, pageSize: 10 },
        suborders: [],
      },
      () => this.fetchSuborders(this.state.pagination),
    );
  }

  async fetchSuborders(params) {
    const { pageSize, current } = params;
    const {
      companyOrderNumber,
      statusNames,
      createdBefore,
      createdAfter,
      companyId,
    } = this.state;
    this.setState({ fetchingSuborders: true });
    const { suborders, total } = await fetchSuborders(
      current - 1,
      pageSize,
      companyId,
      statusNames.length > 0 ? statusNames : undefined,
      createdBefore,
      createdAfter,
      companyOrderNumber,
    );
    this.setState({
      totalSubordersNumber: total,
      suborderProcess: 0,
      fetchingSuborders: false,
      suborders,
      pagination: { ...params, total },
    });
  }

  getProgressValue() {
    return Math.ceil(
      (this.state.suborderProcess * 100) / this.state.totalSubordersNumber,
    );
  }

  async fetchCompany() {
    const company = await fetchCompany();
    this.setState({ companyId: company?.id });
  }

  async componentDidMount() {
    await this.fetchCompany();
    this.fetchSuborders(this.state.pagination);
  }

  render() {
    return (
      <div>
        <div className="row center">
          <span className="viewTitle">
            <ion-icon
              name="cube-outline"
              style={{ height: '25px', width: '25px', marginRight: '10px' }}
            ></ion-icon>
            <h2>Subordenes</h2>
          </span>
        </div>
        <br />
        <br />
        <div className="row center card" style={{ flexDirection: 'row' }}>
          {/* Input fields column */}
          <div
            className="col center no-padding"
            style={{ maxWidth: '800px', width: '80%' }}
          >
            <div
              className="row center no-padding"
              style={{ width: '100%', marginBottom: '10px' }}
            >
              <div
                className="col no-padding center"
                style={{ maxWidth: '200px', minWidth: '120px', width: '100%' }}
              >
                <div className="inputLabel">Estados:</div>
                <Select
                  mode="multiple"
                  allowClear
                  placeholder="Seleccione el/los estado(s)"
                  style={{ width: '100%' }}
                  onChange={(status) => this.setState({ statusNames: status })}
                  onClear={() => this.setState({ statusNames: [] })}
                >
                  {this.state.allStatusNames.map((status) => (
                    <Option key={status}>{status}</Option>
                  ))}
                </Select>
              </div>
              <div
                className="col no-padding center"
                style={{ maxWidth: '200px', minWidth: '120px', width: '100%' }}
              >
                <div className="inputLabel">Número de orden:</div>
                <div style={{ display: 'inline-flex' }}>
                  <input
                    type="text"
                    className="small"
                    placeholder="Número de orden"
                    onChange={(e) =>
                      this.setState({ companyOrderNumber: e.target.value })
                    }
                  />
                </div>
              </div>
            </div>
            <hr />
            <div className="row center no-padding" style={{ width: '100%' }}>
              <div
                className="col no-padding center"
                style={{ maxWidth: '300px', minWidth: '120px', width: '100%' }}
              >
                <div style={{ fontSize: 'medium' }}>
                  Desde:
                  <DatePicker
                    style={{ marginLeft: '1em', marginRight: '1em' }}
                    onChange={(date) =>
                      this.setState({
                        createdAfter: date
                          ? moment(date).startOf('day')
                          : undefined,
                      })
                    }
                    format="YYYY-MM-DD"
                    value={this.state.createdAfter}
                  />
                  (12 am)
                </div>
              </div>
              <div
                className="col no-padding center"
                style={{ maxWidth: '300px', minWidth: '120px', width: '100%' }}
              >
                <div style={{ fontSize: 'medium' }}>
                  Hasta:
                  <DatePicker
                    style={{ marginLeft: '1em', marginRight: '1em' }}
                    onChange={(date) =>
                      this.setState({
                        createdBefore: date
                          ? moment(date).startOf('day')
                          : undefined,
                      })
                    }
                    format="YYYY-MM-DD"
                    value={this.state.createdBefore}
                  />
                  (12 am)
                </div>
              </div>
            </div>
          </div>
          {/* Button column */}
          <div
            className="col center"
            style={{ maxWidth: '480px', minWidth: '150px', width: '20%' }}
          >
            <button
              type="button"
              className={`button primary small ${
                !this.state.companyId && 'disabled'
              }`}
              onClick={this.reload}
            >
              {this.state.fetchingSuborders && (
                <LoadingOutlined
                  style={{ marginRight: '10px' }}
                ></LoadingOutlined>
              )}
              Buscar
            </button>
          </div>
        </div>
        <div className="row">
          <div
            className="col no-padding"
            style={{ marginLeft: '0', marginRight: 'auto' }}
          >
            {this.state.pagination.total && (
              <div className="inputLabel">
                Subordenes encontradas: {this.state.pagination.total}
                <br />
                Subordenes en total: {this.state.totalSubordersNumber}
              </div>
            )}
            <button
              type="button"
              className="button secondary small center"
              style={{ marginTop: '1em' }}
              disabled={this.state.generatingExcelFile}
              onClick={this.generateExcelFile}
            >
              <ion-icon
                name="cloud-download-outline"
                style={{ width: '20px', height: '20px', marginRight: '5px' }}
              ></ion-icon>
              Descargar reporte
            </button>
          </div>
        </div>
        <Table
          bordered
          showHeader
          rowKey={(s) => s.id}
          columns={this.getCols()}
          dataSource={this.state.suborders}
          pagination={this.state.pagination}
          loading={this.state.fetchingSuborders}
          onChange={this.fetchSuborders}
          expandedRowRender={(s) => <Suborder suborder={s} />}
        />
        <br />
        {this.state.generatingExcelFile && (
          <div style={{ width: '100%' }}>
            <div>
              <Modal
                closable={false}
                width="30%"
                visible
                footer={null}
                style={{ marginTop: '190px' }}
              >
                <div>
                  {this.state.suborderProcess === 0 ? (
                    <div className="col center">
                      <OverlayLoader />
                      <span style={{ marginTop: '6em' }}>Procesando datos</span>
                    </div>
                  ) : (
                    <div className="col center" style={{ width: '100%' }}>
                      <h3> Descargando suborders</h3>
                      <ProgressBar
                        progress={this.getProgressValue()}
                        height={30}
                      ></ProgressBar>
                      <span>
                        {this.state.suborderProcess} /
                        {this.state.totalSubordersNumber}
                      </span>
                    </div>
                  )}
                </div>
              </Modal>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Suborders;
