import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'antd';
import { addProductConfiguration, addVariant } from './actions';
import { DeleteOutlined } from '@ant-design/icons';

function AddNewProductConfiguration({
  variants,
  productId,
  setLoading,
  fetchData,
  showModal,
}) {
  const initialState = {
    reference: '',
    basePrice: 0,
    retailPrice: 0,
    currencyCodeName: 'COP',
    isAvailable: true,
    vatPercentage: 19,
    taxClassificationName: 'TAXABLE',
    properties: [],
  };
  const [productConfiguration, setProductConfiguration] =
    useState(initialState);

  const [newVariant, setNewVariant] = useState({
    name: '',
    value: '',
  });
  const [isAddingNewVariant, setIsAddingNewVariant] = useState(false);

  const handleProductConfigurationChange = (e) => {
    const obj = {
      ...productConfiguration,
      [e.target.name]: e.target.value,
    };
    if (e.target.name === 'taxClassificationName') {
      let vatPercentage = 19;
      if (e.target.value === 'EXEMPT' || e.target.value === 'EXCLUDED') {
        vatPercentage = 0;
      }
      obj.vatPercentage = vatPercentage;
    }
    setProductConfiguration(obj);
  };

  const handleVariantSelect = (e) => {
    if (e.target.value === 'addNewDimension') {
      setIsAddingNewVariant(true);
      setNewVariant({ ...newVariant, name: '' });
    } else {
      setIsAddingNewVariant(false);
      setNewVariant({ ...newVariant, name: e.target.value });
    }
  };

  const handleVariantChange = (variant) => {
    setNewVariant({ ...newVariant, [variant.name]: variant.value });
  };

  const handleAddVariant = () => {
    const canAddVariant = addVariant(
      productConfiguration.properties,
      newVariant.name,
    );
    if (canAddVariant) {
      const name = newVariant.name.trim().toUpperCase();
      const value = newVariant.value.trim().toUpperCase();
      setProductConfiguration({
        ...productConfiguration,
        properties: [...productConfiguration.properties, { name, value }],
      });
      setNewVariant({ name: '', value: '' });
    }
  };

  const handleAddVariantOnKeyUp = (event) => {
    if (newVariant.name && newVariant.value && event.ctrlKey)
      handleAddVariant();
  };

  const handleDeleteVariant = (variant) => {
    setProductConfiguration({
      ...productConfiguration,
      properties: productConfiguration.properties.filter(
        (item) => item.name !== variant.name,
      ),
    });
  };

  /*  document.addEventListener(
    'keyup',
    (event) => {
      if (event.ctrlKey) console.log('evsdfdsafdsfent :>> ', event);
    },
    false,
  ); */

  const taxClassificationNames = [
    { name: 'GRAVABLE', value: 'TAXABLE' },
    { name: 'EXENTO', value: 'EXEMPT' },
    { name: 'EXCLUIDO', value: 'EXCLUDED' },
  ];

  const checkAllFields = () => {
    const vatPercentage = parseInt(productConfiguration.vatPercentage, 10) >= 0;
    return (
      !productConfiguration.reference ||
      !productConfiguration.basePrice ||
      !productConfiguration.properties.length ||
      !productConfiguration.retailPrice ||
      !productConfiguration.currencyCodeName ||
      !vatPercentage ||
      !productConfiguration.taxClassificationName
    );
  };

  return (
    <div className="card fit cont-add-new-variant">
      <div className="col">
        <h2>Agregar nueva Configuracion de Producto</h2>
        <div className="col">
          <div className="row no-padding">
            <div className="col">
              Referencia
              <input
                type="text"
                name="reference"
                value={productConfiguration.reference}
                onChange={handleProductConfigurationChange}
              />
            </div>
            <div className="col">
              Precio Base
              <input
                type="text"
                name="basePrice"
                value={productConfiguration.basePrice}
                onChange={handleProductConfigurationChange}
              />
            </div>
            <div className="col">
              Precio Publico
              <input
                type="text"
                name="retailPrice"
                value={productConfiguration.retailPrice}
                onChange={handleProductConfigurationChange}
              />
            </div>
            <div className="col">
              Moneda
              <input
                type="text"
                name="currencyCodeName"
                value={productConfiguration.currencyCodeName}
                onChange={handleProductConfigurationChange}
              />
            </div>
          </div>
          <div className="row center gap no-padding">
            <div
              className="col"
              style={{ marginTop: 0, marginBottom: 'auto', rowGap: '5px' }}
            >
              <div className="row gap">
                Tipo
                <select
                  value={productConfiguration.taxClassificationName}
                  onChange={handleProductConfigurationChange}
                  name="taxClassificationName"
                >
                  {taxClassificationNames.map((taxClassification) => (
                    <option
                      value={taxClassification.value}
                      key={taxClassification.name}
                    >
                      {taxClassification.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="row gap">
              Disponible
              <Switch
                checked={productConfiguration.isAvailable}
                onChange={(e) =>
                  handleProductConfigurationChange({
                    target: { name: 'isAvailable', value: e },
                  })
                }
              />
            </div>
            <hr />
          </div>
          <div className="col">
            <div style={{ fontWeight: '500' }}>Variantes</div>
            <div className="row gap no-padding">
              {productConfiguration.properties.map((variant) => (
                <div
                  className="col cont-variant"
                  key={variant.reference + variant.name}
                >
                  <div className="row right no-padding">
                    <DeleteOutlined
                      className="btn"
                      onClick={() => {
                        handleDeleteVariant(variant);
                      }}
                    />
                  </div>
                  <span>{variant.name}</span>
                  <span>{variant.value}</span>
                </div>
              ))}
            </div>

            <div className="row no-padding">
              <div className="col cont-name">
                Dimension (Color)
                <select
                  onKeyUp={handleAddVariantOnKeyUp}
                  onChange={handleVariantSelect}
                  value={newVariant.name}
                >
                  <option value="">-- Seleccionar dimension --</option>
                  {Object.keys(variants).map((variant) => (
                    <option value={variant} key={variant}>
                      {variant}
                    </option>
                  ))}
                  <option value="addNewDimension">
                    Agregar nueva dimension
                  </option>
                </select>
                {isAddingNewVariant ? (
                  <input
                    type="text"
                    placeholder="Agregar nueva dimension"
                    name="name"
                    onChange={(e) => handleVariantChange(e.target)}
                    onKeyUp={handleAddVariantOnKeyUp}
                    value={newVariant.name}
                  />
                ) : null}
              </div>
              <div className="col cont-value">
                Valor (Rojo)
                <input
                  type="text"
                  name="value"
                  onChange={(e) => handleVariantChange(e.target)}
                  onKeyUp={handleAddVariantOnKeyUp}
                  value={newVariant.value}
                />
              </div>
            </div>
            <div className="col center no-padding">
              <button
                type="button"
                className={`button small ${
                  newVariant.name === '' || newVariant.value === ''
                    ? 'disabled'
                    : ''
                }`}
                onClick={handleAddVariant}
                disabled={!newVariant.name || !newVariant.value}
              >
                <ion-icon
                  name="add-outline"
                  style={{ marginRight: '10px' }}
                ></ion-icon>
                Agregar Variante
              </button>
            </div>
          </div>
        </div>
        <div className="row right no-padding" style={{ marginTop: '1.8em' }}>
          <button
            type="button"
            onClick={async () => {
              setLoading(true);
              showModal(false);
              await addProductConfiguration(productId, productConfiguration);
              await fetchData();
              setProductConfiguration(initialState);
              setLoading(false);
            }}
            className={`button primary ${checkAllFields() ? 'disabled' : ''}`}
            disabled={checkAllFields()}
            style={{ padding: '5px 25px' }}
          >
            Agregar y Guardar
          </button>
        </div>
      </div>
    </div>
  );
}

AddNewProductConfiguration.propTypes = {
  variants: PropTypes.object,
  productId: PropTypes.string,
  setLoading: PropTypes.func,
  fetchData: PropTypes.func,
  showModal: PropTypes.func,
};

export default AddNewProductConfiguration;
