import React, { useState } from 'react';
import { Col, Input, Button } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

export function VariantPropertyInput({
  editVariantProperty,
  deleteVariantProperty,
  variantProperty,
  index,
}) {
  const [isEdit, setIsEdit] = useState(false);
  const [editProperty, setEditProperty] = useState(variantProperty);

  function toggleEdit() {
    if (isEdit) {
      setIsEdit(false);
    } else {
      setEditProperty(editProperty);
      setIsEdit(true);
    }
  }

  return isEdit === true ? (
    <Col span={5} key={variantProperty.name}>
      <Input
        placeholder="Dimensión (COLOR)"
        onChange={(e) =>
          setEditProperty({ ...editProperty, name: e.target.value })
        }
        value={editProperty.name}
        style={{
          height: 50,
          borderColor: 'darkGrey',
          fontSize: '1.2em',
        }}
      />
      <Input
        placeholder="Valor (ROJO)"
        onChange={(e) =>
          setEditProperty({ ...editProperty, value: e.target.value })
        }
        value={editProperty.value}
        style={{
          height: 50,
          borderColor: 'darkGrey',
          fontSize: '1.2em',
          marginTop: '1em',
        }}
      />
      <Button
        style={{ marginTop: '2em' }}
        type="primary"
        onClick={() => {
          editVariantProperty(editProperty, index);
          toggleEdit();
        }}
        disabled={!editProperty.name || !editProperty.value}
      >
        Editar propiedad
      </Button>
    </Col>
  ) : (
    <Col span={5} key={variantProperty.name}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          fontSize: '1rem',
          marginBottom: '0.5rem',
        }}
      >
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => deleteVariantProperty(index)}
        >
          <span>
            Eliminar{' '}
            <DeleteOutlined />
          </span>
        </div>
        <div style={{ cursor: 'pointer' }} onClick={() => toggleEdit()}>
          <span>
            Editar{' '}
            <EditOutlined />
          </span>
        </div>
      </div>

      <div>Dimensión: {variantProperty.name}</div>
      <div>Valor: {variantProperty.value}</div>
    </Col>
  );
}
