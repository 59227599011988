import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Modal, Button, Input, Checkbox, notification } from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  FilterOutlined,
} from '@ant-design/icons';
import displayErrorMessage from '../../Errors/DisplayErrorMessage';
import AddCompanyCategory from './AddCompanyCategory';

const ls = require('local-storage');
const axios = require('axios');

class CompanyCategories extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.updateCompanyCategory = this.updateCompanyCategory.bind(this);
    this.deleteCompanyCategory = this.deleteCompanyCategory.bind(this);
  }

  async deleteCompanyCategory(companyCategoryId) {
    let url = `${ls.get('gatewayAPI')}/company-service-api/company-category`;
    url += `/${companyCategoryId}`;
    const headers = { Authorization: `Bearer ${ls.get('authTokenCompany')}` };
    try {
      await axios({ method: 'delete', url, headers });
      this.props.reload();
      notification.success({
        message: `Agrupación eliminada`,
        duration: 10,
        description: `Agrupación eliminada exitosamente`,
      });
    } catch (e) {
      displayErrorMessage('Error eliminando la agrupación de la compañia', e);
    }
  }

  async updateCompanyCategory() {
    this.setState({ updatingCompanyCategory: true });
    const data = {
      name: this.state.name,
      isActive: this.state.isActive,
    };
    let url = `${ls.get('gatewayAPI')}/company-service-api`;
    url += `/company-category/${this.state.editCompanyCategory.id}`;
    const headers = { Authorization: `Bearer ${ls.get('authTokenCompany')}` };
    try {
      await axios({ method: 'put', url, headers, data });
      this.props.reload();
      this.setState({ editCompanyCategory: undefined });
      notification.success({
        message: `Agrupación modificada`,
        duration: 10,
        description: `Agrupación modificada exitosamente`,
      });
    } catch (e) {
      displayErrorMessage('Error modificando agrupación', e);
    }
    this.setState({ updatingCompanyCategory: false });
  }

  render() {
    const companyCategoryStyle = {
      border: '1px solid',
      borderRadius: '5px',
      padding: '0.8em',
    };
    const disabled = { backgroundColor: 'silver', color: 'gray' };
    const filterCategoryId = this.props.filterWithCompanyCategoryId;
    return (
      <div
        style={{
          width: '100%',
          height: 'fit-content',
          margin: '0em 2.5% 2em 2.5%',
          padding: '1em',
        }}
      >
        <div>
          <Row gutter={24}>
            {this.props.companyCategories.map((companyCategory) => (
              <Col
                span={4}
                key={companyCategory.id}
                style={{ marginTop: '1em' }}
                onMouseEnter={() =>
                  this.setState({
                    showOptionsOfCategoryId: companyCategory.id,
                  })
                }
                onMouseLeave={() =>
                  this.setState({
                    showOptionsOfCategoryId: undefined,
                  })
                }
              >
                <div
                  style={
                    companyCategory.isActive
                      ? { ...companyCategoryStyle }
                      : { ...companyCategoryStyle, ...disabled }
                  }
                >
                  <div>{companyCategory.name}</div>
                  <div
                    style={
                      this.state.showOptionsOfCategoryId === companyCategory.id
                        ? { display: 'inline-flex' }
                        : { display: 'none' }
                    }
                  >
                    <button
                      type="button"
                      onClick={() => {
                        if (filterCategoryId !== companyCategory.id) {
                          this.props.setCompanyCategoryFilter(
                            companyCategory.id,
                          );
                        } else {
                          this.props.setCompanyCategoryFilter(undefined);
                        }
                      }}
                      style={
                        filterCategoryId === companyCategory.id
                          ? { backgroundColor: 'purple' }
                          : {}
                      }
                    >
                      <FilterOutlined />
                    </button>
                    <button
                      type="button"
                      onClick={() =>
                        this.setState({
                          editCompanyCategory: companyCategory,
                          isActive: companyCategory.isActive,
                          name: companyCategory.name,
                        })
                      }
                    >
                      <EditOutlined />
                    </button>
                    <button
                      type="button"
                      onClick={() =>
                        this.deleteCompanyCategory(companyCategory.id)
                      }
                    >
                      <DeleteOutlined />
                    </button>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
          <br />
          <AddCompanyCategory reload={this.props.reload} />
        </div>
        <Modal
          title="Modifica los datos de tu agrupación"
          visible={this.state.editCompanyCategory}
          onCancel={() => this.setState({ editCompanyCategory: undefined })}
          width="50%"
          footer={[
            <Button
              key="save"
              type="primary"
              onClick={this.updateCompanyCategory}
              loading={this.state.updatingCompanyCategory}
            >
              Guardar
            </Button>,
            <Button
              key="cancel"
              onClick={() => this.setState({ editCompanyCategory: undefined })}
            >
              Cancelar
            </Button>,
          ]}
        >
          <div>
            <div style={{ fontSize: '1.2em' }}>Datos de la agrupación:</div>
            <br />
            <br />
            <Row gutter={24}>
              <Col span={12}>Nombre</Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <Input
                  onChange={(e) => this.setState({ name: e.target.value })}
                  value={this.state.name}
                  style={{
                    height: 50,
                    borderColor: 'darkGrey',
                    fontSize: '1.2em',
                  }}
                />
              </Col>
              <Col span={12}>
                <Checkbox
                  checked={this.state.isActive}
                  onChange={(e) => {
                    this.setState({ isActive: e.target.checked });
                  }}
                >
                  Agrupación habilitada
                </Checkbox>
              </Col>
            </Row>
          </div>
        </Modal>
      </div>
    );
  }
}

CompanyCategories.propTypes = {
  setCompanyCategoryFilter: PropTypes.func,
  filterWithCompanyCategoryId: PropTypes.string,
  reload: PropTypes.func,
  companyCategories: PropTypes.array,
};

export default CompanyCategories;
