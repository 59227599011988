import React from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Button,
  Row,
  Select,
  Input,
  Upload,
  message,
  InputNumber,
  Tooltip,
} from 'antd';
import {
  UploadOutlined,
  DeleteOutlined,
  CheckOutlined,
  FileSearchOutlined,
  UndoOutlined,
} from '@ant-design/icons';

class ContentFile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cf: {},
    };
    this.beforeUpload = this.beforeUpload.bind(this);
  }

  beforeUpload(file) {
    const fileTypeName =
      this.state.cf.newContentFileTypeName ||
      this.state.cf.contentFileType.name;
    if (fileTypeName === 'IMAGE') {
      const isJpgOrPng =
        file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        message.error('Debe subir una imagen en formato .JPG o .PNG');
        return isJpgOrPng;
      }
    } else if (fileTypeName === 'PDF') {
      const isPdf = file.type === 'application/pdf';
      if (!isPdf) {
        message.error('Debe subir un archivo PDF');
        return isPdf;
      }
    }
    this.setState((prevState) => {
      const theCF = prevState.cf;
      theCF.newFile = file;
      return { cf: theCF };
    });
    return true;
  }

  changeURL(url) {
    this.setState((prevState) => {
      const theCF = prevState.cf;
      theCF.newUrl = url;
      return { cf: theCF };
    });
  }

  changeName(name) {
    this.setState((prevState) => {
      const theCF = prevState.cf;
      theCF.newName = name;
      return { cf: theCF };
    });
  }

  changeContentFileTypeName(cfName) {
    let changeType = false;
    if (cfName === 'VIDEO' || cfName === 'GOOGLE_DRIVE') {
      this.setState((prevState) => {
        const theCF = prevState.cf;
        theCF.newFile = undefined;
        return { cf: theCF };
      });
      changeType = true;
    } else if (cfName === 'PDF') {
      if (this.state.cf.newFile !== undefined) {
        if (this.state.cf.newFile.type === 'application/pdf') {
          changeType = true;
        }
      } else {
        changeType = true;
      }
    } else if (cfName === 'IMAGE') {
      if (this.state.cf.newFile !== undefined) {
        if (
          this.state.cf.newFile.type === 'image/jpeg' ||
          this.state.cf.newFile.type === 'image/png'
        ) {
          changeType = true;
        }
      } else {
        changeType = true;
      }
    }
    if (changeType) {
      this.setState((prevState) => {
        const theCF = prevState.cf;
        theCF.newContentFileTypeName = cfName;
        return { cf: theCF };
      });
    }
  }

  componentDidMount() {
    this.setState({ cf: this.props.contentFile });
  }

  resetChanges() {
    this.setState((prevState) => {
      const theCF = prevState.cf;
      theCF.newName = undefined;
      theCF.newRelevance = undefined;
      theCF.newContentFileTypeName = undefined;
      theCF.newUrl = undefined;
      theCF.newFile = undefined;
      return { cf: theCF };
    });
  }

  render() {
    const { cf } = this.state;
    const showUndo =
      cf.id &&
      (cf.newName !== undefined ||
        cf.newRelevance !== undefined ||
        cf.newContentFileTypeName !== undefined ||
        cf.newUrl !== undefined ||
        cf.newFile !== undefined);
    const disableUrl =
      cf.newContentFileTypeName === 'IMAGE' ||
      cf.newContentFileTypeName === 'PDF' ||
      (!cf.newContentFileTypeName &&
        (!cf.contentFileType ||
          cf.contentFileType.name === 'PDF' ||
          cf.contentFileType.name === 'IMAGE'));
    const disableUpload =
      cf.newContentFileTypeName === 'VIDEO' ||
      cf.newContentFileTypeName === 'GOOGLE_DRIVE' ||
      (!cf.newContentFileTypeName &&
        (!cf.contentFileType ||
          cf.contentFileType.name === 'VIDEO' ||
          cf.contentFileType.name === 'GOOGLE_DRIVE'));
    const disableOpenFile =
      this.state.cf.newContentFileTypeName ||
      this.state.cf.newFile ||
      this.state.cf.newName ||
      this.state.cf.newRelevance ||
      this.state.cf.newUrl ||
      !this.state.cf.id;
    return (
      <div style={{ marginLeft: '2%', marginRight: '2%' }}>
        <Row gutter={24} style={{ marginTop: '1em' }}>
          <Col span={1}>
            {showUndo ? (
              <Tooltip title="Deshacer cambios">
                <Button
                  type="text"
                  style={{ color: '#1A002D' }}
                  onClick={() => this.resetChanges()}
                  shape="round"
                  icon={<UndoOutlined />}
                ></Button>
              </Tooltip>
            ) : (
              <div />
            )}
          </Col>
          <Col span={3}>
            <InputNumber
              placeholder="Posición"
              min={1}
              style={
                cf.newRelevance !== undefined
                  ? { width: '100%', borderColor: '#1A002D' }
                  : { width: '100%' }
              }
              value={
                cf.newRelevance === undefined ? cf.relevance : cf.newRelevance
              }
              onChange={(e) =>
                this.setState((prevState) => {
                  const theCF = prevState.cf;
                  theCF.newRelevance = e;
                  return { cf: theCF };
                })
              }
            />
          </Col>
          <Col span={4}>
            <Input
              style={
                cf.newName !== undefined
                  ? { width: '100%', borderColor: '#1A002D' }
                  : { width: '100%' }
              }
              placeholder="Nombre"
              value={cf.newName === undefined ? cf.name : cf.newName}
              onChange={(e) => this.changeName(e.target.value)}
            />
          </Col>
          <Col span={4}>
            <Select
              style={{ width: '100%' }}
              loading={cf.newContentFileTypeName !== undefined}
              filterOption={false}
              onSelect={(e) => this.changeContentFileTypeName(e)}
              value={
                cf.contentFileType
                  ? cf.newContentFileTypeName || cf.contentFileType.name
                  : cf.newContentFileTypeName
              }
              defaultValue={
                cf.contentFileType ? cf.contentFileType.name : 'Tipo de archivo'
              }
            >
              {['PDF', 'IMAGE', 'GOOGLE_DRIVE', 'VIDEO'].map((type) => (
                <Select.Option key={type}>{type}</Select.Option>
              ))}
            </Select>
          </Col>
          <Col span={9}>
            <Input
              style={
                cf.newUrl !== undefined
                  ? { width: '100%', borderColor: '#1A002D' }
                  : { width: '100%' }
              }
              placeholder="URL del archivo"
              disabled={disableUrl}
              value={cf.newUrl === undefined ? cf.url : cf.newUrl}
              onChange={(e) => this.changeURL(e.target.value)}
            />
          </Col>
          <Col span={1}>
            <Upload
              disabled={disableUpload}
              beforeUpload={this.beforeUpload}
              showUploadList={false}
            >
              <div>
                <Tooltip title="Subir archivo">
                  <Button
                    type="text"
                    style={
                      cf.newFile !== undefined
                        ? { color: '#1A002D', borderColor: '#1A002D' }
                        : { color: '#1A002D' }
                    }
                    shape="round"
                    icon={cf.newFile ? <CheckOutlined /> : <UploadOutlined />}
                    disabled={disableUpload}
                  ></Button>
                </Tooltip>
              </div>
            </Upload>
          </Col>
          <Col span={1}>
            <Tooltip title="Abrir archivo">
              <Button
                disabled={disableOpenFile}
                onClick={() => window.open(this.state.cf.url)}
                type="text"
                style={{ color: '#1A002D' }}
                shape="round"
                icon={<FileSearchOutlined />}
              ></Button>
            </Tooltip>
          </Col>
          <Col span={1}>
            <Tooltip title="Borrar archivo">
              <Button
                type="text"
                style={{ color: 'red' }}
                shape="round"
                onClick={() =>
                  this.props.removeContentFile(this.props.contentFileIndex)
                }
                icon={<DeleteOutlined />}
              ></Button>
            </Tooltip>
          </Col>
        </Row>
      </div>
    );
  }
}

ContentFile.propTypes = {
  contentFile: PropTypes.object.isRequired,
  removeContentFile: PropTypes.func.isRequired,
  contentFileIndex: PropTypes.number,
};

export default ContentFile;
