import React from 'react';
import PropTypes from 'prop-types';

function EditProductInformation({
  productConfiguration,
  handleProductConfigurationChange,
  taxClassificationNames,
}) {
  return (
    <div className="row left no-padding">
      <div className="col">
        Referencia
        <input
          type="text"
          name="reference"
          value={productConfiguration.reference}
          onChange={handleProductConfigurationChange}
        />
      </div>
      <div className="col">
        Precio Base
        <input
          type="text"
          name="basePrice"
          value={productConfiguration.basePrice}
          onChange={handleProductConfigurationChange}
        />
      </div>
      <div className="col">
        Precio Publico
        <input
          type="text"
          name="retailPrice"
          value={productConfiguration.retailPrice}
          onChange={handleProductConfigurationChange}
        />
      </div>
      <div className="col">
        Tipo
        <select
          value={productConfiguration.taxClassificationName}
          onChange={handleProductConfigurationChange}
          name="taxClassificationName"
        >
          {taxClassificationNames.map((taxClassification) => (
            <option
              value={taxClassification.value}
              key={taxClassification.name}
            >
              {taxClassification.name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}

EditProductInformation.propTypes = {
  productConfiguration: PropTypes.object,
  handleProductConfigurationChange: PropTypes.func,
  taxClassificationNames: PropTypes.array,
};

export default EditProductInformation;
