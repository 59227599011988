import React from 'react';
import './products.scss';
import XLSX from 'xlsx';
import moment from 'moment';
import { Empty, Pagination, Switch } from 'antd';
import { ShopOutlined, LoadingOutlined } from '@ant-design/icons';
import ProductCard from './Product/ProductCard';
import Errors from '../Errors/Errors';
import displayErrorMessage from '../Errors/DisplayErrorMessage';
import AddProduct from './AddProduct';
import CompanyCategories from './CompanyCategory/CompanyCategories';
import UploadImages from './UploadImages';
import UpdateProducts from './UpdateProducts';
import UpdateProductConfigurationsPrices from './UpdateProductConfigurationsPrices';
import { fetchProductConfigurations } from '../../gateway/ProductServices';
import OverlayLoader from '../OverlayLoader';

const ls = require('local-storage');
const axios = require('axios');

class Products extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      companyCategories: [],
      pagination: { current: 1, pageSize: 24 },
      fetchedConfigurations: 0,
      totalConfigurations: 0,
      errorStatus: undefined,
      fetchingProducts: false,
      onlyProductsFromPublicCatalogs: true,
      productsWithImages: true,
      isDisabled: false,
      generatingExcelFile: false,
    };
    this.fetchProducts = this.fetchProducts.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this.reload = this.reload.bind(this);
    this.changeProductReference = this.changeProductReference.bind(this);
    this.changeProductConfigReference =
      this.changeProductConfigReference.bind(this);
    this.setCompanyCategoryFilter = this.setCompanyCategoryFilter.bind(this);
    this.fetchCompanyCategories = this.fetchCompanyCategories.bind(this);
    this.fetchProductsFromPublicCatalogsWithoutImagesTotalCount =
      this.fetchProductsFromPublicCatalogsWithoutImagesTotalCount.bind(this);
    this.generateExcelFile = this.generateExcelFile.bind(this);
  }

  reload() {
    this.setState(
      { products: [], companyCategories: [], fetchingProducts: false },
      () => {
        this.fetchProducts();
        this.fetchCompanyCategories();
        this.fetchProductsTotalCount();
        this.fetchProductsFromPublicCatalogsWithoutImagesTotalCount();
      },
    );
  }

  setCompanyCategoryFilter(companyCategoryId) {
    this.setState({ filterWithCompanyCategoryId: companyCategoryId }, () =>
      this.reload(),
    );
  }

  async onChangePage(page) {
    await this.fetchProducts(page);
    if (page) {
      const productsGrid = document.getElementById('productsGrid');
      productsGrid.scrollIntoView({ behavior: 'smooth' });
    }
  }

  async fetchProducts(current) {
    this.setState({ fetchingProducts: true });
    const { pageSize } = this.state.pagination;

    let url = `${ls.get('gatewayAPI')}/company-service-api/product`;
    url += `?pageSize=${pageSize}&page=${(current || 1) - 1}`;
    if (this.state.productReference)
      url += `&productReference=${this.state.productReference}`;
    if (this.state.productConfigurationReference)
      url += `&productConfigurationReference=${this.state.productConfigurationReference}`;
    if (this.state.onlyProductsFromPublicCatalogs) {
      url += `&onlyProductsFromPublicCatalogs=true`;
    }
    if (this.state.filterWithCompanyCategoryId) {
      url += `&companyCategoryId=${this.state.filterWithCompanyCategoryId}`;
    }
    url += `&productsWithImages=${this.state.productsWithImages}`;
    url += `&isDisabled=${this.state.isDisabled}`;

    const headers = { Authorization: `Bearer ${ls.get('authTokenCompany')}` };
    try {
      const result = await axios({ method: 'get', url, headers });
      const products = result.data;
      const total = parseInt(result.headers['pagination-total'], 0);
      this.setState({ products, pagination: { current, total, pageSize } });
    } catch (e) {
      displayErrorMessage('Error recuperando productos', e);
      this.setState({ errorStatus: e.response ? e.response.status : 500 });
    } finally {
      this.setState({ fetchingProducts: false });
    }
  }

  async fetchProductsTotalCount() {
    const url = `${ls.get('gatewayAPI')}/company-service-api/product`;

    const headers = { Authorization: `Bearer ${ls.get('authTokenCompany')}` };
    try {
      const result = await axios({ method: 'get', url, headers });
      const totalCount = parseInt(result.headers['pagination-total'], 0);
      this.setState({ totalCount });
    } catch (e) {
      displayErrorMessage('Error recuperando productos', e);
    }
  }

  async fetchCompanyCategories() {
    let url = `${ls.get('gatewayAPI')}/company-service-api/company-category`;
    url += `?pageSize=50&page=0`;
    const headers = { Authorization: `Bearer ${ls.get('authTokenCompany')}` };
    try {
      const result = await axios({ method: 'get', url, headers });
      const companyCategories = result.data;
      this.setState({ companyCategories });
    } catch (e) {
      displayErrorMessage('Error recuperando agrupaciones de la compañia', e);
    }
  }

  async fetchProductsFromPublicCatalogsWithoutImagesTotalCount() {
    let url = `${ls.get('gatewayAPI')}/company-service-api/product`;
    url +=
      '?onlyProductsFromPublicCatalogs=true&productsWithImages=false&isDisabled=false';

    const headers = { Authorization: `Bearer ${ls.get('authTokenCompany')}` };
    try {
      const result = await axios({ method: 'get', url, headers });
      const totalCountFromPublicCatalogsAndNoImage = parseInt(
        result.headers['pagination-total'],
        0,
      );
      this.setState({ totalCountFromPublicCatalogsAndNoImage });
    } catch (e) {
      displayErrorMessage(e, 'Error recuperando productos');
    }
  }

  async changeProductConfigReference(e) {
    this.setState({ productConfigurationReference: e.target.value });
    await new Promise((r) => setTimeout(r, 500));
    this.fetchProducts();
  }

  async changeProductReference(e) {
    this.setState({ productReference: e.target.value });
    await new Promise((r) => setTimeout(r, 500));
    this.fetchProducts();
  }

  async generateExcelFile() {
    this.setState({ generatingExcelFile: true });
    const date = moment().endOf('day');
    const name = `Configuraciones de productos ${date.format(
      'YYYY/MM/DD',
    )}.xlsx`;
    const firstSheet = [
      'Referencia General',
      'Referencia Unica',
      'Nombre',
      'Configuración',
      'Precio base',
      'Precio mayorista',
      'Precio catálogo',
    ];
    const dataFirstSheet = [];
    dataFirstSheet.push(firstSheet);

    let currentPage = 0;
    const { productConfigurations, total } = await fetchProductConfigurations(
      undefined,
      undefined,
      undefined,
      currentPage,
      200,
    );
    let fetchedConfigurations = productConfigurations.length;
    this.setState({ totalConfigurations: total, fetchedConfigurations });

    currentPage += 1;
    let allProductConfigurations = productConfigurations;
    let finishedFetching = total <= 100;

    while (!finishedFetching) {
      // eslint-disable-next-line no-await-in-loop
      const result = await fetchProductConfigurations(
        undefined,
        undefined,
        undefined,
        currentPage,
        200,
      );
      fetchedConfigurations += result.productConfigurations.length;
      allProductConfigurations = allProductConfigurations.concat(
        result.productConfigurations,
      );
      finishedFetching = result.productConfigurations.length === 0;
      currentPage += 1;
      this.setState({ fetchedConfigurations });
    }

    for (let i = 0; i < allProductConfigurations.length; i += 1) {
      const configuration = allProductConfigurations[i];
      const dimensionArray = configuration.productConfigurationValues?.map(
        (configValue) =>
          `${configValue.dimensionValue?.dimension?.name}: ${configValue.dimensionValue?.value}`,
      );
      dataFirstSheet.push([
        configuration.product?.reference,
        configuration.reference,
        configuration.product?.name,
        dimensionArray.join(' '),
        {
          v: configuration.basePrice,
          t: 'n',
          z: '$#,##0.00',
        },
        {
          v: configuration.sellerPrice,
          t: 'n',
          z: '$#,##0.00',
        },
        {
          v: configuration.retailPrice,
          t: 'n',
          z: '$#,##0.00',
        },
      ]);
    }

    const ws1cols = [
      { wch: 20 },
      { wch: 20 },
      { wch: 50 },
      { wch: 50 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
    ];

    const worksheet1 = XLSX.utils.aoa_to_sheet(dataFirstSheet);
    worksheet1['!cols'] = ws1cols;
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      workbook,
      worksheet1,
      'Configuraciones de productos',
    );
    XLSX.writeFile(workbook, name);
    this.setState({ generatingExcelFile: false });
  }

  componentDidMount() {
    this.fetchProducts();
    this.fetchCompanyCategories();
    this.fetchProductsTotalCount();
    this.fetchProductsFromPublicCatalogsWithoutImagesTotalCount();
  }

  render() {
    return (
      <div>
        <div className="row center">
          <span className="viewTitle">
            <ShopOutlined style={{ fontSize: '25px', marginRight: '10px' }} />
            <h2>Productos</h2>
          </span>
        </div>
        {/* Actions buttons */}
        <div className="row no-padding center" style={{ marginBottom: '20px' }}>
          <div
            className="col center no-padding"
            style={{
              maxWidth: '200px',
              width: '100%',
              minWidth: '150px',
              marginRight: '10px',
              marginBottom: '10px',
            }}
          >
            <AddProduct reload={this.reload} />
          </div>
          <div
            className="col center no-padding"
            style={{
              maxWidth: '200px',
              width: '100%',
              minWidth: '150px',
              marginRight: '10px',
              marginBottom: '10px',
            }}
          >
            <UploadImages reload={this.reload} />
          </div>
          <div
            className="col center no-padding"
            style={{
              maxWidth: '200px',
              width: '100%',
              minWidth: '150px',
              marginRight: '10px',
              marginBottom: '10px',
            }}
          >
            <UpdateProducts reload={this.reload} />
          </div>
          <div
            className="col center no-padding"
            style={{
              maxWidth: '200px',
              width: '100%',
              minWidth: '150px',
              marginRight: '10px',
              marginBottom: '10px',
            }}
          >
            <button
              type="button"
              className="button secondary center"
              style={{ width: '100%' }}
              onClick={this.generateExcelFile}
            >
              {this.state.generatingExcelFile ? (
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  <LoadingOutlined
                    style={{ marginRight: '5px' }}
                  ></LoadingOutlined>{' '}
                  <span>
                    {this.state.fetchedConfigurations}/
                    {this.state.totalConfigurations}
                  </span>
                </span>
              ) : (
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  <ion-icon
                    name="cloud-download-outline"
                    style={{
                      marginRight: '5px',
                      height: '20px',
                      width: '20px',
                    }}
                  ></ion-icon>
                  <span>Descargar excel</span>
                </span>
              )}
            </button>
          </div>
          <div
            className="col center no-padding"
            style={{
              maxWidth: '200px',
              width: '100%',
              minWidth: '150px',
              marginRight: '10px',
              marginBottom: '10px',
            }}
          >
            <UpdateProductConfigurationsPrices reload={this.reload} />
          </div>
        </div>
        {/* Search filters and fields */}
        <div className="row card">
          <h3>Buscar productos</h3>
          {/* Switches row */}
          <div className="switches__row">
            <div className="switch__item">
              <div style={{ marginRight: '10px' }}>
                Sólo productos de catálogos públicos
              </div>
              <Switch
                checked={this.state.onlyProductsFromPublicCatalogs}
                onChange={(e) => {
                  this.setState({ onlyProductsFromPublicCatalogs: e }, () =>
                    this.fetchProducts(),
                  );
                }}
              ></Switch>
            </div>
            <div className="switch__item">
              <div style={{ marginRight: '10px' }}>
                Sólo productos con imagenes
              </div>
              <Switch
                checked={this.state.productsWithImages}
                onChange={(e) => {
                  this.setState({ productsWithImages: e }, () =>
                    this.fetchProducts(),
                  );
                }}
              ></Switch>
            </div>
            <div className="switch__item">
              <div style={{ marginRight: '10px' }}>
                Incluir productos deshabilitados
              </div>
              <Switch
                checked={this.state.isDisabled}
                onChange={(e) => {
                  this.setState({ isDisabled: e }, () => this.fetchProducts());
                }}
              ></Switch>
            </div>
          </div>
          <hr />
          {/* References input fields row */}
          <div className="row center" style={{ width: '100%' }}>
            <div
              className="col center"
              style={{ minWidth: '200px', width: '50%' }}
            >
              <p className="input__label">Referencia general</p>
              <input
                type="text"
                placeholder="Referencia general"
                className="large"
                onChange={this.changeProductReference}
              />
            </div>
            <div
              className="col center"
              style={{ minWidth: '200px', width: '50%' }}
            >
              <p className="input__label">Referencia única</p>
              <input
                type="text"
                placeholder="Referencia única"
                className="large"
                onChange={this.changeProductConfigReference}
              />
            </div>
          </div>
          {/* Search results summary row */}
          <div className="row no-padding" style={{ width: '100%' }}>
            <div className="col no-padding">
              <div style={{ fontSize: '14px' }}>
                Cantidad: {this.state.pagination.total}/{this.state.totalCount}
              </div>
              <div style={{ fontSize: '14px' }}>
                Productos habilitados y de catalogos publicos que no tienen
                imagen: {this.state.totalCountFromPublicCatalogsAndNoImage}
              </div>
            </div>
          </div>
        </div>
        <div className="row center" style={{ width: '100%' }}>
          <CompanyCategories
            reload={this.reload}
            companyCategories={this.state.companyCategories}
            setCompanyCategoryFilter={this.setCompanyCategoryFilter}
            filterWithCompanyCategoryId={this.state.filterWithCompanyCategoryId}
          />
          <Errors errorStatus={this.state.errorStatus} />
          {this.state.products.length === 0 && !this.state.fetchingProducts && (
            <div style={{ width: '100%' }}>
              <Empty description={<span>Sin Productos</span>} />
            </div>
          )}
          <div className="products__grid" id="productsGrid">
            {this.state.fetchingProducts && <OverlayLoader />}
            {this.state.products.map((product) => (
              <ProductCard
                product={product}
                reload={this.reload}
                key={product.id}
              />
            ))}
          </div>
        </div>
        {!this.state.fetchingProducts && (
          <div className="row center">
            <Pagination
              onChange={this.onChangePage}
              pageSize={this.state.pagination.pageSize}
              current={this.state.pagination.current || 1}
              total={this.state.pagination.total}
            />
          </div>
        )}
      </div>
    );
  }
}

export default Products;
