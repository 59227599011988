import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { toCurrency } from '../../Utils/formatters';

class Suborder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
    };
    this.setColumns = this.setColumns.bind(this);
  }

  setColumns() {
    const columns = [
      {
        title: 'Nombre',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Configuración',
        dataIndex: 'configuration',
        key: 'configuration',
      },
      {
        title: 'Ref. Única',
        dataIndex: 'uniqueReference',
        key: 'uniqueRef',
      },
      {
        title: 'Cantidad',
        dataIndex: 'quantity',
        key: 'quantity',
      },
      {
        title: 'Precio base',
        dataIndex: 'basePrice',
        key: 'basePrice',
        render: (s) => <div>{toCurrency(s)}</div>,
      },
      {
        title: 'Precio mayorista',
        dataIndex: 'sellerPrice',
        key: 'sellerPrice',
        render: (s) => <div>{toCurrency(s)}</div>,
      },
      {
        title: 'Precio público',
        dataIndex: 'publicPrice',
        key: 'publicPrice',
        render: (s) => <div>{toCurrency(s)}</div>,
      },
    ];
    this.setState({ columns });
  }

  componentDidMount() {
    this.setColumns();
  }

  render() {
    const { suborder } = this.props;
    return (
      <div>
        <br />
        <br />
        <h3>Items de la suborden:</h3>
        <Table
          rowKey={(s) => s.id}
          columns={this.state.columns}
          dataSource={suborder.suborderItems}
        />
        <br />
        <br />
      </div>
    );
  }
}

Suborder.propTypes = {
  suborder: PropTypes.object,
};

export default Suborder;
