import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './productCardModal.scss';
import {
  PlusOutlined,
  LoadingOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { Upload, Modal, notification, InputNumber } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import {
  addProductConfigurationMedia,
  deleteProductConfigMedia,
  deleteAllProductConfigMedia,
} from '../../gateway/ProductServices';
import OverlayLoader from '../../components/OverlayLoader';

const { Dragger } = Upload;

class UploadProductConfigurationImages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageList: [],
      loadingImages: false,
      uploadingImages: false,
    };
    this.beforeUpload = this.beforeUpload.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.onPreview = this.onPreview.bind(this);
    this.addImages = this.addImages.bind(this);
    this.addRelevance = this.addRelevance.bind(this);
  }

  onRemove(uid) {
    this.setState({ loadingImages: true });
    this.setState((prevState) => {
      const imageListAux = prevState.imageList;
      for (let i = 0; i < imageListAux.length; i += 1) {
        const currentImage = imageListAux[i];
        if (currentImage.file.uid === uid) {
          imageListAux.splice(i, 1);
          break;
        }
      }
      return { imageList: imageListAux, totalImages: imageListAux.length };
    });
    this.setState({ loadingImages: false });
    return true;
  }

  onPreview(media) {
    this.setState({ isPreviewImage: true, previewImage: media });
  }

  async beforeUpload(file, fileList) {
    this.setState({ loadingImages: true });
    const currentFile = file;
    const isJpgOrPng =
      currentFile.type === 'image/jpeg' || currentFile.type === 'image/png';
    if (!isJpgOrPng) {
      notification.error({
        message: `Imagen con error`,
        duration: 0,
        description: `La imagen ${currentFile.name} debe ser .jpg o .png`,
      });
      for (let i = 0; i < fileList.length; i += 1) {
        const element = fileList[i];
        if (element.name === file.name) {
          fileList.splice(i, 1);
        }
      }
    } else {
      this.setState((prevState) => {
        const { imageList } = prevState;
        imageList.push({ relevance: undefined, file });
        return { imageList, totalImages: imageList.length };
      });
    }
    this.setState({ loadingImages: false });
  }

  addRelevance(relevance, mediaId) {
    this.setState((prevState) => {
      const { imageList } = prevState;
      const img = imageList.find((i) => i.file.uid === mediaId);
      img.relevance = relevance;
      return { imageList };
    });
  }

  async addImages() {
    this.setState({ uploadingImages: true });
    const { productConfigurationId } = this.props;
    const imageListCopy = [...this.state.imageList];
    const imagesWithErrors = [];
    const calls = [];
    for (let i = 0; i < imageListCopy.length; i += 1) {
      const image = { ...imageListCopy[i] };
      calls.push(
        addProductConfigurationMedia(
          productConfigurationId,
          image.file,
          image.relevance,
        ).then((result) => {
          if (!result) {
            imagesWithErrors.push(image);
          }
        }),
      );
    }
    await Promise.all(calls);
    if (imagesWithErrors.length > 0) {
      notification.error({
        message: `Imagenenes que no se pudieron guardar: ${imagesWithErrors.length}`,
        duration: 0,
        description: `Imágenes con errores: ${imagesWithErrors.map(
          (image) => image.file.name,
        )}`,
      });
    }

    this.setState({ imageList: imagesWithErrors });
    this.setState({ uploadingImages: false });
    this.props.reloadProductConfigurationMedia(
      this.props.productConfigurationId,
    );
  }

  render() {
    return (
      <div style={{ position: 'relative' }}>
        {this.state.loadingImages && <OverlayLoader />}
        <div>
          {this.props.productConfigurationMedia.length !== 0 && (
            <div className="card" style={{ marginTop: '1em' }}>
              <div style={{ margin: '1em' }}>
                <div className="product__action">
                  {this.props.productConfigurationMedia.length !== 0 && (
                    <h2>Imágenes subidas</h2>
                  )}
                  {this.props.productConfigurationMedia.length !== 0 && (
                    <button
                      type="button"
                      className="link-button warning"
                      onClick={() => {
                        confirm({
                          title:
                            '¿Estás seguro que deseas eliminar todas las imágenes?',
                          icon: <ExclamationCircleOutlined />,
                          onOk: async () => {
                            await deleteAllProductConfigMedia(
                              this.props.productConfigurationId,
                            );
                            this.props.reloadProductConfigurationMedia(
                              this.props.productConfigurationId,
                            );
                          },
                        });
                      }}
                    >
                      <ion-icon
                        name="trash-outline"
                        style={{ marginRight: '5px' }}
                      ></ion-icon>
                      Eliminar todas
                    </button>
                  )}
                </div>
                {this.props.productConfigurationMedia.length !== 0 && (
                  <p>
                    Las siguentes son imágenes que actualmente tiene esta
                    variante de producto
                  </p>
                )}
                <div className="grid-cards">
                  {this.props.productConfigurationMedia.map((media) => (
                    <div className="card-modal" key={media.id}>
                      <div className="card-top">
                        <button
                          type="button"
                          onClick={() => this.onPreview(media)}
                          style={{
                            border: 'none',
                            backgroundColor: 'transparent',
                            cursor: 'pointer',
                            margin: '0.2rem',
                          }}
                        >
                          <ion-icon name="resize-outline"></ion-icon>
                        </button>
                        <button
                          type="button"
                          onClick={async () => {
                            this.setState({ loadingImages: true });
                            await deleteProductConfigMedia(
                              this.props.productConfigurationId,
                              media.id,
                            );
                            await this.props.reloadProductConfigurationMedia(
                              this.props.productConfigurationId,
                            );
                            this.setState({ loadingImages: false });
                          }}
                          style={{
                            border: 'none',
                            backgroundColor: 'transparent',
                            cursor: 'pointer',
                            margin: '0.2rem',
                            marginLeft: '43%',
                            color: 'red',
                          }}
                        >
                          <ion-icon name="trash-outline"></ion-icon>
                        </button>
                      </div>
                      <div className="card-action">{`# ${
                        media.relevance || ''
                      }`}</div>
                      <div className="card-bottom">
                        <img
                          alt="Imagen producto"
                          style={{
                            objectFit: 'contain',
                            width: '100px',
                            height: '70px',
                          }}
                          src={media.mediaURL}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
          <br />
          <div className="card">
            <div style={{ margin: '1em' }}>
              <h2> Cargar imágenes</h2>
              <p>Sube nuevas imágenes a esta variante del producto</p>
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  marginTop: '0.5em',
                  textAlign: 'center',
                }}
              >
                <div className="container-upload">
                  <Dragger
                    loading={this.state.loadingImages}
                    multiple
                    beforeUpload={this.beforeUpload}
                    showUploadList={false}
                    style={{ width: '150px' }}
                  >
                    <p className="ant-upload-drag-icon">
                      <PlusOutlined />
                    </p>
                    Cargar imágen
                  </Dragger>
                </div>
                <div className="product__action" style={{ marginTop: '1em' }}>
                  {this.state.imageList.length > 0 && (
                    <p>Imágenes cargadas: {this.state.imageList.length}</p>
                  )}
                  {this.state.imageList.length > 0 && (
                    <button
                      type="button"
                      className={`link-button warning ${
                        this.state.imageList.length === 0 ? 'disabled' : ''
                      }`}
                      onClick={() => {
                        this.setState({ imageList: [] });
                      }}
                      disabled={this.state.imageList.length === 0}
                    >
                      Limpiar
                    </button>
                  )}
                </div>
                <div className="grid-cards">
                  {this.state.imageList.map((image) => (
                    <div className="card-modal" key={image.file.uid}>
                      <div className="card-top">
                        <button
                          type="button"
                          onClick={() => this.onPreview(image.file)}
                          style={{
                            border: 'none',
                            backgroundColor: 'transparent',
                            cursor: 'pointer',
                            margin: '0.2rem',
                          }}
                        >
                          <ion-icon name="resize-outline"></ion-icon>
                        </button>
                        <button
                          type="button"
                          onClick={() => this.onRemove(image.file.uid)}
                          style={{
                            border: 'none',
                            backgroundColor: 'transparent',
                            cursor: 'pointer',
                            margin: '0.2rem',
                            marginLeft: '43%',
                            color: 'red',
                          }}
                        >
                          <ion-icon name="trash-outline"></ion-icon>
                        </button>
                      </div>
                      <div className="card-action">
                        <p
                          style={{
                            marginRight: '0.5em',
                            marginBottom: '0em',
                          }}
                        >
                          Posición:
                        </p>
                        <InputNumber
                          placeholder="#"
                          onChange={(e) => this.addRelevance(e, image.file.uid)}
                          style={{ maxWidth: '35%' }}
                        ></InputNumber>
                      </div>
                      <div className="card-bottom">
                        <img
                          alt="Imagen producto"
                          style={{
                            objectFit: 'contain',
                            width: '100px',
                            height: '70px',
                          }}
                          src={URL.createObjectURL(image.file)}
                        />
                      </div>
                    </div>
                  ))}
                </div>
                {this.state.imageList.length > 0 && (
                  <div className="container-upload">
                    <button
                      key="save"
                      type="button"
                      className="button primary modal button-icon"
                      onClick={() => this.addImages()}
                      loading={this.state.uploadingImages}
                      disabled={this.state.imageList.length === 0}
                    >
                      {this.state.uploadingImages ? (
                        <LoadingOutlined
                          style={{ fontSize: '1.3em', marginRight: '0.3em ' }}
                        />
                      ) : (
                        ''
                      )}
                      Subir
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <Modal
          title="Preview"
          visible={this.state.isPreviewImage}
          onCancel={() =>
            this.setState({ isPreviewImage: false, previewImage: undefined })
          }
          style={{ width: '80%' }}
          footer={[]}
          className="col center"
        >
          {this.state.previewImage && (
            <img
              src={
                this.state.previewImage.mediaURL ||
                URL.createObjectURL(this.state.previewImage)
              }
              alt="img"
              style={{ maxHeight: '69vh', maxWidth: '100%' }}
            />
          )}
        </Modal>
      </div>
    );
  }
}

UploadProductConfigurationImages.propTypes = {
  productConfigurationId: PropTypes.string,
  productConfigurationMedia: PropTypes.array,
  reloadProductConfigurationMedia: PropTypes.func,
};

export default UploadProductConfigurationImages;
