import displayError from '../components/Errors/DisplayErrorMessage';

const ls = require('local-storage');
const axios = require('axios');

export const fetchOrders = async (subordersIds, companyId) => {
  let url = `${ls.get('gatewayAPI')}/order-service-api/order`;

  if (companyId) url += `?companyId=${companyId}`;
  if (subordersIds && subordersIds.length > 0)
    url += `${companyId ? '&' : '?'}subordersIds=${JSON.stringify(
      subordersIds,
    )}`;
  const headers = { Authorization: `Bearer ${ls.get('authTokenCompany')}` };
  try {
    const result = await axios({ method: 'get', url, headers });
    const orders = result.data;
    return orders;
  } catch (e) {
    displayError('Error recuperando Ordenes', e);
  }
  return [];
};
export const fetchSuborders = async (
  page,
  pageSize,
  companyId,
  statusNames,
  createdBefore,
  createdAfter,
  companyOrderNumber,
) => {
  let url = `${ls.get('gatewayAPI')}/order-service-api/suborder`;
  url += `?page=${page}&pageSize=${pageSize}`;
  if (companyId) url += `&companyId=${companyId}`;
  if (statusNames && statusNames.length > 0)
    url += `&statusNames=${JSON.stringify(statusNames)}`;
  if (createdAfter) url += `&createdAfter=${createdAfter.toISOString()}`;
  if (createdBefore) url += `&createdBefore=${createdBefore.toISOString()}`;
  if (companyOrderNumber) url += `&companyOrderNumber=${companyOrderNumber}`;
  const headers = { Authorization: `Bearer ${ls.get('authTokenCompany')}` };
  try {
    const result = await axios({ method: 'get', url, headers });
    const suborders = result.data;
    return { suborders, total: result.headers['pagination-total'] };
  } catch (e) {
    displayError('Error recuperando subordenes', e);
  }
  return { suborders: [], total: 0 };
};
