const ls = require('local-storage');
const axios = require('axios');

export const refreshAuthToken = async () => {
  const refreshToken = ls.get('refreshTokenCompany');
  const url = `${ls.get('gatewayAPI')}/identity-service-api/auth/renew-token`;
  try {
    return axios({ method: 'post', data: { refreshToken }, url });
  } catch (e) {
    console.error('Error :>> ', e);
  }
};
