import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  InboxOutlined,
  LoadingOutlined,
  SearchOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import {
  Upload,
  Button,
  Modal,
  message,
  notification,
  Select,
  Col,
  Row,
  Checkbox,
  Input,
} from 'antd';
import displayErrorMessage from '../Errors/DisplayErrorMessage';
import {
  XLSToHashImageNamesVsreferences,
  XLSToHashGeneralReferenceVsUniqueReferences,
  getHeaders,
} from './XMLToHashRefGVSImageNames';

const { Dragger } = Upload;
const { Option } = Select;

const ls = require('local-storage');
const axios = require('axios');

class UploadImages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      catalogs: [],
      imageList: [],
      imageLoader: false,
      imagesWithErrors: [],
      separator: '#',
    };
    this.beforeUpload = this.beforeUpload.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.onPreview = this.onPreview.bind(this);
    this.addImages = this.addImages.bind(this);
    this.addImagesFromFile = this.addImagesFromFile.bind(this);
    this.loadReferencesVsNames = this.loadReferencesVsNames.bind(this);
    this.loadGeneralReferencesVsUniqueReferences = this.loadGeneralReferencesVsUniqueReferences.bind(
      this,
    );
  }

  onRemove(uid) {
    this.setState({ imageLoader: true });
    this.setState((prevState) => {
      const imageListAux = prevState.imageList;
      for (let i = 0; i < imageListAux.length; i += 1) {
        const currentFile = imageListAux[i];
        if (currentFile.uid === uid) {
          imageListAux.splice(i, 1);
          break;
        }
      }
      return { imageList: imageListAux, totalImages: imageListAux.length };
    });
    this.setState({ imageLoader: false });
    return true;
  }

  onPreview(media) {
    this.setState({ isPreviewImage: true, previewImage: media });
  }

  async beforeUpload(file, fileList) {
    const currentFile = file;
    const isJpgOrPng =
      currentFile.type === 'image/jpeg' || currentFile.type === 'image/png';
    if (!isJpgOrPng) {
      notification.error({
        message: `Imagen con error`,
        duration: 0,
        description: `La imagen ${currentFile.name} debe ser .jpg o .png`,
      });
      for (let i = 0; i < fileList.length; i += 1) {
        const element = fileList[i];
        if (element.name === file.name) {
          fileList.splice(i, 1);
        }
      }
      this.setState({ imageLoader: true });
    } else {
      this.setState((prevState) => {
        const { imageList } = prevState;
        let { imageLoader } = prevState;
        imageList.push(file);
        if (imageList.length === fileList.length) {
          imageLoader = false;
        } else {
          imageLoader = true;
        }
        return {
          imageList,
          imageLoader,
          totalImages: fileList.length,
          imagesWithErrors: [],
        };
      });
    }
  }

  async fetchCatalogs() {
    const url = `${ls.get('gatewayAPI')}/company-service-api/company/catalogs`;
    const headers = { Authorization: `Bearer ${ls.get('authTokenCompany')}` };
    try {
      const result = await axios({ method: 'get', url, headers });
      const catalogsRaw = result.data === '' ? [] : result.data;
      const catalogs = [];
      for (let i = 0; i < catalogsRaw.length; i += 1) {
        if (catalogsRaw[i].allowOrders) {
          catalogs.push(catalogsRaw[i]);
        }
      }
      this.setState({ catalogs });
    } catch (e) {
      displayErrorMessage('Error recuperando catálogos', e);
    }
  }

  async addImages() {
    this.setState({ addingImages: true });
    const imagesWithErrors = [];
    const imagesUploadedCorrectly = [];
    const imageListCopy = [...this.state.imageList];
    for (let i = 0; i < imageListCopy.length; i += 1) {
      let removeImageFromList = true;
      const image = imageListCopy[i];
      const { name } = image;
      let reference = '';
      let id = '';
      const refGVsName = this.state.refGVSNamesHash
        ? this.state.refGVSNamesHash[name]
        : undefined;

      const { separator } = this.state;
      let refGFromRefUHash;
      if (this.state.refGVSRefU) {
        refGFromRefUHash = this.state.refGVSRefU[
          name.includes(separator) ? name.split(separator)[0] : ''
        ];
      }

      if (refGVsName && refGVsName.length > 0) {
        const current = refGVsName.shift();
        reference = current.refG;
        id = current.id;
        i -= 1;
        removeImageFromList = false;
      } else if (refGFromRefUHash && refGFromRefUHash.length > 0) {
        reference = refGFromRefUHash;
        [, id] = name.split(separator);
      } else if (name.includes(separator)) {
        [reference, id] = name.split(separator);
      } else {
        [reference] = name.split('.');
        id = '1.';
      }

      reference = reference.trim();
      let url = `${ls.get('gatewayAPI')}/company-service-api`;
      url += `/catalog/${this.state.catalogId}/productImage`;
      const headers = {
        Authorization: `Bearer ${ls.get('authTokenCompany')}`,
        'Content-Type': 'multipart/form-data',
      };

      const data = new FormData();
      data.append('file', image);
      data.append('reference', reference);
      data.append('isCoverImage', id.startsWith('1.') || id.startsWith('1)'));

      try {
        // eslint-disable-next-line no-await-in-loop
        await axios({ method: 'post', url, headers, data });
        imagesUploadedCorrectly.push(imageListCopy[i]);
        if (removeImageFromList) {
          this.onRemove(image.uid);
        }
      } catch (e) {
        const error = e.response?.data?.message;
        imagesWithErrors.push({ name: imageListCopy[i].name, error });
      }
    }
    if (imagesUploadedCorrectly.length > 0) {
      notification.success({
        message: `Imagenenes salvadas correctamente`,
        duration: 0,
        description: `Se agregaron correctamente  ${imagesUploadedCorrectly.length} imagenes a sus productos`,
      });
    }
    if (imagesWithErrors.length > 0) {
      notification.error({
        message: `Imagenenes que no se pudieron guardar: ${imagesWithErrors.length}`,
        duration: 0,
        description: `Revisar que las referencias existan en el catalogo seleccionado: ${imagesWithErrors.map(
          (image) => (image ? `${image.name}\n` : ''),
        )}`,
      });
    }
    this.setState({ addingImages: false, imagesWithErrors });
  }

  async addImagesFromFile() {
    this.setState({ addingImages: true });
    const imagesWithErrors = [];
    let promises = [];
    const { refGVSNamesHash } = this.state;
    const keys = Object.keys(refGVSNamesHash);
    for (let i = 0; i < keys.length; i += 1) {
      const { id } = refGVSNamesHash[keys[i]];
      let sourceImageUrl = keys[i];
      if (sourceImageUrl.trim() !== '') {
        if (!sourceImageUrl.startsWith('http')) {
          sourceImageUrl = `http://${sourceImageUrl}`;
        }
        const data = {
          reference: refGVSNamesHash[keys[i]].refG,
          sourceImageUrl,
          isCoverImage: id.startsWith('1.') || id.startsWith('1)'),
        };
        const promise = this.uploadImageToBackend(data, imagesWithErrors);
        promises.push(promise);
      }

      if (i % 50 === 0) {
        // eslint-disable-next-line no-await-in-loop
        await Promise.all(promises);
        promises = [];
      }
    }
    await Promise.all(promises);
    if (imagesWithErrors.length > 0) {
      notification.error({
        message: `Imagenenes que no se pudieron guardar: ${imagesWithErrors.length}`,
        duration: 0,
        description: `Revisar que las referencias existan en el catalogo seleccionado: ${imagesWithErrors.map(
          (image) => (image ? `${image.name}\n` : ''),
        )}`,
      });
    }
    this.setState({ addingImages: false });
  }

  async uploadImageToBackend(data, imagesWithErrors) {
    let url = `${ls.get('gatewayAPI')}/company-service-api`;
    url += `/catalog/${this.state.catalogId}/product/productMedia`;
    const headers = { Authorization: `Bearer ${ls.get('authTokenCompany')}` };
    try {
      // eslint-disable-next-line no-await-in-loop
      await axios({ method: 'post', url, headers, data });
    } catch (e) {
      imagesWithErrors.push(data.sourceImageUrl);
    }
  }

  headers(headers, file) {
    const headersSelector = [];
    for (let i = 0; i < headers.length; i += 1) {
      headersSelector.push({
        key: i.toString(),
        label: `${headers[i]}`,
        value: `${headers[i]}`,
      });
    }
    this.setState({ headersSelector, headers, file });
  }

  loadReferencesVsNames() {
    const headers = [this.state.refG];
    if (!this.state.refG) {
      message.error('Debes añadir la Referencia general.');
    } else {
      const variantHeaders = [];
      const { checkedValues } = this.state;
      for (let i = 0; checkedValues && i < checkedValues.length; i += 1) {
        variantHeaders.push(checkedValues[i]);
      }
      XLSToHashImageNamesVsreferences(
        this.state.file,
        headers,
        variantHeaders,
      ).then((result) => {
        if (result.errors.length === 0) {
          this.setState({ refGVSNamesHash: result.refGVSNamesHash }, () =>
            notification.success({
              message: `Todo listo para subir las imagenes`,
              duration: 10,
            }),
          );
        } else {
          notification.error({
            message: `Problema al crear la tabla`,
            duration: 0,
            description: result.errors.join(','),
          });
        }
      });
    }
  }

  loadGeneralReferencesVsUniqueReferences() {
    const headers = [this.state.refG, this.state.refU];
    if (!this.state.refG || !this.state.refU) {
      message.error('Debes añadir la Referencia general y unica');
    } else {
      XLSToHashGeneralReferenceVsUniqueReferences(
        this.state.file,
        headers,
      ).then((result) => {
        if (result.errors.length === 0) {
          this.setState({ refGVSRefU: result.refGVSRefU }, () =>
            notification.success({
              message: `Todo listo para subir las imagenes`,
              duration: 10,
            }),
          );
        } else {
          notification.error({
            message: `Problema al crear la tabla`,
            duration: 0,
            description: result.errors.join(',\n'),
          });
        }
      });
    }
  }

  componentDidMount() {
    this.fetchCatalogs();
  }

  render() {
    return (
      <div style={{ width: '100%' }}>
        <button
          type="button"
          className="button secondary center"
          onClick={() => this.setState({ visible: true })}
          style={{ width: '100%' }}
        >
          <ion-icon
            name="cloud-upload-outline"
            style={{ height: '20px', width: '20px', marginRight: '5px' }}
          ></ion-icon>
          Subir imágenes
        </button>
        <Modal
          title="Sube imagenes en masa a los productos"
          visible={this.state.visible}
          onCancel={() => {
            this.setState({
              visible: false,
              imageList: [],
              imagesWithErrors: [],
            });
            this.props.reload();
          }}
          width="80%"
          footer={[
            <Button
              key="save"
              type="primary"
              onClick={this.addImages}
              loading={this.state.addingImages}
              disabled={
                this.state.imageList.length === 0 || !this.state.catalogId
              }
            >
              Guardar imagenes
            </Button>,
            <Button
              key="saveFromFile"
              type="primary"
              onClick={this.addImagesFromFile}
              loading={this.state.addingImages}
              disabled={!this.state.refGVSNamesHash || !this.state.catalogId}
            >
              Guardar desde archivo
            </Button>,
            <Button
              key="cancel"
              onClick={() => {
                this.setState({
                  visible: false,
                  imageList: [],
                  imagesWithErrors: [],
                });
                this.props.reload();
              }}
            >
              Cancelar
            </Button>,
          ]}
        >
          <div>
            <div style={{ fontSize: '1.5em' }}>
              {this.state.imageList.length === this.state.totalImages && (
                <div>Imagenes cargadas: {this.state.imageList.length}</div>
              )}
              {this.state.imageList.length !== this.state.totalImages && (
                <div>
                  Procesando {this.state.imageList.length}/
                  {this.state.totalImages}
                </div>
              )}
            </div>
            <br />
            <Row gutter={24}>
              <Col span={12}>
                <Select
                  onChange={(e) => this.setState({ catalogId: e })}
                  onSelect={(e) => this.setState({ catalogId: e })}
                  placeholder="Selecciona el catálogo de este producto"
                  value={this.state.catalogId}
                >
                  {this.state.catalogs.map((item) => (
                    <Option value={item.id} key={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Col>
              <Col span={4}>
                <Row gutter={24}>
                  <Col span={15}>Separador:</Col>
                  <Col span={9}>
                    <Input
                      onChange={(e) =>
                        this.setState({ separator: e.target.value })
                      }
                      value={this.state.separator}
                    />
                  </Col>
                </Row>
              </Col>
              <br />
              <br />
              <Col span={8}>
                {!this.state.headers && (
                  <Upload
                    accept=".xlsx"
                    showUploadList={false}
                    beforeUpload={(file) => {
                      getHeaders(file)
                        .then((res) => this.headers(res, file))
                        .catch((err) => message.error(err));
                      return false;
                    }}
                  >
                    <Button>
                      <UploadOutlined /> Cargar tabla de traducción a Ref G
                    </Button>
                  </Upload>
                )}
                {this.state.headers && !this.state.refGVSNamesHash && (
                  <>
                    <Select
                      placeholder="Referencia general"
                      style={{ width: 200 }}
                      onChange={(value) => this.setState({ refG: value })}
                      value={this.state.refG}
                    >
                      {this.state.headers.map((header) => (
                        <Option key={header} value={header}>
                          {header}
                        </Option>
                      ))}
                    </Select>
                    <br />
                    <Checkbox.Group
                      options={this.state.headersSelector}
                      onChange={(checkedValues) =>
                        this.setState({ checkedValues })
                      }
                    />
                    <br />
                    <Button
                      type="primary"
                      onClick={this.loadReferencesVsNames}
                      loading={this.state.loadingReferencesVsNames}
                    >
                      Crear Mapa RefG vs Nombre de fotos
                    </Button>
                    <br />
                    <br />
                    <Select
                      placeholder="Referencia unica"
                      style={{ width: 200 }}
                      onChange={(value) => this.setState({ refU: value })}
                      value={this.state.refU}
                    >
                      {this.state.headers.map((header) => (
                        <Option key={header} value={header}>
                          {header}
                        </Option>
                      ))}
                    </Select>
                    <br />
                    <Button
                      type="primary"
                      onClick={this.loadGeneralReferencesVsUniqueReferences}
                      loading={this.state.loadingReferencesVsNames}
                    >
                      Crear Mapa Ref general vs Ref Unica
                    </Button>
                  </>
                )}
              </Col>
            </Row>

            <div style={{ width: '100%', height: '100%', marginTop: '2em' }}>
              <Dragger
                multiple
                directory
                style={{ marginBottom: '1em' }}
                beforeUpload={this.beforeUpload}
                showUploadList={false}
              >
                <p className="ant-upload-drag-icon">
                  {!this.state.imageLoader ? (
                    <InboxOutlined />
                  ) : (
                    <LoadingOutlined />
                  )}
                </p>
                Dale click o arrastra la carpeta con las imagenes a subir
              </Dragger>
              {this.state.imagesWithErrors.length > 0 && (
                <div
                  style={{
                    margin: '0 10%',
                    padding: '4% 8%',
                    border: 'solid red',
                    textAlign: 'left',
                  }}
                >
                  <ul>
                    {this.state.imagesWithErrors.map((image) => (
                      <li key={image.name}>
                        Referencia: {image.name}. Error: {image.error}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              <Row gutter={24}>
                {this.state.imageList.map((media) => (
                  <Col span={2} key={media.uid} style={{ margin: '1em 1em' }}>
                    <Row gutter={24}>
                      <Col span={12}>
                        <button
                          type="button"
                          onClick={() => this.onPreview(media)}
                          style={{
                            border: 'none',
                            background: 'white',
                          }}
                        >
                          <SearchOutlined />
                        </button>
                      </Col>
                      <Col span={12}>
                        <div style={{ textAlign: 'right' }}>
                          <button
                            type="button"
                            onClick={() => this.onRemove(media.uid)}
                            style={{
                              fontSize: '0.8em',
                              borderRadius: '100%',
                              background: 'white',
                            }}
                          >
                            X
                          </button>
                        </div>
                      </Col>
                    </Row>
                    <img
                      src={URL.createObjectURL(media)}
                      alt="img"
                      style={{ width: '80px', height: '100px' }}
                    />
                    {media.name}
                  </Col>
                ))}
              </Row>
            </div>
          </div>
        </Modal>
        <Modal
          title="Preview"
          visible={this.state.isPreviewImage}
          onCancel={() =>
            this.setState({ isPreviewImage: false, previewImage: undefined })
          }
          width="80%"
          footer={[]}
        >
          <img
            src={
              this.state.previewImage
                ? URL.createObjectURL(this.state.previewImage)
                : ''
            }
            alt="img"
          />
        </Modal>
      </div>
    );
  }
}

UploadImages.propTypes = {
  reload: PropTypes.func.isRequired,
};

export default UploadImages;
