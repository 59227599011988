import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

class Logout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    this.props.logout();
    return <Redirect to="/login" />;
  }
}

Logout.propTypes = {
  logout: PropTypes.func,
};

export default Logout;
