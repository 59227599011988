import React from 'react';
import { Divider, notification } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import './login.scss';
import flipcatLoginLogo from '../../assets/flipcat_login_logo.svg';

const axios = require('axios');
const ls = require('local-storage');

class WebView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
    };
    this.login = this.login.bind(this);
  }

  displayError(errorMessage) {
    let message = errorMessage;
    if (errorMessage === 'Incorrect password') {
      message = 'Contraseña incorrecta';
    } else if (errorMessage === 'Email not found') {
      message = 'El correo ingresado no correspone a una cuenta registrada';
    }
    notification.error({
      message: `Ocurrio un problema`,
      duration: 0,
      description: `${message}`,
    });
  }

  async login() {
    this.setState({ loading: true });
    const data = { email: this.state.email, password: this.state.password };
    const url = `${ls.get('gatewayAPI')}/identity-service-api/auth/login/`;
    const headers = { Authorization: `Bearer ${ls.get('authTokenCompany')}` };
    try {
      const result = await axios({ method: 'post', url, data, headers });
      this.props.login(result.data.accessToken, result.data.refreshToken);
    } catch (e) {
      this.displayError(e.response.data.message);
    } finally {
      this.setState({ loading: false });
    }

  }

  render() {
    return (
      <div className="login__grid">
        <div className="login__column">
          <div className="col center no-padding" style={{ width: '100%' }}>
            <div
              style={{
                fontFamily: 'Lato',
                fontWeight: 'bold',
                fontSize: '30px',
                textAlign: 'left',
                lineHeight: '35px',
                color: '#1A002D',
              }}
            >
              Iniciar sesión
            </div>
            <div style={{ width: '27%' }}>
              <Divider style={{ borderTop: '1.5px solid #06C2AC' }} />
            </div>
            <br />
            <div className="row center" style={{ width: '100%' }}>
              <div
                className="col no-padding"
                style={{ maxWidth: '335px', width: '100%' }}
              >
                <div className="login__label">Correo electrónico</div>
                <input
                  className="large"
                  type="email"
                  placeholder="Ingresa tu correo"
                  onChange={(e) => this.setState({ email: e.target.value })}
                />
              </div>
            </div>
            <div
              className="row center"
              style={{ width: '100%', marginBottom: '40px' }}
            >
              <div
                className="col no-padding"
                style={{ maxWidth: '335px', width: '100%' }}
              >
                <div className="login__label">Contraseña</div>
                <div className="password__input">
                  <input
                    placeholder="Ingresa tu contraseña"
                    className="large"
                    type={this.state.showPassword ? 'text' : 'password'}
                    onChange={(e) =>
                      this.setState({ password: e.target.value })
                    }
                  />
                  <div className="show__icon">
                    <ion-icon
                      name={
                        !this.state.showPassword
                          ? 'eye-outline'
                          : 'eye-off-outline'
                      }
                      onClick={() =>
                        this.setState((prevState) => ({
                          showPassword: !prevState.showPassword,
                        }))
                      }
                      style={{ width: '20px', height: '20px' }}
                    ></ion-icon>
                  </div>
                </div>
              </div>
            </div>
            <button
              type="button"
              className={`button linear-button large ${
                this.state.loading && 'disabled'
              }`}
              onClick={this.login}
            >
              {this.state.loading && (
                <LoadingOutlined style={{ marginRight: '10px' }} />
              )}
              Ingresar
            </button>
          </div>
        </div>
        <div className="login__column image__container">
          <img src={flipcatLoginLogo} alt="" />
        </div>
      </div>
    );
  }
}

WebView.propTypes = {
  login: PropTypes.func,
};

export default WebView;
