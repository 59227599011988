import React from 'react';
import PropTypes from 'prop-types';
import { Col, Button, Row } from 'antd';
import { PaperClipOutlined, DeleteOutlined } from '@ant-design/icons';
import UpsertContentModal from './UpsertContentModal';

class Content extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  render() {
    const { title } = this.props.content;
    return (
      <div>
        <Row gutter={24} style={{ marginTop: '1em' }}>
          <Col span={16}>
            <Button
              loading={this.state.loading}
              block
              type="text"
              style={{
                background: '#F5F5F5',
                textAlign: 'left',
                color: '#1A002D',
              }}
              shape="round"
              icon={<PaperClipOutlined />}
            >
              {title}
            </Button>
          </Col>
          <Col span={6}>
            <UpsertContentModal
              handleReload={this.props.handleReload}
              content={this.props.content}
            />
          </Col>
          <Col span={2}>
            <Button
              loading={this.state.loading}
              onClick={() => this.props.removeContent(this.props.contentIndex)}
              type="text"
              style={{ background: '#A10C0C', color: 'white' }}
              shape="round"
              icon={<DeleteOutlined />}
            ></Button>
          </Col>
        </Row>
      </div>
    );
  }
}

Content.propTypes = {
  content: PropTypes.object.isRequired,
  handleReload: PropTypes.func.isRequired,
  removeContent: PropTypes.func.isRequired,
  contentIndex: PropTypes.number,
};

export default Content;
