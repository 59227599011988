import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Switch, Modal } from 'antd';
import {
  createInventory,
  fetchInventory,
  updateInventory,
} from '../../gateway/InventoryServices';
import OverlayLoader from '../../components/OverlayLoader';
import LoadingCard from '../../components/LoadingCard';
import EditProductConfiguration from './EditProductConfiguration';
import { updateProductConfiguration } from './actions';
import UploadProductConfigurationImages from './UploadProductConfigurationImages';

let keyForVariants = 0;

function ProductConfiguration({
  productConfiguration,
  setLoading,
  fetchData,
  warehouses,
  companyId,
  handleProductConfigurationChange,
  reloadProductConfigurationMedia,
}) {
  const [showInventories, setShowInventories] = useState(false);
  const [showProductConfigurationImages, setShowProductConfigurationImages] =
    useState(false);
  const [showEditOfProductConfiguration, setShowEditOfProductConfiguration] =
    useState(false);
  const [inventoryHash, setInventoryHash] = useState(null);
  const [updatingInventary, setUpdatingInventary] = useState(false);

  const handleStockChange = (e, warehouseId) => {
    const newInventoryHash = { ...inventoryHash };
    const newStock = parseInt([e.target.value], 0);
    if (newInventoryHash[warehouseId].inventory === undefined) {
      newInventoryHash[warehouseId].inventory = {
        stock: newStock,
        ownerId: companyId,
        productConfigurationId: productConfiguration.id,
        isPendingToCreate: true,
      };
    } else {
      newInventoryHash[warehouseId].inventory.stock = newStock;
    }
    setInventoryHash(newInventoryHash);
  };

  const fetchInventories = async () => {
    const { inventory } = await fetchInventory(
      0,
      20,
      undefined,
      undefined,
      undefined,
      undefined,
      JSON.stringify([productConfiguration.id]),
    );
    const inventoryHashData = {};
    warehouses.forEach((warehouse) => {
      const inv = inventory.find((i) => i?.warehouse?.id === warehouse.id);
      inventoryHashData[warehouse.id] = { inventory: inv, warehouse };
    });
    setInventoryHash(inventoryHashData);
  };

  const translateVATtype = (type) => {
    if (type === 'TAXABLE') return 'GRAVABLE';
    if (type === 'EXEMPT') return 'EXENTO';
    if (type === 'EXCLUDED') return 'EXCLUIDO';
    return '';
  };

  const dispatchEditOfProdConfig = async (
    newProductConfiguration,
    dimensions = [],
  ) => {
    try {
      setLoading(true);
      const [productToAdd, productToDelete] = dimensions.reduce(
        (accum, item) => {
          const { value, name, id, action } = item;
          if (action === 'insert' || action === 'update')
            accum[0].push({ value, name });
          if (action === 'update' || action === 'delete') accum[1].push(id);
          return accum;
        },
        [[], []],
      );
      setShowEditOfProductConfiguration(false);
      await updateProductConfiguration(
        newProductConfiguration,
        productToAdd,
        productToDelete,
      );
      await fetchData();
    } catch (e) {
      console.log('e :>> ', e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="col">
      <div className="productConfigLayout">
        <div className="col gap no-padding">
          <div className="row no-padding" style={{ columnGap: '2.5em' }}>
            <div className="col">
              <h5 className="property_name">Variante</h5>
              <div className="property_value">
                <div className="col no-padding">
                  {productConfiguration.variantDisplay.map((variantType) => (
                    // eslint-disable-next-line no-plusplus
                    <span key={keyForVariants++}>{variantType}</span>
                  ))}
                </div>
              </div>
            </div>
            <div
              className="col center"
              style={{ marginTop: 0, marginBottom: 'auto' }}
            >
              <h5 className="property_name">Disponible</h5>
              <div className="property_value">
                <Switch
                  style={{ width: '3em' }}
                  checked={productConfiguration.isAvailable}
                  onChange={(e) => {
                    handleProductConfigurationChange(productConfiguration.id, {
                      target: { name: 'isAvailable', value: e },
                    });
                    dispatchEditOfProdConfig({
                      ...productConfiguration,
                      isAvailable: e,
                    });
                  }}
                />
              </div>
            </div>
            <div className="col" style={{ marginTop: 0, marginBottom: 'auto' }}>
              <h5 className="property_name">Referencia</h5>
              <div className="property_value">
                <span>{productConfiguration.reference}</span>
              </div>
            </div>
          </div>
          <div className="row no-padding" style={{ columnGap: '2.5em' }}>
            <div className="col">
              <h5>Precio base</h5>
              <span>$ {productConfiguration.basePrice}</span>
            </div>
            <div className="col">
              <h5>Precio publico</h5>
              <span>$ {productConfiguration.retailPrice}</span>
            </div>
            <div className="col">
              <h5>Tipo de IVA</h5>
              <span>
                {translateVATtype(productConfiguration.taxClassification.name)}
              </span>
            </div>
            <div className="col">
              <h5>Impuesto %</h5>
              <span>{productConfiguration.vatPercentage} %</span>
            </div>
          </div>
          {!showInventories ? (
            <button
              type="button"
              className="link-button"
              style={{ color: '#1A002D', textDecoration: 'underline' }}
              onClick={async () => {
                setShowInventories(true);
                await fetchInventories();
              }}
            >
              <ion-icon
                name="chevron-down-outline"
                style={{ marginRight: '0.5em' }}
              ></ion-icon>
              Ver Inventarios
            </button>
          ) : (
            <button
              type="button"
              className="link-button"
              style={{
                width: '8em',
                color: '#1A002D',
                textDecoration: 'underline',
              }}
              onClick={() => {
                setShowInventories(false);
                setInventoryHash(null);
              }}
            >
              <ion-icon
                name="chevron-up-outline"
                style={{ marginRight: '0.5em' }}
              ></ion-icon>
              Ocultar
            </button>
          )}
        </div>
        <div className="col gap no-padding">
          <button
            className="button secondary"
            style={{
              minWidth: '6em',
            }}
            type="button"
            onClick={() => setShowEditOfProductConfiguration(true)}
          >
            <ion-icon
              name="create-outline"
              style={{ marginRight: '5px' }}
            ></ion-icon>
            Editar
          </button>

          <button
            type="button"
            onClick={() => setShowProductConfigurationImages(true)}
            className="button secondary"
            style={{ height: '3em' }}
          >
            Imagenes
          </button>
        </div>
      </div>

      {showInventories &&
        (inventoryHash === null ? (
          <LoadingCard message="Cargando Inventarios..." />
        ) : (
          warehouses.map((warehouse) => (
            <div
              style={{
                position: 'relative',
                backgroundColor: 'gainsboro',
                borderRadius: '10px',
              }}
              key={warehouse.name}
            >
              {updatingInventary ? <OverlayLoader /> : null}
              <hr />
              <div className="row center gap no-padding">
                <div
                  className="col"
                  style={{ marginTop: 0, marginBottom: 'auto' }}
                >
                  <h4>Bodega</h4>
                  <span style={{ paddingTop: '10px' }}>{warehouse.name}</span>
                </div>
                <div className="col">
                  <h4>Cantidad</h4>
                  <input
                    type="number"
                    value={inventoryHash[warehouse.id].inventory?.stock || 0}
                    name="stock"
                    style={{ width: '5em' }}
                    onChange={(e) => handleStockChange(e, warehouse.id)}
                  />
                </div>
                <div className="col">
                  <h4>Modificar Inventario</h4>
                  <button
                    type="button"
                    className="button"
                    onClick={async () => {
                      setUpdatingInventary(true);
                      if (
                        inventoryHash[warehouse.id].inventory.isPendingToCreate
                      ) {
                        await createInventory(warehouse.id, [
                          inventoryHash[warehouse.id].inventory,
                        ]);
                        const newState = { ...inventoryHash };
                        newState[
                          warehouse.id
                        ].inventory.isPendingToCreate = false;
                        setInventoryHash(newState);
                      } else
                        await updateInventory(warehouse.id, [
                          inventoryHash[warehouse.id].inventory,
                        ]);
                      setUpdatingInventary(false);
                    }}
                  >
                    {!inventoryHash[warehouse.id].inventory ||
                    inventoryHash[warehouse.id].inventory.isPendingToCreate
                      ? 'Crear Inventario'
                      : 'Actualizar Inventario'}
                  </button>
                </div>
              </div>
              <hr />
            </div>
          ))
        ))}
      <hr />
      <Modal
        visible={showProductConfigurationImages}
        onCancel={() => setShowProductConfigurationImages(false)}
        footer={null}
        title={null}
        width="50%"
      >
        <UploadProductConfigurationImages
          productConfigurationId={productConfiguration.id}
          productConfigurationMedia={
            productConfiguration.productConfigurationMedia
          }
          fetchData={fetchData}
          reloadProductConfigurationMedia={reloadProductConfigurationMedia}
        />
      </Modal>
      <Modal
        visible={showEditOfProductConfiguration}
        footer={null}
        onCancel={() => setShowEditOfProductConfiguration(false)}
      >
        <EditProductConfiguration
          productConfigurationToEdit={productConfiguration}
          dispatchEditOfProdConfig={dispatchEditOfProdConfig}
          showEditPC={showEditOfProductConfiguration}
        />
      </Modal>
    </div>
  );
}

ProductConfiguration.propTypes = {
  productConfiguration: PropTypes.object,
  setLoading: PropTypes.func,
  fetchData: PropTypes.func,
  handleProductConfigurationChange: PropTypes.func,
  warehouses: PropTypes.array,
  companyId: PropTypes.string,
  reloadProductConfigurationMedia: PropTypes.func,
};

export default ProductConfiguration;
