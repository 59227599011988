import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'antd';
import { Link } from 'react-router-dom';

const { Meta } = Card;

class CompanyCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Card
        hoverable
        style={{ width: 250 }}
        cover={
          <img
            style={{ height: '19em' }}
            alt={this.props.item.name}
            src={this.props.item.imageURL}
          />
        }
      >
        <Meta title={this.props.item.name} />
        <br />
        <div>
          <div>
            Fecha inicio:{' '}
            {this.props.item.openingDate
              ? this.props.item.openingDate.split('T')[0]
              : ''}
          </div>
          <p>
            Fecha fin:{' '}
            {this.props.item.closingDate
              ? this.props.item.closingDate.split('T')[0]
              : ''}
          </p>
          <p>
            Estado:{' '}
            <strong>{this.props.item.isPublic ? 'Público' : 'Privado'}</strong>
          </p>
          <p>
            Permite órdenes:{' '}
            <strong>{this.props.item.allowOrders ? 'Si' : 'No'}</strong>
          </p>
          <a href={this.props.item.linkURL}>Link catálogo interactivo</a>
          <br />
          <br />
          <Link
            to={{
              pathname: `/catalogos/${this.props.item.id}`,
              desiredCatalog: this.props.item,
            }}
          >
            Editar
          </Link>
        </div>
      </Card>
    );
  }
}

CompanyCard.propTypes = {
  item: PropTypes.object,
};

export default CompanyCard;
