import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Form, Input, InputNumber } from 'antd';
import { PlusSquareOutlined } from '@ant-design/icons';
import displayErrorMessage from '../Errors/DisplayErrorMessage';

const ls = require('local-storage');
const axios = require('axios');

class CreateBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      loadingOnCreateBox: false,
    };
    this.createBox = this.createBox.bind(this);
  }

  showModal = () => this.setState({ visible: true });

  handleCancel = () => this.setState({ visible: false });

  handleOk = (values) => {
    const box = {
      name: values.name,
      maxItems: values.maxItems,
      maxWeight: values.maxWeight,
      length: values.length,
      width: values.width,
      height: values.height,
      sameCityPrice: values.sameCityPrice,
      differentCityPrice: values.differentCityPrice,
    };
    this.createBox(box);
    this.setState({ visible: false });
  };

  async createBox(box) {
    this.setState({ loadingOnCreateBox: true });
    const url = `${ls.get('gatewayAPI')}/company-service-api/shipping-box`;
    const headers = { Authorization: `Bearer ${ls.get('authTokenCompany')}` };
    try {
      await axios({ method: 'post', url, headers, data: box });
      this.props.reload();
    } catch (e) {
      displayErrorMessage('Error creando las cajas', e);
    }
    this.setState({ loadingOnCreateBox: false });
  }

  render() {
    return (
      <div>
        <Button
          style={{
            paddingTop: '1em',
            paddingBottom: '3em',
            color: '#646464',
            borderColor: '#646464',
            backgroundColor: 'white',
          }}
          type="primary"
          onClick={this.showModal}
          loading={this.state.loadingOnCreateBox}
        >
          <div>
            <PlusSquareOutlined />
            Crear
          </div>
        </Button>
        <br />
        <Modal
          title="Ingresa los datos de tu caja"
          visible={this.state.visible}
          onCancel={this.handleCancel}
          footer={[
            <Button key="cancel" onClick={this.handleCancel}>
              Cancelar
            </Button>,
          ]}
        >
          <Form onFinish={this.handleOk} className="Profile-form">
            <Form.Item label="Nombre" name="name">
              <Input />
            </Form.Item>
            <Form.Item label="Maximo número de articulos" name="maxItems">
              <InputNumber />
            </Form.Item>
            <Form.Item label="Maximo peso (KG)" name="maxWeight">
              <InputNumber />
            </Form.Item>
            <Form.Item label="Dimensiones de la caja: Largo (cm)" name="length">
              <InputNumber />
            </Form.Item>
            <Form.Item label="Dimensiones de la caja: Ancho (cm)" name="width">
              <InputNumber />
            </Form.Item>
            <Form.Item label="Dimensiones de la caja: Alto (cm)" name="height">
              <InputNumber />
            </Form.Item>
            <Form.Item label="Precio misma ciudad" name="sameCityPrice">
              <InputNumber
                formatter={(value) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
              />
            </Form.Item>
            <Form.Item label="Precio distinta ciudad" name="differentCityPrice">
              <InputNumber
                formatter={(value) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                style={{ background: '#34a80d', marginRight: '1em' }}
                htmlType="submit"
                loading={this.state.loadingOnCreateBox}
              >
                Crear caja
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
}

CreateBox.propTypes = {
  reload: PropTypes.func.isRequired,
};

export default CreateBox;
