import React from 'react';
import NumberFormat from 'react-number-format';

export function toCurrency(value, colorR) {
  const color = colorR || 'black';
  return (
    <NumberFormat
      value={value}
      displayType="text"
      thousandSeparator
      style={{ color }}
      prefix=" $ "
    />
  );
}
