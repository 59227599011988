import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/browser';
import { store } from './store/store'
import App from './App';

Sentry.init({ 
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_SENTRY_ENV,
  debug: process.env.REACT_APP_SENTRY_DEBUG,
  tracesSampleRate: 1.0
});

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);

console.log('1. Default. Cleaning service worker and caches');
if (navigator.serviceWorker) {
  console.log('Service worker detected.');
  navigator.serviceWorker.getRegistrations().then((registrations) => {
    for (let i = 0; i < registrations.length; i += 1) {
      console.log('Unregistering');
      console.log(registrations[i]);
      registrations[i].unregister();
    }
  });
  caches.keys().then((keyList) =>
    Promise.all(
      keyList.map((key) => {
        console.log('Deleting caché: ');
        console.log(key);
        return caches.delete(key);
      }),
    ),
  );
}
