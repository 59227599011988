import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Row, Col, Input, notification, Checkbox } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import displayErrorMessage from '../../Errors/DisplayErrorMessage';

const ls = require('local-storage');
const axios = require('axios');

class AddCompanyCategory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      isActive: true,
      loadingAddCompanyCategory: false,
    };
    this.createCompanyCategory = this.createCompanyCategory.bind(this);
  }

  async createCompanyCategory() {
    this.setState({ loadingAddCompanyCategory: true });
    const data = {
      name: this.state.name,
      isActive: this.state.isActive,
    };
    const url = `${ls.get('gatewayAPI')}/company-service-api/company-category`;
    const headers = { Authorization: `Bearer ${ls.get('authTokenCompany')}` };
    try {
      await axios({ method: 'post', url, headers, data });
      this.setState({ visible: false });
      this.props.reload();
      notification.success({
        message: `Agrupación añadida`,
        duration: 10,
        description: `Agrupación añadida exitosamente`,
      });
    } catch (e) {
      displayErrorMessage('Error creando agrupación', e);
    }
    this.setState({ loadingAddCompanyCategory: false });
  }

  render() {
    return (
      <div>
        <button
          type="button"
          className="button primary center"
          onClick={() => this.setState({ visible: true })}
        >
          {this.state.loadingAddCompanyCategory ? (
            <LoadingOutlined
              style={{ fontSize: '20px', marginRight: '5px' }}
            ></LoadingOutlined>
          ) : (
            <ion-icon
              name="add-outline"
              style={{ width: '20px', height: '20px', marginRight: '5px' }}
            ></ion-icon>
          )}
          Nueva agrupación
        </button>
        <Modal
          title="Ingresa los datos de tu nueva agrupación"
          visible={this.state.visible}
          onCancel={() => this.setState({ visible: false })}
          width="50%"
          footer={[
            <Button
              key="save"
              type="primary"
              onClick={this.createCompanyCategory}
              loading={this.state.loadingAddCompanyCategory}
              disabled={!this.state.name}
            >
              Guardar
            </Button>,
            <Button
              key="cancel"
              onClick={() => this.setState({ visible: false })}
            >
              Cancelar
            </Button>,
          ]}
        >
          <div>
            <div style={{ fontSize: '1.2em' }}>Datos de la agrupación:</div>
            <br />
            <br />
            <Row gutter={24}>
              <Col span={12}>Nombre</Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <Input
                  onChange={(e) => this.setState({ name: e.target.value })}
                  value={this.state.name}
                  style={{
                    height: 50,
                    borderColor: 'darkGrey',
                    fontSize: '1.2em',
                  }}
                />
              </Col>
              <Col span={12}>
                <Checkbox
                  checked={this.state.isActive}
                  onChange={(e) => {
                    this.setState({ isActive: e.target.checked });
                  }}
                >
                  Agrupación habilitada
                </Checkbox>
              </Col>
            </Row>
          </div>
        </Modal>
      </div>
    );
  }
}

AddCompanyCategory.propTypes = {
  reload: PropTypes.func.isRequired,
};

export default AddCompanyCategory;
