import React from 'react';
import { Tooltip } from 'antd';
import './SideNav.scss';
import { Link } from 'react-router-dom';
import {
  ReadOutlined,
  PlusOutlined,
  DropboxOutlined,
  CodeSandboxOutlined,
  DeleteOutlined,
  ShopOutlined,
  BarChartOutlined,
  SettingOutlined,
  SnippetsOutlined,
  /* RollbackOutlined, */
  LogoutOutlined,
  UpOutlined,
  DownOutlined,
  FileDoneOutlined,
  RocketOutlined,
  FormOutlined,
  BlockOutlined,
  RiseOutlined,
} from '@ant-design/icons';
import flipcatLogoBig from '../../assets/flipcat_plaza_logo.svg';
import shoppingBagIcon from '../../assets/shopping-bag.svg';

class SideNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSideNavCollapsed: true,
      openGroupName: '',
    };
  }

  openMenuGroup(groupName) {
    if (this.state.openGroupName === groupName)
      this.setState({ openGroupName: '' });
    else this.setState({ openGroupName: groupName });
  }

  render() {
    return (
      <div
        className={`sideNav ${
          this.state.isSideNavCollapsed ? 'collapsed' : ''
        } ${this.state.openGroupName === 'packings' && 'scroll'}`}
      >
        <div
          className={`collapseButton ${
            this.state.isSideNavCollapsed && 'collapsed'
          }`}
        >
          <div
            className="icon__container"
            onClick={() =>
              this.setState((prevState) => ({
                isSideNavCollapsed: !prevState.isSideNavCollapsed,
              }))
            }
            aria-hidden="true"
          >
            <ion-icon name="menu-outline" size="large"></ion-icon>
          </div>
          {!this.state.isSideNavCollapsed && (
            <div className="logo__container">
              <img src={flipcatLogoBig} alt="" />
            </div>
          )}
        </div>
        <div className="routeItems__container">
          {/* Stock route item */}
          <div className="routeItem">
            <Link to="/inventarios">
              {this.state.isSideNavCollapsed ? (
                <Tooltip placement="rightTop" title="Inventarios">
                  <div className="icon__container">
                    <img
                      src={shoppingBagIcon}
                      style={{ height: '30px', width: '30px', color: 'white' }}
                      alt=""
                    />
                  </div>
                </Tooltip>
              ) : (
                <div className="icon__container">
                  <img
                    src={shoppingBagIcon}
                    style={{ height: '30px', width: '30px', color: 'white' }}
                    alt=""
                  />
                </div>
              )}

              {!this.state.isSideNavCollapsed && (
                <span className="route__label">Inventarios</span>
              )}
            </Link>
          </div>
          {/* Packings group submenu */}
          <div className="routeSubMenu">
            <div
              className="routeItem"
              onClick={() => this.openMenuGroup('packings')}
              aria-hidden="true"
            >
              {this.state.isSideNavCollapsed ? (
                <Tooltip placement="rightTop" title="Paquetes">
                  <div className="icon__container">
                    <SnippetsOutlined />
                  </div>
                </Tooltip>
              ) : (
                <div className="icon__container">
                  <SnippetsOutlined />
                </div>
              )}
              {!this.state.isSideNavCollapsed && (
                <span className="route__label">
                  Paquetes
                  <span className="openClose__button">
                    {this.state.openGroupName === 'packings' ? (
                      <UpOutlined />
                    ) : (
                      <DownOutlined />
                    )}
                  </span>
                </span>
              )}
            </div>
            <div
              className={`menuGroup ${
                this.state.openGroupName === 'packings' && 'open'
              }`}
            >
              <div className="routeItem">
                <Link to="/paquetes-para-enviar">
                  {this.state.isSideNavCollapsed ? (
                    <Tooltip placement="rightTop" title="Nuevos paquetes">
                      <div className="icon__container">
                        <PlusOutlined />
                      </div>
                    </Tooltip>
                  ) : (
                    <div className="icon__container">
                      <PlusOutlined />
                    </div>
                  )}

                  {!this.state.isSideNavCollapsed && (
                    <span className="route__label">Nuevos paquetes</span>
                  )}
                </Link>
              </div>
              <div className="routeItem">
                <Link to="/paquetes-en-proceso">
                  {this.state.isSideNavCollapsed ? (
                    <Tooltip placement="rightTop" title="Paquetes en proceso">
                      <div className="icon__container">
                        <DropboxOutlined />
                      </div>
                    </Tooltip>
                  ) : (
                    <div className="icon__container">
                      <DropboxOutlined />
                    </div>
                  )}

                  {!this.state.isSideNavCollapsed && (
                    <span className="route__label">Paquetes en proceso</span>
                  )}
                </Link>
              </div>
              <div className="routeItem">
                <Link to="/paquetes-completados">
                  {this.state.isSideNavCollapsed ? (
                    <Tooltip placement="rightTop" title="Paquetes empacados">
                      <div className="icon__container">
                        <CodeSandboxOutlined />
                      </div>
                    </Tooltip>
                  ) : (
                    <div className="icon__container">
                      <CodeSandboxOutlined />
                    </div>
                  )}
                  {!this.state.isSideNavCollapsed && (
                    <span className="route__label">Paquetes empacados</span>
                  )}
                </Link>
              </div>
              <div className="routeItem">
                <Link to="/paquetes-enviados">
                  {this.state.isSideNavCollapsed ? (
                    <Tooltip placement="rightTop" title="Paquetes enviados">
                      <div className="icon__container">
                        <RocketOutlined />
                      </div>
                    </Tooltip>
                  ) : (
                    <div className="icon__container">
                      <RocketOutlined />
                    </div>
                  )}

                  {!this.state.isSideNavCollapsed && (
                    <span className="route__label">Paquetes enviados</span>
                  )}
                </Link>
              </div>
              <div className="routeItem">
                <Link to="/paquetes-entregados">
                  {this.state.isSideNavCollapsed ? (
                    <Tooltip placement="rightTop" title="Paquetes entregados">
                      <div className="icon__container">
                        <FileDoneOutlined />
                      </div>
                    </Tooltip>
                  ) : (
                    <div className="icon__container">
                      <FileDoneOutlined />
                    </div>
                  )}
                  {!this.state.isSideNavCollapsed && (
                    <span className="route__label">Paquetes entregados</span>
                  )}
                </Link>
              </div>
              {/* <div className="routeItem">
                <Link to="/paquetes-devueltos">
                  <div className="icon__container">
                    <RollbackOutlined />
                  </div>
                  {!this.state.isSideNavCollapsed && (
                    <span className="route__label">Paquetes devueltos</span>
                  )}
                </Link>
              </div> */}
              <div className="routeItem">
                <Link to="/paquetes-cancelados">
                  {this.state.isSideNavCollapsed ? (
                    <Tooltip placement="rightTop" title="Paquetes cancelados">
                      <div className="icon__container">
                        <DeleteOutlined />
                      </div>
                    </Tooltip>
                  ) : (
                    <div className="icon__container">
                      <DeleteOutlined />
                    </div>
                  )}

                  {!this.state.isSideNavCollapsed && (
                    <span className="route__label">Paquetes cancelados</span>
                  )}
                </Link>
              </div>
            </div>
          </div>
          {/* Catalogs route item */}
          <div className="routeItem">
            <Link to="/catalogos">
              {this.state.isSideNavCollapsed ? (
                <Tooltip placement="rightTop" title="Catálogos">
                  <div className="icon__container">
                    <ReadOutlined />
                  </div>
                </Tooltip>
              ) : (
                <div className="icon__container">
                  <ReadOutlined />
                </div>
              )}

              {!this.state.isSideNavCollapsed && (
                <span className="route__label">Catálogos</span>
              )}
            </Link>
          </div>
          {/* Products route item */}
          <div className="routeItem">
            <Link to="/productos">
              {this.state.isSideNavCollapsed ? (
                <Tooltip placement="rightTop" title="Productos">
                  <div className="icon__container">
                    <ShopOutlined />
                  </div>
                </Tooltip>
              ) : (
                <div className="icon__container">
                  <ShopOutlined />
                </div>
              )}

              {!this.state.isSideNavCollapsed && (
                <span className="route__label">Productos</span>
              )}
            </Link>
          </div>
          {/* Analytics route item */}
          <div className="routeItem">
            <Link to="/estadisticas">
              {this.state.isSideNavCollapsed ? (
                <Tooltip placement="rightTop" title="Estadísticas">
                  <div className="icon__container">
                    <BarChartOutlined />
                  </div>
                </Tooltip>
              ) : (
                <div className="icon__container">
                  <BarChartOutlined />
                </div>
              )}

              {!this.state.isSideNavCollapsed && (
                <span className="route__label">Estadísticas</span>
              )}
            </Link>
          </div>
          {/* Reports group submenu */}
          <div className="routeSubMenu">
            <div
              className="routeItem"
              onClick={() => this.openMenuGroup('reports')}
              aria-hidden="true"
            >
              {this.state.isSideNavCollapsed ? (
                <Tooltip placement="rightTop" title="Reportes">
                  <div className="icon__container">
                    <FormOutlined />
                  </div>
                </Tooltip>
              ) : (
                <div className="icon__container">
                  <FormOutlined />
                </div>
              )}

              {!this.state.isSideNavCollapsed && (
                <span className="route__label">
                  Reportes
                  <span className="openClose__button">
                    {this.state.openGroupName === 'reports' ? (
                      <UpOutlined />
                    ) : (
                      <DownOutlined />
                    )}
                  </span>
                </span>
              )}
            </div>
            <div
              className={`menuGroup ${
                this.state.openGroupName === 'reports' && 'open'
              }`}
            >
              <div className="routeItem">
                <Link to="/reporte-subordenes">
                  {this.state.isSideNavCollapsed ? (
                    <Tooltip placement="rightTop" title="Subordenes">
                      <div className="icon__container">
                        <BlockOutlined />
                      </div>
                    </Tooltip>
                  ) : (
                    <div className="icon__container">
                      <BlockOutlined />
                    </div>
                  )}

                  {!this.state.isSideNavCollapsed && (
                    <span className="route__label">Subordenes</span>
                  )}
                </Link>
              </div>
            </div>
          </div>
          {/* TakeOffPlan route item */}
          <div className="routeItem">
            <Link to="/plan-despega">
              {this.state.isSideNavCollapsed ? (
                <Tooltip placement="rightTop" title="Aumenta tus ventas">
                  <div className="icon__container">
                    <RiseOutlined />
                  </div>
                </Tooltip>
              ) : (
                <div className="icon__container">
                  <RiseOutlined />
                </div>
              )}

              {!this.state.isSideNavCollapsed && (
                <span className="route__label">Aumenta tus ventas</span>
              )}
            </Link>
          </div>
          {/* Settings route item */}
          <div className="routeItem">
            <Link to="/configuracion">
              {this.state.isSideNavCollapsed ? (
                <Tooltip placement="rightTop" title="Configuración">
                  <div className="icon__container">
                    <SettingOutlined />
                  </div>
                </Tooltip>
              ) : (
                <div className="icon__container">
                  <SettingOutlined />
                </div>
              )}

              {!this.state.isSideNavCollapsed && (
                <span className="route__label">Configuración</span>
              )}
            </Link>
          </div>

          {/* Logout route item */}
          <div className="routeItem logout">
            <Link to="/cerrar">
              {this.state.isSideNavCollapsed ? (
                <Tooltip placement="rightTop" title="Cerrar sesión">
                  <div className="icon__container">
                    <LogoutOutlined />
                  </div>
                </Tooltip>
              ) : (
                <div className="icon__container">
                  <LogoutOutlined />
                </div>
              )}
              {!this.state.isSideNavCollapsed && (
                <span className="route__label">Cerrar sesión</span>
              )}
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default SideNav;
