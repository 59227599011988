import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateAuth } from '../../store/reducers/auth';
import { refreshAuthToken } from './AuthService';
import Sorry from './Sorry';
import DisplayErrorMessage from './DisplayErrorMessage';

const ls = require('local-storage');
const mapStateToProps = (state) => {
  return {
    updateSession: state.auth.updateSession,
  };
};
class Errors extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async refreshToken() {
    const refreshToken = ls.get('refreshTokenCompany');
    if (this.props.errorStatus === 401 || this.props.updateSession) {
      if (refreshToken) {
        try {
          const result = await refreshAuthToken();
          ls.set('authTokenCompany', result.data.accessToken);
          ls.set('refreshTokenCompany', result.data.refreshToken);
          this.props.updateAuth(false);
        } catch (e) {
          DisplayErrorMessage('Error Refrescando el token de acceso', e);
        }
      }
    }
  }

  render() {
    this.refreshToken();
    return <div>{this.props.errorStatus > 499 && <Sorry />}</div>;
  }
}

Errors.propTypes = {
  errorStatus: PropTypes.number,
};

export default connect(mapStateToProps, { updateAuth })(Errors);
