import React from 'react';
import { Button, Empty, Row } from 'antd';
import { AppstoreOutlined } from '@ant-design/icons';
import Errors from '../Errors/Errors';
import CreateWarehouse from './CreateWarehouse';
import DisplayWarehouse from './DisplayWarehouse';
import displayErrorMessage from '../Errors/DisplayErrorMessage';

const ls = require('local-storage');
const axios = require('axios');

class Warehouses extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      warehouses: [],
      errorStatus: undefined,
      fetchingWarehouses: false,
    };
    this.fetchWarehouses = this.fetchWarehouses.bind(this);
    this.reload = this.reload.bind(this);
  }

  reload() {
    this.setState({
      warehouses: [],
      errorStatus: undefined,
      fetchingWarehouses: false,
    });
    this.fetchWarehouses();
  }

  // Fetchs

  async fetchWarehouses() {
    this.setState({ fetchingWarehouses: true });
    let url = `${ls.get('gatewayAPI')}/company-service-api`;
    url += `/warehouse-location`;
    const headers = { Authorization: `Bearer ${ls.get('authTokenCompany')}` };
    try {
      const result = await axios({ method: 'get', url, headers });
      const warehouses = result.data.sort((a, b) => (a.name > b.name ? 1 : -1));
      this.setState({ warehouses });
    } catch (e) {
      displayErrorMessage('Error obteniendo las bodegas', e);
      this.setState({ errorStatus: e.response ? e.response.status : 500 });
    } finally {
      this.setState({ fetchingWarehouses: false });
    }
  }

  componentDidMount() {
    this.fetchWarehouses();
  }

  render() {
    return (
      <div>
        <div style={{ fontSize: '1.5em', marginTop: '1em' }}>
          <AppstoreOutlined />
          <div>Bodegas</div>
        </div>
        <br />
        <br />
        <div style={{ width: '100%', textAlign: 'center' }}>
          <Button
            style={{ height: '3em' }}
            type="primary"
            onClick={this.reload}
            loading={this.state.fetchingWarehouses}
          >
            Recargar
          </Button>
        </div>
        <br />
        <CreateWarehouse reload={this.reload} />
        <br />
        <Errors errorStatus={this.state.errorStatus} />
        <Row gutter={24} style={{ paddingLeft: '10%', paddingRight: '10%' }}>
          {this.state.warehouses.map((warehouse) => (
            <DisplayWarehouse
              warehouse={warehouse}
              reload={this.reload}
              key={warehouse.name}
            />
          ))}
        </Row>
        {this.state.warehouses.length === 0 && <Empty />}
        <br />
      </div>
    );
  }
}

export default Warehouses;
