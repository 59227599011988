import React from 'react';
import './productCard.scss';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import addImage from './addImage.jpg';

class ProductCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { name, reference, productMedia, isDisabled } = this.props.product;

    let cover = { mediaURL: addImage, id: 'addImage' };
    if (productMedia && productMedia.length > 0) {
      cover =
        productMedia.filter((img) => img.isCoverImage)[0] || productMedia[0];
    }
    return (
      <div className="product__card">
        <Link
          to={{
            pathname: `/productos/${this.props.product.id}`,
            product: this.props.product,
          }}
        >
          <div className="product__body">
            <div className="image__container">
              <img
                className="product__image"
                key={cover.id}
                src={cover.mediaURL}
                alt="img"
              />
              <div className="product__reference">REF G: {reference}</div>
            </div>

            {isDisabled && (
              <div
                style={{
                  position: 'absolute',
                  width: '100%',
                  top: '10px',
                  zIndex: 10,
                  backgroundColor: '#E56564',
                  padding: '7px',
                  fontSize: '0.8em',
                  color: 'white',
                }}
              >
                Deshabilitado
              </div>
            )}
            <div className="product__name">
              <div>{name}</div>
            </div>
          </div>
        </Link>
      </div>
    );
  }
}

ProductCard.propTypes = {
  product: PropTypes.object.isRequired,
};

export default ProductCard;
