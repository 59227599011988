import React from 'react';
import PropTypes from 'prop-types';
import { Card, Col } from 'antd';
import { DropboxOutlined } from '@ant-design/icons';
import UpdateBox from './UpdateBox';

class DisplayBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      name,
      maxItems,
      maxWeight,
      length,
      width,
      height,
      sameCityPrice,
      differentCityPrice,
    } = this.props.box;

    return (
      <Col span={8} style={{ marginTop: '1em' }}>
        <Card
          hoverable
          style={{ paddingTop: '2em' }}
          cover={
            <div
              style={{
                display: 'inline-flex',
                fontSize: '2em',
                paddingLeft: '1em',
              }}
            >
              <DropboxOutlined />
              <div style={{ paddingLeft: '0.5em' }}>{name}</div>
            </div>
          }
        >
          <div style={{ textAlign: 'left' }}>
            <br />
            <div>
              Items máximos: <b>{maxItems}</b>
            </div>
            <div>
              Peso máximo: <b>{maxWeight} KG</b>
            </div>
            <div>
              Largo: <b>{length} cm</b>
            </div>
            <div>
              Ancho: <b>{width} cm</b>
            </div>
            <div>
              Alto: <b>{height} cm</b>
            </div>
            <div>
              Precio misma ciudad: $<b>{sameCityPrice}</b>
            </div>
            <div>
              Precio distinta ciudad: $<b>{differentCityPrice}</b>
            </div>
          </div>
          <UpdateBox box={this.props.box} reload={this.props.reload} />
        </Card>
      </Col>
    );
  }
}

DisplayBox.propTypes = {
  box: PropTypes.object.isRequired,
  reload: PropTypes.func.isRequired,
};

export default DisplayBox;
