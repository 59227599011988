import displayError from '../components/Errors/DisplayErrorMessage';

const ls = require('local-storage');
const axios = require('axios');

export const fetchRole = async () => {
  const url = `${ls.get('gatewayAPI')}/identity-service-api/role`;

  const headers = { Authorization: `Bearer ${ls.get('authTokenCompany')}` };
  try {
    const result = await axios({ method: 'get', url, headers });
    const role = result.data;
    return role;
  } catch (e) {
    displayError('Error recuperando el rol', e);
    return [];
  }
};
