import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Upload,
  message,
  Select,
  notification,
  Modal,
  Row,
  Col,
} from 'antd';
import {
  UploadOutlined,
  ReloadOutlined,
  DollarCircleOutlined,
} from '@ant-design/icons';
import {
  XLSToJsonUpdateProductConfigurationsPrices,
  getHeaders,
} from './XMLToJsonUpdateProductConfigurationsPrices';
import { updateProductConfigurationsPrices } from '../../gateway/ProductServices';

const { Option } = Select;

class UpdateProductConfigurationsPrices extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      productConfigurations: [],
    };

    this.proccessFile = this.proccessFile.bind(this);
    this.updateProductConfigurations = this.updateProductConfigurations.bind(
      this,
    );
    this.reload = this.reload.bind(this);
    this.setHeadersValues = this.setHeadersValues.bind(this);
  }

  reload() {
    this.setState({
      headers: undefined,
      file: undefined,
      productConfigurations: [],
    });
  }

  headers(fileHeaders, file) {
    this.setHeadersValues(fileHeaders);
    this.setState({ headers: fileHeaders, file });
  }

  setHeadersValues(headers) {
    if (headers && headers.includes('Referencia') && !this.state.reference) {
      this.setState({ reference: 'Referencia' });
    }
    if (headers && headers.includes('Precio base') && !this.state.basePrice) {
      this.setState({ basePrice: 'Precio base' });
    }
    if (
      headers &&
      headers.includes('Precio público') &&
      !this.state.retailPrice
    ) {
      this.setState({ retailPrice: 'Precio público' });
    }
  }

  proccessFile() {
    const { reference, basePrice, retailPrice } = this.state;
    const headers = [reference, basePrice, retailPrice];
    if (!reference || (!basePrice && !retailPrice)) {
      message.error('Hay campos requeridos sin llenar.');
    } else {
      XLSToJsonUpdateProductConfigurationsPrices(this.state.file, headers).then(
        (response) => {
          if (response.errors.length === 0) {
            notification.warn({
              message: `Productos listos`,
              duration: 10,
              description: `${response.finalProductConfigurations.length} Nuevos productos seran actualizados.`,
            });
            this.setState({
              productConfigurations: response.finalProductConfigurations,
            });
          } else {
            notification.error({
              message: `Productos con errores`,
              duration: 0,
              description: response.errors.join(','),
            });
          }
        },
      );
    }
  }

  async updateProductConfigurations() {
    this.setState({ loading: true });
    const result = await updateProductConfigurationsPrices(
      this.state.productConfigurations,
    );
    if (result) {
      notification.success({
        message: `Product configurations actualizados`,
        duration: 10,
        description: `${this.state.productConfigurations.length} configuraciones de productos fueron actualizadas.`,
      });
      this.setState({ visible: false, loading: false });
      this.reload();
      this.props.reload();
    } else {
      this.setState({ loading: false });
    }
  }

  render() {
    return (
      <div style={{ width: '100%' }}>
        <button
          type="button"
          className="button secondary center"
          onClick={() => this.setState({ visible: true })}
          style={{ width: '100%' }}
        >
          <DollarCircleOutlined
            style={{ fontSize: '20px', marginRight: '5px' }}
          />
          Editar precios productos
        </button>
        <Modal
          title="Editar precios de configuración de productos con Excel"
          visible={this.state.visible}
          onOk={this.handleOk}
          width="30%"
          onCancel={() => {
            this.reload();
            this.setState({ visible: false });
          }}
          footer={[]}
        >
          <div>
            <Row gutter={24}>
              <Col span={12}>
                <Upload
                  accept=".xlsx"
                  showUploadList={false}
                  beforeUpload={(file) => {
                    getHeaders(file)
                      .then((res) => this.headers(res, file))
                      .catch((err) => message.error(err));
                    return false;
                  }}
                >
                  <Button>
                    <UploadOutlined /> Subir nuevos precios por configuración
                  </Button>
                </Upload>
              </Col>
              <Col span={12}>
                <div style={{ width: '100%', textAlign: 'right' }}>
                  <Button
                    style={{
                      fontSize: '1.5em',
                      color: '#646464',
                      borderColor: '#646464',
                      backgroundColor: 'white',
                      height: 'auto',
                    }}
                    type="primary"
                    onClick={() => this.reload()}
                  >
                    <ReloadOutlined />
                  </Button>
                </div>
              </Col>
            </Row>
            <br />
            {this.state.headers && (
              <>
                <Select
                  placeholder="Referencia"
                  style={{ width: 200 }}
                  onChange={(value) => this.setState({ reference: value })}
                  value={this.state.reference}
                >
                  {this.state.headers.map((header) => (
                    <Option key={header} value={header}>
                      {header}
                    </Option>
                  ))}
                </Select>
                <Select
                  placeholder="Precio base"
                  style={{ width: 200 }}
                  onChange={(value) => this.setState({ basePrice: value })}
                  value={this.state.basePrice}
                >
                  {this.state.headers.map((header) => (
                    <Option key={header} value={header}>
                      {header}
                    </Option>
                  ))}
                </Select>
                <Select
                  placeholder="Precio público"
                  style={{ width: 200 }}
                  onChange={(value) => this.setState({ retailPrice: value })}
                  value={this.state.retailPrice}
                >
                  {this.state.headers.map((header) => (
                    <Option key={header} value={header}>
                      {header}
                    </Option>
                  ))}
                </Select>
                <br />
                <br />
                {this.state.productConfigurations.length === 0 && (
                  <Button type="primary" onClick={this.proccessFile}>
                    Confirmar
                  </Button>
                )}
              </>
            )}
            <br />
            <br />
            {this.state.productConfigurations.length !== 0 && (
              <Button
                type="primary"
                onClick={this.updateProductConfigurations}
                loading={this.state.loading}
              >
                {`Actualizar ${this.state.productConfigurations.length} configuraciones de productos`}
              </Button>
            )}
            <br />
            <br />
          </div>
        </Modal>
      </div>
    );
  }
}

UpdateProductConfigurationsPrices.propTypes = {
  reload: PropTypes.func,
};

export default UpdateProductConfigurationsPrices;
