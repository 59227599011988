import React from 'react';

function TakeOffPlanFeatureCard({
  stageNo = 0,
  cardImg = '',
  cardText = <p></p>,
  stageIndColor = 'green',
}) {
  return (
    <div className="take-off-feature-card">
      <div
        className="stage-indicator"
        style={{ backgroundColor: stageIndColor }}
      >
        Etapa {stageNo}
      </div>
      <div className="stage-img-container">
        <img src={cardImg} alt="" />
      </div>
      <div className="stage-text">{cardText}</div>
    </div>
  );
}

export default TakeOffPlanFeatureCard;
