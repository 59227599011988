import displayError from '../components/Errors/DisplayErrorMessage';
import displaySuccess from './displaySuccess';

const ls = require('local-storage');
const axios = require('axios');

export const fetchInventory = async (
  page,
  pageSize,
  warehouseId,
  ownerId,
  minStock,
  maxStock,
  productConfigurationIds,
) => {
  let url = `${ls.get('gatewayAPI')}/warehouse-service-api/public/inventory`;
  url += `?page=${page}&pageSize=${pageSize}`;

  if (warehouseId && warehouseId !== 'none')
    url += `&warehouseId=${warehouseId}`;
  if (productConfigurationIds && productConfigurationIds !== '[]')
    url += `&productConfigurationIds=${productConfigurationIds}`;
  if (ownerId && ownerId !== 'none') url += `&ownerId=${ownerId}`;
  if (minStock && minStock > -1) url += `&minStock=${minStock}`;
  if (maxStock && maxStock > -1) url += `&maxStock=${maxStock}`;

  try {
    const headers = { Authorization: `Bearer ${ls.get('authTokenCompany')}` };
    const result = await axios({ method: 'get', url, headers });
    const total = parseInt(result.headers['pagination-total'], 0);
    return { inventory: result.data, total };
  } catch (e) {
    displayError('Error recuperando el inventario', e);
  }
  return { inventory: [], total: 0 };
};

export const updateInventory = async (warehouseId, inventoryToUpdate) => {
  let url = `${ls.get('gatewayAPI')}/warehouse-service-api/`;
  url += `warehouse/${warehouseId}/inventory`;

  const data = inventoryToUpdate;

  try {
    const headers = { Authorization: `Bearer ${ls.get('authTokenCompany')}` };
    await axios({ method: 'patch', url, headers, data });
    displaySuccess('Inventario Actualizado', 0);
    return true;
  } catch (e) {
    displayError('Error actualizando el inventario', e);
  }
  return false;
};

export const createInventory = async (warehouseId, inventoryToCreate) => {
  let url = `${ls.get('gatewayAPI')}/warehouse-service-api/`;
  url += `warehouse/${warehouseId}/inventory`;

  const data = inventoryToCreate;

  try {
    const headers = { Authorization: `Bearer ${ls.get('authTokenCompany')}` };
    await axios({ method: 'post', url, headers, data });
    displaySuccess('Inventario Creado', 0);
    return true;
  } catch (e) {
    displayError('Error creando el inventario', e);
  }
  return false;
};
