import React from 'react';
import PropTypes from 'prop-types';
import MobileView from './MobileView';
import Errors from '../Errors/Errors';
import WebView from './WebView';

const ls = require('local-storage');

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const authToken = ls.get('authTokenCompany');
    const refreshToken = ls.get('refreshTokenCompany');
    if (authToken && refreshToken) {
      this.props.login(authToken, refreshToken);
    }
  }

  render() {
    return (
      <div className="login__root" style={{ textAlign: 'center' }}>
        {window.innerWidth > 760 && <WebView login={this.props.login} />}
        {window.innerWidth <= 760 && <MobileView login={this.props.login} />}
        <Errors errorStatus={this.state.errorStatus} />
      </div>
    );
  }
}

Login.propTypes = {
  login: PropTypes.func.isRequired,
};

export default Login;
