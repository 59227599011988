import displayError from '../components/Errors/DisplayErrorMessage';

const ls = require('local-storage');
const axios = require('axios');

export const fetchWarehouses = async (warehouseName) => {
  let url = `${ls.get('gatewayAPI')}/warehouse-service-api/warehouse`;

  if (warehouseName) url += `?warehouseName=${warehouseName}`;

  try {
    const headers = { Authorization: `Bearer ${ls.get('authTokenCompany')}` };
    const result = await axios({ method: 'get', url, headers });
    return result.data;
  } catch (e) {
    displayError('Error recuperando las bodegas', e);
  }
  return [];
};

export const fetchPackings = async (
  page,
  pageSize,
  currentStatusNames,
  suborderId,
) => {
  let url = `${ls.get('gatewayAPI')}/warehouse-service-api/packing`;
  url += `?page=${page}&pageSize=${pageSize}`;
  if (currentStatusNames)
    url += `&currentStatusNames=${JSON.stringify(currentStatusNames)}`;
  if (suborderId) url += `&suborderId=${suborderId}`;

  try {
    const headers = { Authorization: `Bearer ${ls.get('authTokenCompany')}` };
    const result = await axios({ method: 'get', url, headers });
    return { packings: result.data, total: result.headers['pagination-total'] };
  } catch (e) {
    displayError('Error recuperando paquetes', e);
  }
  return { packings: [], total: 0 };
};
