import React from 'react';
import PropTypes from 'prop-types';
import {
  DeleteOutlined,
  StopOutlined,
  PlusSquareFilled,
} from '@ant-design/icons';

function EditVariantInformation({
  dimensions,
  handleAddVariant,
  getRowStyle,
  handleEditDimensions,
  canDelete,
  handleDelete,
  handleCancel,
}) {
  return (
    <>
      <div className="row">
        <PlusSquareFilled
          style={{ color: '#06C2AC', fontSize: '32px' }}
          onClick={() => handleAddVariant()}
        />
      </div>
      <div className="row space-between  no-padding">
        <div className="col">Dimensión</div>
        <div className="col">Valor</div>
        <div className="col">Acción</div>
      </div>
      {dimensions.map((variant, index) => (
        <div key={variant.id} className={getRowStyle(variant)}>
          <div className="col">
            <input
              style={{ width: '100px' }}
              onChange={(e) => {
                handleEditDimensions('name', e.target.value, index);
              }}
              name={variant.name}
              type="text"
              value={variant.name}
            />
          </div>
          <div className="col no-padding">
            <input
              style={{ width: '100px' }}
              onChange={(e) => {
                handleEditDimensions('value', e.target.value, index);
              }}
              name={variant.value}
              type="text"
              value={variant.value}
            />
          </div>
          <div className="col">
            <div className="row no-padding">
              {variant.action !== 'delete' && canDelete() && (
                <DeleteOutlined onClick={() => handleDelete(variant, index)} />
              )}
              {(variant.action === 'delete' || variant.action === 'update') && (
                <StopOutlined onClick={() => handleCancel(index)} />
              )}
            </div>
          </div>
        </div>
      ))}
    </>
  );
}

EditVariantInformation.propTypes = {
  dimensions: PropTypes.array,
  handleAddVariant: PropTypes.func,
  getRowStyle: PropTypes.func,
  handleEditDimensions: PropTypes.func,
  canDelete: PropTypes.func,
  handleDelete: PropTypes.func,
  handleCancel: PropTypes.func,
};

export default EditVariantInformation;
