import React from 'react';
import PropTypes from 'prop-types';
import { Button, Upload, message, Select, Checkbox, notification } from 'antd';
import { UploadOutlined, CloseOutlined } from '@ant-design/icons';
import FindUniqueRefDuplicates from './FindUniqueRefDuplicates';
import ProductsTable from './ProductsTable';
import { XLSToJsonAddProducts, getHeaders } from './XMLToJSONUploadProducts';

const { Option } = Select;
const ls = require('local-storage');
const axios = require('axios');

class UploadProducts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      referencesThatCouldNotBeCreated: [],
    };

    // This binding is necessary to make `this` work in the callback
    this.proccessFile = this.proccessFile.bind(this);
    this.handleChangeRef = this.handleChangeRef.bind(this);
    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleChangeDesc = this.handleChangeDesc.bind(this);
    this.handleChangeRetailP = this.handleChangeRetailP.bind(this);
    this.handleChangeBaseP = this.handleChangeBaseP.bind(this);
    this.handleChangeId = this.handleChangeId.bind(this);
    this.handleChangeInventory = this.handleChangeInventory.bind(this);
    this.sendNewProducts = this.sendNewProducts.bind(this);
    this.sendNewProductsBatch = this.sendNewProductsBatch.bind(this);
    this.handleChangeVatPercentage = this.handleChangeVatPercentage.bind(this);
    this.handleChangeTaxName = this.handleChangeTaxName.bind(this);
    this.setHeadersValues = this.setHeadersValues.bind(this);
    this.cleanValues = this.cleanValues.bind(this);
  }

  setHeadersValues(headers) {
    if (!headers) return;
    if (headers.includes('Referencia General') && !this.state.ref) {
      this.handleChangeRef('Referencia General');
    }
    if (headers.includes('Ref. Unica - SKU') && !this.state.id) {
      this.handleChangeId('Ref. Unica - SKU');
    }
    if (headers.includes('NOMBRE') && !this.state.name) {
      this.handleChangeName('NOMBRE');
    }
    if (headers.includes('DESCRIPCION') && !this.state.desc) {
      this.handleChangeDesc('DESCRIPCION');
    }
    if (headers.includes('PRECIO BASE') && !this.state.baseP) {
      this.handleChangeBaseP('PRECIO BASE');
    }
    if (headers.includes('PRECIO CATÁLOGO') && !this.state.fullP) {
      this.handleChangeRetailP('PRECIO CATÁLOGO');
    }
    if (headers.includes('Inventario') && !this.state.quantity) {
      this.handleChangeInventory('Inventario');
    }
    if (headers.includes('Tipo de IVA') && !this.state.taxName) {
      this.handleChangeTaxName('Tipo de IVA');
    }
    if (headers.includes('Valor IVA') && !this.state.vatPer) {
      this.handleChangeVatPercentage('Valor IVA');
    }
  }

  cleanValues() {
    this.setState({
      ref: undefined,
      name: undefined,
      desc: undefined,
      fullP: undefined,
      baseP: undefined,
      id: undefined,
      quantity: undefined,
      taxName: undefined,
      vatPer: undefined,
    });
  }

  // This function set in the state the headers of the xml file.
  headers(fileHeaders, file) {
    const headersSelector = [];
    for (let i = 0; i < fileHeaders.length; i += 1) {
      headersSelector.push({
        key: i.toString(),
        label: `${fileHeaders[i]}`,
        value: `${fileHeaders[i]}`,
      });
    }
    this.setHeadersValues(fileHeaders);
    this.setState({ headersSelector, headers: fileHeaders, file });
  }

  handleChangeRef(value) {
    this.setState({ ref: value });
  }

  handleChangeName(value) {
    this.setState({ name: value });
  }

  handleChangeDesc(value) {
    this.setState({ desc: value });
  }

  handleChangeRetailP(value) {
    this.setState({ fullP: value });
  }

  handleChangeBaseP(value) {
    this.setState({ baseP: value });
  }

  handleChangeId(value) {
    this.setState({ id: value });
  }

  handleChangeInventory(value) {
    this.setState({ quantity: value });
  }

  handleChangeTaxName(value) {
    this.setState({ taxName: value });
  }

  handleChangeVatPercentage(value) {
    this.setState({ vatPer: value });
  }

  proccessFile() {
    this.setState({ loadingProducts: true });
    const { ref, id, name, baseP, fullP, desc, quantity } = this.state;
    const { taxName, vatPer } = this.state;
    const headers = [
      ref,
      id,
      name,
      baseP,
      fullP,
      desc,
      quantity,
      taxName,
      vatPer,
    ];
    if (!baseP || !fullP || !quantity || !vatPer || !taxName) {
      message.error('Hay campos requeridos sin llenar.');
      this.setState({ loadingProducts: false });
    } else if (!ref || !id || !desc || !name) {
      message.error('Hay campos requeridos sin llenar.');
      this.setState({ loadingProducts: false });
    } else {
      const variantHeaders = [];
      const { checkedValues } = this.state;
      for (let i = 0; checkedValues && i < checkedValues.length; i += 1) {
        variantHeaders.push(checkedValues[i]);
      }
      const { catalogId } = this.props;
      XLSToJsonAddProducts(
        this.state.file,
        headers,
        variantHeaders,
        catalogId,
      ).then((result) => {
        if (result.errors.length === 0) {
          const {
            repeatedUniqueReferences,
            productsWithPCWithSameDimensions,
            uniqueReferencesSmallerThan1Digit,
          } = FindUniqueRefDuplicates(result.finalProducts);
          if (
            repeatedUniqueReferences.length +
              uniqueReferencesSmallerThan1Digit.length +
              productsWithPCWithSameDimensions.length ===
            0
          ) {
            notification.success({
              message: `Productos sin errores`,
              duration: 10,
              description: `Los productos estan listos para ser añadidos.`,
            });
            this.setState({ products: result.finalProducts });
          } else {
            if (repeatedUniqueReferences.length > 0) {
              const errorMessage = `Existen filas Con las mismas referencias únicas: ${repeatedUniqueReferences
                .join(', ')
                .toString()}.`;

              notification.error({
                message: `Productos con errores`,
                duration: 0,
                description: errorMessage,
              });
            }
            if (productsWithPCWithSameDimensions.length > 0) {
              const errorMessage = `Existen filas que pertenecen al mismo producto y con dimensiones iguales. Referencias de productos: ${productsWithPCWithSameDimensions
                .join(', ')
                .toString()}.`;

              notification.error({
                message: `Productos con errores`,
                duration: 0,
                description: errorMessage,
              });
            }
            if (uniqueReferencesSmallerThan1Digit.length > 0) {
              const errorMessage = `Existen filas con referencias generales de 1 solo digito. Referencias de productos: ${productsWithPCWithSameDimensions
                .join(', ')
                .toString()}.`;

              notification.error({
                message: `Productos con errores`,
                duration: 0,
                description: errorMessage,
              });
            }
            this.setState({
              errors: productsWithPCWithSameDimensions.concat(
                repeatedUniqueReferences.concat(
                  uniqueReferencesSmallerThan1Digit,
                ),
              ),
            });
          }
        } else {
          this.setState({ errors: result.errors });
        }
        this.setState({ loadingProducts: false });
      });
    }
  }

  async sendNewProducts() {
    this.setState({ loading: true });
    const arrayOfArrays = [];
    if (this.state.products.length > 40) {
      let currentArray = [];
      for (let i = 0; i < this.state.products.length; i += 1) {
        currentArray.push(this.state.products[i]);
        if (currentArray.length > 30) {
          const batchArray = [...currentArray];
          arrayOfArrays.push(batchArray);
          currentArray = [];
        }
      }
      if (currentArray.length > 0) {
        const batchArray = [...currentArray];
        arrayOfArrays.push(batchArray);
        currentArray = [];
      }
    } else {
      arrayOfArrays[0] = this.state.products;
    }

    let referencesThatCouldNotBeCreated = [];
    for (let i = 0; i < arrayOfArrays.length; i += 1) {
      // eslint-disable-next-line no-await-in-loop
      const errors = await this.sendNewProductsBatch(arrayOfArrays[i], i);
      referencesThatCouldNotBeCreated = referencesThatCouldNotBeCreated.concat(
        errors,
      );
    }
    this.setState({ loading: false, referencesThatCouldNotBeCreated });
  }

  async sendNewProductsBatch(products, i) {
    let url = `${ls.get('gatewayAPI')}/company-service-api`;
    url += `/catalog/${this.props.catalogId}/addProducts`;
    const headers = { Authorization: `Bearer ${ls.get('authTokenCompany')}` };
    try {
      const data = products;
      const response = await axios({ method: 'post', url, headers, data });
      const { referencesThatCouldNotBeCreated } = response.data;
      const productsWithError = referencesThatCouldNotBeCreated.length;
      if (productsWithError > 0) {
        notification.error({
          message: `Productos no añadidos`,
          duration: 0,
          description: `${productsWithError} no fueron añadidos.`,
        });
      }
      notification.success({
        message: `Productos añadidos`,
        duration: 5,
        description: `${
          products.length - productsWithError
        } Nuevos productos fueron añadidos.`,
      });
      return referencesThatCouldNotBeCreated;
    } catch (e) {
      let errorMessage = e.response?.data?.message;
      if (!errorMessage) {
        errorMessage = `Error desconocido. Por favor contactanos. Batch: ${i}`;
      } else if (
        errorMessage.includes(
          'There is already a product in this catalog with that reference',
        )
      ) {
        errorMessage = `Ya existe un producto en el catálogo con esa referencia. Referencia duplicada:${
          errorMessage.split(':')[1]
        }`;
      } else if (errorMessage.includes('Validation error')) {
        errorMessage = `${e.response.data.constraints
          .map((elem) => `${elem.field} - ${elem.message}`)
          .join(', ')
          .toString()}`;
      }
      notification.error({
        message: `Productos no añadidos`,
        duration: 0,
        description: errorMessage,
      });
    }
    return [];
  }

  render() {
    return (
      <div>
        <br />
        {!this.state.headers && (
          <Upload
            accept=".xlsx"
            showUploadList={false}
            beforeUpload={(file) => {
              // Prevent upload
              this.setState({ loadingHeaders: true });
              getHeaders(file)
                .then((res) => this.headers(res, file))
                .catch((err) => {
                  notification.error({
                    message: `Error en el documento`,
                    duration: 0,
                    description: err.msg,
                  });
                })
                .finally(() => this.setState({ loadingHeaders: false }));
              return false;
            }}
          >
            <Button loading={this.state.loadingHeaders}>
              <UploadOutlined /> Subir productos
            </Button>
          </Upload>
        )}
        {this.state.headers && (
          <Button
            onClick={() => {
              this.cleanValues();
              this.setState({
                headers: undefined,
                products: [],
                errors: undefined,
                headersSelector: undefined,
              });
            }}
            danger
          >
            <CloseOutlined /> Subir otro archivo
          </Button>
        )}
        <br />
        <br />
        {this.state.headers && !this.state.errors && (
          <>
            <Select
              placeholder="Referencia General"
              style={{ width: 200 }}
              onChange={this.handleChangeRef}
              value={this.state.ref}
            >
              {this.state.headers.map((header) => (
                <Option key={header} value={header}>
                  {header}
                </Option>
              ))}
            </Select>
            <Select
              placeholder="Referencia Unica"
              style={{ width: 200 }}
              onChange={this.handleChangeId}
              value={this.state.id}
            >
              {this.state.headers.map((header) => (
                <Option key={header} value={header}>
                  {header}
                </Option>
              ))}
            </Select>
            <Select
              placeholder="Nombre"
              style={{ width: 200 }}
              onChange={this.handleChangeName}
              value={this.state.name}
            >
              {this.state.headers.map((header) => (
                <Option key={header} value={header}>
                  {header}
                </Option>
              ))}
            </Select>
            <Select
              placeholder="Precio base"
              style={{ width: 200 }}
              onChange={this.handleChangeBaseP}
              value={this.state.baseP}
            >
              {this.state.headers.map((header) => (
                <Option key={header} value={header}>
                  {header}
                </Option>
              ))}
            </Select>
            <Select
              placeholder="Precio catálogo"
              style={{ width: 200 }}
              onChange={this.handleChangeRetailP}
              value={this.state.fullP}
            >
              {this.state.headers.map((header) => (
                <Option key={header} value={header}>
                  {header}
                </Option>
              ))}
            </Select>
            <br />
            <Select
              placeholder="Descripcion"
              style={{ width: 200 }}
              onChange={this.handleChangeDesc}
              value={this.state.desc}
            >
              {this.state.headers.map((header) => (
                <Option key={header} value={header}>
                  {header}
                </Option>
              ))}
            </Select>
            <Select
              placeholder="inventario"
              style={{ width: 200 }}
              onChange={this.handleChangeInventory}
              value={this.state.quantity}
            >
              {this.state.headers.map((header) => (
                <Option key={header} value={header}>
                  {header}
                </Option>
              ))}
            </Select>
            <Select
              placeholder="Tipo de IVA"
              style={{ width: 200 }}
              onChange={this.handleChangeTaxName}
              value={this.state.taxName}
            >
              {this.state.headers.map((header) => (
                <Option key={header} value={header}>
                  {header}
                </Option>
              ))}
            </Select>
            <Select
              placeholder="Valor IVA"
              style={{ width: 200 }}
              onChange={this.handleChangeVatPercentage}
              value={this.state.vatPer}
            >
              {this.state.headers.map((header) => (
                <Option key={header} value={header}>
                  {header}
                </Option>
              ))}
            </Select>
            <br />
            <br />
            <div style={{ fontSize: 'small' }}>
              Selecciona las caracteristicas del producto.
            </div>
            <br />
            <Checkbox.Group
              options={this.state.headersSelector}
              onChange={(checkedValues) => this.setState({ checkedValues })}
            />
            <br />
            <br />
            <Button
              type="primary"
              onClick={this.proccessFile}
              loading={this.state.loadingProducts}
            >
              Confirmar
            </Button>
          </>
        )}
        <br />
        <br />
        {this.state.errors && (
          <div
            style={{
              margin: '0 10%',
              padding: '4% 8%',
              border: 'solid red',
              textAlign: 'left',
            }}
          >
            <ul>
              {this.state.errors.map((error) => (
                <li>{error}</li>
              ))}
            </ul>
          </div>
        )}
        {this.state.referencesThatCouldNotBeCreated.length > 0 && (
          <div
            style={{
              margin: '0 10%',
              padding: '4% 8%',
              border: 'solid red',
              textAlign: 'left',
            }}
          >
            <ul>
              {this.state.referencesThatCouldNotBeCreated.map((error) => (
                <li key={error.reference}>
                  Referencia: {error.reference}. Error: {error.error}
                </li>
              ))}
            </ul>
          </div>
        )}
        {this.state.products.length !== 0 && (
          <>
            <ProductsTable products={this.state.products} />
            <Button
              type="primary"
              onClick={this.sendNewProducts}
              loading={this.state.loading}
            >
              Subir estos productos a mi catálogo
            </Button>
          </>
        )}
        <br />
        <br />
        <br />
        <br />
      </div>
    );
  }
}

UploadProducts.propTypes = {
  catalogId: PropTypes.string,
};

export default UploadProducts;
