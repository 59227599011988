import displayError from '../components/Errors/DisplayErrorMessage';

const ls = require('local-storage');
const axios = require('axios');

export const fetchAllCities = async (
  page,
  pageSize,
  name,
  cityCode,
  governmentCodes,
) => {
  let url = `${ls.get('gatewayAPI')}/company-service-api/public/city`;
  if (typeof page === 'number' && typeof pageSize === 'number')
    url += `?page=${page}&pageSize=${pageSize}`;
  if (name) url += `&cityName=${name}`;
  if (cityCode) url += `&governmentCode=${cityCode}`;
  if (governmentCodes && governmentCodes.length > 0)
    url += `&governmentCodes=${JSON.stringify(governmentCodes)}`;

  try {
    const headers = { Authorization: `Bearer ${ls.get('authTokenCompany')}` };
    const result = await axios({ method: 'get', url, headers });
    return { cities: result.data, total: result.headers['pagination-total'] };
  } catch (e) {
    displayError('Error recuperando las ciudades', e);
  }
  return { cities: [], total: 0 };
};
