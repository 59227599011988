import React from 'react';
import PropTypes from 'prop-types';
import Plot from 'react-plotly.js';

class OrdersCharts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <Plot
          data={[
            {
              type: 'scatter',
              mode: 'lines+markers',
              x: this.props.dailyOrdersData.created_at_date,
              y: this.props.dailyOrdersData.count,
            },
          ]}
          layout={{
            width: '100%',
            height: '100%',
            title: 'Ordenes por día',
            yaxis: { title: { text: 'órdenes' } },
          }}
          config={{
            displaylogo: false,
            displayModeBar: false,
          }}
        />
        <Plot
          data={[
            {
              type: 'bar',
              x: this.props.monthlyOrdersData.created_at_month,
              y: this.props.monthlyOrdersData.orders,
              text: this.props.monthlyOrdersData.orders,
              textposition: 'outside',
              hovertemplate: 'mes=%{x}<br>ordenes=%{text}<br><extra></extra>',
            },
          ]}
          layout={{
            width: '100%',
            height: '100%',
            title: 'Ordenes por mes',
            yaxis: { title: { text: 'órdenes' } },
          }}
          config={{
            displaylogo: false,
            displayModeBar: false,
          }}
        />
        <Plot
          data={[
            {
              type: 'bar',
              x: this.props.topStatesData.state,
              y: this.props.topStatesData.count,
              text: this.props.topStatesData.count,
              textposition: 'outside',
              hovertemplate:
                'departamento=%{x}<br>ordenes=%{text}<br><extra></extra>',
            },
          ]}
          layout={{
            width: '100%',
            height: '100%',
            title: 'Departamentos con más ordenes',
            xaxis: { title: { text: 'departamento' } },
            yaxis: { title: { text: 'ordenes' } },
          }}
          config={{
            displaylogo: false,
            displayModeBar: false,
          }}
        />
        <Plot
          data={[
            {
              type: 'bar',
              x: this.props.topCitiesData.city,
              y: this.props.topCitiesData.count,
              text: this.props.topCitiesData.count,
              textposition: 'outside',
              hovertemplate:
                'ciudad=%{x}<br>ordenes=%{text}<br><extra></extra>',
            },
          ]}
          layout={{
            width: '100%',
            height: '100%',
            title: 'Ciudades con más ordenes',
            xaxis: { title: { text: 'ciudad' } },
            yaxis: { title: { text: 'ordenes' } },
          }}
          config={{
            displaylogo: false,
            displayModeBar: false,
          }}
        />
      </div>
    );
  }
}

OrdersCharts.propTypes = {
  dailyOrdersData: PropTypes.object.isRequired,
  monthlyOrdersData: PropTypes.object.isRequired,
  topCitiesData: PropTypes.object.isRequired,
  topStatesData: PropTypes.object.isRequired,
};

export default OrdersCharts;
