import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Input, Row, Col, notification } from 'antd';
import { PlusOutlined, UploadOutlined, FormOutlined } from '@ant-design/icons';
import { uuid } from 'uuidv4';
import ContentFile from './ContentFile';
import { addContent, updateContent } from '../../gateway/ContentServices';
import {
  addContentFile,
  deleteContentFile,
  updateContentFile,
} from '../../gateway/ContentFileServices';

const { TextArea } = Input;

const text = { fontSize: '1.2em', color: '#1A002D' };
class ContentModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      visible: false,
      contentFiles: [],
    };
    this.saveContent = this.saveContent.bind(this);
    this.addContentFile = this.addContentFile.bind(this);
    this.removeContentFile = this.removeContentFile.bind(this);
    this.checkData = this.checkData.bind(this);
  }

  async removeContentFile(index) {
    this.setState({ loading: true });
    const cf = this.state.contentFiles[index];
    let fileDeleted = true;
    if (cf.id) {
      fileDeleted = await deleteContentFile(this.state.id, cf.id);
      if (fileDeleted) {
        notification.success({
          message: 'Archivo de contenido borrado',
          duration: 5,
          description: `Archivo : ${cf.name} ha sido borrado`,
        });
      }
    }
    if (fileDeleted) {
      this.setState((prevState) => {
        const { contentFiles } = prevState;
        contentFiles.splice(index, 1);
        return { contentFiles };
      });
    }
    this.setState({ loading: false });
  }

  checkData() {
    const { id, contentFiles, newTitle, newDescription } = this.state;
    let { title, description } = this.state;
    title = newTitle || title;
    description = newDescription || description;
    let isCorrect = true;
    if (!id && !title && !description) {
      isCorrect = false;
    } else if (contentFiles) {
      for (let i = 0; i < contentFiles.length; i += 1) {
        const cf = contentFiles[i];
        if (!cf.id) {
          if (!cf.newRelevance || !cf.newContentFileTypeName || !cf.newName) {
            isCorrect = false;
          } else if (
            cf.newContentFileTypeName === 'VIDEO' ||
            cf.newContentFileTypeName === 'GOOGLE_DRIVE'
          ) {
            if (!cf.newUrl) isCorrect = false;
          } else if (
            cf.newContentFileTypeName === 'IMAGE' ||
            cf.newContentFileTypeName === 'PDF'
          ) {
            if (!cf.newFile) isCorrect = false;
          }
        } else if (
          (cf.newContentFileTypeName === 'IMAGE' ||
            cf.newContentFileTypeName === 'PDF') &&
          !cf.newFile
        ) {
          isCorrect = false;
        } else if (
          (cf.newContentFileTypeName === 'VIDEO' ||
            cf.newContentFileTypeName === 'GOOGLE_DRIVE') &&
          !cf.newUrl
        ) {
          isCorrect = false;
        }
      }
    }
    if (!isCorrect) {
      notification.error({
        message: 'Campos incompletos',
        duration: 10,
        description:
          'Porfavor complete todos los campos del contenido y de los archivos para poder guardarlos.',
      });
    }
    return isCorrect;
  }

  addContentFile() {
    this.setState((prevState) => {
      const { contentFiles } = prevState;
      contentFiles.push({ tempId: uuid() });
      return { contentFiles };
    });
  }

  async saveContent() {
    this.setState({ loading: true });
    const dataIsCorrect = this.checkData();
    if (dataIsCorrect) {
      let contentId = this.state.id;
      let succeded = true;
      if (!contentId) {
        const savedContent = await addContent(
          this.state.newTitle,
          this.state.newDescription,
        );
        if (savedContent) contentId = savedContent.id;
      } else {
        const { newTitle, newDescription } = this.state;
        if (newTitle || newDescription) {
          succeded = await updateContent(contentId, newTitle, newDescription);
        }
      }
      if (contentId) {
        const { contentFiles } = this.state;
        for (let i = 0; i < contentFiles.length; i += 1) {
          const cf = contentFiles[i];

          if (!cf.id) {
            // eslint-disable-next-line no-await-in-loop
            succeded = await addContentFile(
              contentId,
              cf.newRelevance,
              cf.newContentFileTypeName,
              cf.newName,
              cf.newUrl,
              cf.newFile,
            );
          } else if (
            cf.newName ||
            cf.newRelevance ||
            cf.newContentFileTypeName ||
            cf.newUrl ||
            cf.newFile
          ) {
            // eslint-disable-next-line no-await-in-loop
            succeded = await updateContentFile(
              cf.id,
              cf.newRelevance,
              cf.newFile || cf.newUrl
                ? cf.newContentFileTypeName || cf.contentFileType.name
                : undefined,
              cf.newName,
              cf.newUrl,
              cf.newFile,
            );
          }
        }
        if (succeded) {
          notification.success({
            message: 'Contenido completamente guardado',
            duration: 10,
            description: `El contenido: ${
              this.state.newTitle || this.state.title
            } ha sido guardado con id: ${contentId}.`,
          });
        } else {
          notification.warning({
            message: 'Contenido guardado parcialmente',
            duration: 10,
            description: `El contenido: ${
              this.state.newTitle || this.state.title
            } fue guardado parcialmente.`,
          });
        }
        this.setState({ visible: false });
        this.props.handleReload();
        this.closeModal();
      }
    }
    this.setState({ loading: false });
  }

  closeModal() {
    if (!this.state.id) {
      this.setState({
        newTitle: undefined,
        newDescription: undefined,
        contentFiles: [],
        visible: false,
      });
    } else {
      this.setState({ newTitle: undefined, newDescription: undefined });
      const { contentFiles } = this.state;
      for (let i = contentFiles.length - 1; i >= 0; i -= 1) {
        const cf = contentFiles[i];
        if (!cf.id) {
          contentFiles.splice(i, 1);
        } else {
          cf.newName = undefined;
          cf.newRelevance = undefined;
          cf.newContentFileTypeName = undefined;
          cf.newFile = undefined;
          cf.newUrl = undefined;
        }
      }
      this.setState({ visible: false, contentFiles });
    }
  }

  componentDidMount() {
    const { content } = this.props;
    if (content) {
      this.setState({
        title: content.title,
        description: content.description,
        contentFiles: content.contentFiles,
        id: content.id,
      });
    }
  }

  render() {
    const { content } = this.props;
    const modalTitle = content
      ? 'Modificar contenido'
      : 'Crear nuevo contenido';
    const { title, description, id } = this.state;
    return (
      <div>
        <Modal
          width="60%"
          title={modalTitle}
          visible={this.state.visible}
          loading={this.state.loading}
          onCancel={() => this.closeModal()}
          footer={[
            <Button
              loading={this.state.loading}
              key="back"
              onClick={() => this.closeModal()}
            >
              Cancelar
            </Button>,
            <Button
              key="submit"
              type="text"
              loading={this.state.loading}
              onClick={this.saveContent}
              style={{ backgroundColor: '#06C2AC', color: 'white' }}
            >
              Guardar
            </Button>,
          ]}
        >
          <div>
            <Row gutter={24}>
              <Col span={24}>
                <div style={text}>Nombre del contenido</div>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <Input
                  style={
                    this.state.newTitle !== undefined
                      ? { borderColor: '#1A002D' }
                      : {}
                  }
                  value={
                    this.state.newTitle === undefined
                      ? title
                      : this.state.newTitle
                  }
                  onChange={(e) => this.setState({ newTitle: e.target.value })}
                />
              </Col>
            </Row>
            <br />
            <div style={text}>Descripción del contenido</div>
            <TextArea
              rows={5}
              style={
                this.state.newDescription !== undefined
                  ? { borderColor: '#1A002D' }
                  : {}
              }
              value={
                this.state.newDescription === undefined
                  ? description
                  : this.state.newDescription
              }
              onChange={(e) =>
                this.setState({ newDescription: e.target.value })
              }
            />
            <br />
            <br />
            <Row gutter={24}>
              <Col span={24}>
                <div style={text}>Archivos</div>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <Button
                  type="text"
                  style={{ backgroundColor: '#06C2AC', color: 'white' }}
                  icon={<PlusOutlined />}
                  loading={this.state.loading}
                  onClick={this.addContentFile}
                ></Button>
              </Col>
            </Row>
            <br />
            {this.state.contentFiles.map((cf, i) => (
              <ContentFile
                key={cf.id || cf.tempId}
                removeContentFile={this.removeContentFile}
                contentFileIndex={i}
                contentFile={cf}
              ></ContentFile>
            ))}
            <br />
          </div>
        </Modal>
        {!id ? (
          <Button
            onClick={() => this.setState({ visible: true })}
            type="text"
            block
            shape="round"
            style={{
              backgroundColor: '#06C2AC',
              color: 'white',
            }}
            icon={<UploadOutlined />}
          >
            Subir nuevo contenido
          </Button>
        ) : (
          <Button
            block
            type="text"
            style={{
              background: '#F5F5F5',
              textAlign: 'left',
              color: '#1A002D',
            }}
            shape="round"
            icon={<FormOutlined />}
            onClick={() => this.setState({ visible: true })}
          >
            Editar
          </Button>
        )}
      </div>
    );
  }
}
ContentModal.propTypes = {
  content: PropTypes.object,
  handleReload: PropTypes.func,
};
export default ContentModal;
